<script lang="ts">
    export let style: "normal" | "cancel" | "play" = "normal";
</script>

<span data-component="CalloutIcon" style="font-size:1em">
    {#if style === "normal"}
        <i class="fa-solid fa-message bubble" />
        <i class="fa-solid fa-user fa-inverse person" />
    {/if}

    {#if style === "play"}
        <i class="fa-solid fa-message bubble" />
        <i class="fa-solid fa-bell fa-inverse person shift-left" />
        <i class="fa-solid fa-user fa-inverse play" />
    {/if}

    {#if style === "cancel"}
        <i class="fa-solid fa-message bubble" />
        <i class="fa-solid fa-user fa-inverse person shift-left" />
        <i class="fa-solid fa-xmark fa-inverse cancel" />
    {/if}
</span>

<style lang="scss">
    [data-component="CalloutIcon"] {
        width: min-content;
        display: grid;
        grid-template-areas: "main";

        .bubble {
            grid-area: main;
        }

        .person {
            grid-area: main;
            transform: scale(0.5) translateY(-0.2em);

            &.shift-left {
                transform: scale(0.5) translate(-0.3em, -0.2em);
            }
        }

        .play {
            grid-area: main;
            transform: scale(0.4) translate(0.8em, -0.3em);
        }

        .cancel {
            grid-area: main;
            transform: scale(0.4) translate(0.8em, -0.3em);
        }
    }
</style>
