import type { ISessionInfoStorageDTO } from "../models/DTO/ISessionInfoStorageDTO";

enum LocalStorageKey {
    songDelay = "songDelay",
    clientName = "clientName",
    dataUrl = "dataUrl",
    coachPriorityAmout = "coachPriorityAmout",
    ambientFolderId = "ambientFolderId",
    ambientSuffle = "ambientSuffle",
    session = "session",
    enableSignalR = "enableSignalR",
    selectSongKeepLastFolder = "selectSongKeepLastFolder"
}

export class AppLocalStorage {

    public clearAll = () => {
        localStorage.clear();
    }

    public get songDelay(): number {
        return parseInt(localStorage.getItem(LocalStorageKey.songDelay)) || 15;
    }
    public set songDelay(value: number) {
        localStorage.setItem(LocalStorageKey.songDelay, value.toString());
    }


    public get clientName(): string {
        return localStorage.getItem(LocalStorageKey.clientName);
    }
    public set clientName(value: string) {
        localStorage.setItem(LocalStorageKey.clientName, value || "");
    }

    public get dataUrl(): string {
        return localStorage.getItem(LocalStorageKey.dataUrl);
    }
    public set dataUrl(value: string) {
        localStorage.setItem(LocalStorageKey.dataUrl, value || "");
    }

    public get coachPriorityAmout(): number {
        return parseInt(localStorage.getItem(LocalStorageKey.coachPriorityAmout)) || 2;
    }
    public set coachPriorityAmout(value: number) {
        localStorage.setItem(LocalStorageKey.coachPriorityAmout, value.toString());
    }

    public get enableSignalR(): boolean {
        return localStorage.getItem(LocalStorageKey.enableSignalR) === "1";
    }
    public set enableSignalR(value: boolean) {
        localStorage.setItem(LocalStorageKey.enableSignalR, value ? "1" : "0");
    }


    public get selectSongKeepLastFolder() {
        const val = localStorage.getItem(LocalStorageKey.selectSongKeepLastFolder);
        if (val === "1") {
            return true;
        } else if (val === "0") {
            return false;
        }
        else {
            return undefined;
        }

    }
    public set selectSongKeepLastFolder(value: boolean) {
        localStorage.setItem(LocalStorageKey.selectSongKeepLastFolder, value ? "1" : "0");
    }

    //########################

    public get ambientFolderId(): string {
        return localStorage.getItem(LocalStorageKey.ambientFolderId);
    }
    public set ambientFolderId(value: string) {
        localStorage.setItem(LocalStorageKey.ambientFolderId, value);
    }

    public get ambientShuffle(): boolean {
        return localStorage.getItem(LocalStorageKey.ambientSuffle) === "1";
    }
    public set ambientShuffle(value: boolean) {
        localStorage.setItem(LocalStorageKey.ambientSuffle, value ? "1" : "0");
    }

    public get sessionInfo(): ISessionInfoStorageDTO {
        const data = localStorage.getItem(LocalStorageKey.session);
        if (!data) {
            return undefined;
        }
        const session: ISessionInfoStorageDTO = JSON.parse(data);

        // Fix dates
        session.sessionSavedDate = new Date(session.sessionSavedDate);
        session.queue.forEach(qs => {
            qs.startTime = qs.startTime ? new Date(qs.startTime) : undefined;
            qs.queuedTime = qs.queuedTime ? new Date(qs.queuedTime) : undefined;
        })

        return session;
    }
    public set sessionInfo(value: ISessionInfoStorageDTO) {
        if (value) {
            localStorage.setItem(LocalStorageKey.session, JSON.stringify(value));
        } else {
            localStorage.removeItem(LocalStorageKey.session);
        }
    }

}

export const appLocalStorage = new AppLocalStorage();
