<script lang="ts">
  import { format } from "date-fns";
  import { i18n, i18n$ } from "../i18n/i18n";
  import { queueSubject } from "../stores/queue-store";
  import Modal from "./modal.svelte";

  let modal: Modal;

  export const openDialogAsync = () => {
    return modal.openDialogAsync();
  };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<Modal
  bind:this={modal}
  config={{
    title: i18n.dialogs.sessionHistory.title,
    buttons: ["close", "x"],
    autoSize: false,
    dialogHeight: "80dvh",
    headerIcon: "fa-solid fa-history",
  }}
>
  <div data-component="SessionHistoryDialog">
    <table class="grid">
      <thead>
        <tr>
          <th class="left">{$i18n$.dialogs.sessionHistory.time}</th>
          <th class="left">{$i18n$.dialogs.sessionHistory.song}</th>
          <th class="center">{$i18n$.dialogs.sessionHistory.occurence}</th>
          <th class="center">{$i18n$.dialogs.sessionHistory.priority}</th>
          <th class="center">{$i18n$.dialogs.sessionHistory.status}</th>
        </tr>
      </thead>

      {#if !$queueSubject?.length}
        <tbody class="empty">
          <tr>
            <!-- EMPTY -->
            <td colspan="999" class="gray-message">{$i18n$.dialogs.sessionHistory.emptyHistory}</td>
          </tr>
        </tbody>
      {:else}
        <tbody class="has-data">
          {#each $queueSubject as qs}
            <tr class:history={qs.queueStatus === "history"} class:current={qs.queueStatus === "current"} class:queued={qs.queueStatus === "waiting"}>
              <td class="left">{qs.startTime ? format(qs.startTime, "HH:mm") : ""}</td>
              <td class="left">{qs.song.title}</td>
              <td class="center">
                {qs.sessionOccurence}
              </td>
              <td class="center">
                {#if qs.isCoachRequest}
                  <i class="fa-solid fa-user-group" />
                  {qs.positionOverridden || ""}
                {/if}
              </td>
              <td class="center">
                {#if qs.playStatus === "playing"}
                  <i class="fa-solid fa-caret-right" />
                {:else if qs.playStatus === "completed"}
                  <i class="fa-solid fa-check" />
                {:else if qs.playStatus === "skipped"}
                  <i class="fa-solid fa-forward-step" />
                {/if}
              </td>
            </tr>
          {/each}
        </tbody>
      {/if}
    </table>
  </div>
</Modal>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="SessionHistoryDialog"] {
    width: 80vw;
    height: 100%;
    overflow: auto;

    .grid {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 0.2rem;

      th {
        position: sticky;
      }

      tbody.has-data {
        tr {
          &.history {
            background-color: $lightgray;
          }
          &.current {
            background-color: $success-bg;
            height: 4rem;
          }
          &.queued {
            background-color: $lightgray;
          }
        }
      }
      tbody.empty {
        td {
          text-align: center;
          padding-top: 3rem;
        }
      }

      tr {
        th,
        td {
          padding: 0.3rem;
        }

        .left {
          text-align: left;
        }
        .center {
          text-align: center;
        }
      }
    }
  }
</style>
