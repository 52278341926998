import { Buffer } from "buffer";

export interface IClientInfo {
    clientKey: string;
    clientName: string;
    data: string;
    limit: string;
    licenseType: string;
}

export const getClientInfo = async (clientKey: string): Promise<IClientInfo> => {

    const res = await fetch(import.meta.env.VITE_AZURE_FUNCTIONS_URL + `/ClientInfo/${clientKey}`);
    if (res.status !== 200) {
        throw new Error(`Failed to get client info for ${clientKey}`);
    }

    return await res.json();
}


export const FromBase64 = (base64String: string) => {
    if (!base64String) {
        return undefined;
    }
    const buffer = Buffer.from(base64String, 'base64');
    return buffer.toString('utf-8');
}