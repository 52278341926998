import { BehaviorSubject } from 'rxjs';
import { data as en } from './en';
import { data as fr } from './fr';


export type Language = 'en' | 'fr';


const i18nSubject = new BehaviorSubject<Partial<typeof fr>>(fr);
export const i18n$ = i18nSubject.asObservable();
export let i18n: Partial<typeof fr> = undefined;
i18n$.subscribe(val => i18n = val);




const langSubject = new BehaviorSubject<Language | undefined>(undefined);
export const lang$ = langSubject.asObservable();
export const getCurrentLanguage = () => {
    return langSubject.value;
}


const getAppLanguage = () => {
    if (localStorage.getItem('language')) {
        return localStorage.getItem('language') as Language;
    }
    if (navigator?.language?.substring(0, 2) === 'fr') {
        return "fr";
    }
    return "en"
}

export const setLanguage = (language: Language, loadNewLanguage = true) => {

    localStorage.setItem('language', language);
    document.cookie = "language=" + language + ";path=/;max-age=3600"; // expire après 1 heure

    if (loadNewLanguage) {
        langSubject.next(language);

        switch (language) {

            case 'en':
                i18nSubject.next(en);
                break;

            case 'fr':
                i18nSubject.next(fr);
                break;

        }
    }
}

setLanguage(getAppLanguage());


export const i18nParams = (text: string, params: any) => {

    return Object.keys(params).reduce((acc, key) => {
        let newVal = acc.replaceAll(`{${key}}`, params[key]);


        if (typeof params[key] === 'number') {
            // eslint-disable-next-line no-constant-condition
            while (true) {
                const pos = newVal.indexOf(`{${key}|`)
                const posEnd = newVal.indexOf(`}`, pos + 1) + 1;
                if (pos === -1) {
                    break
                }

                const pluralization = newVal.substring(pos + 1, posEnd - 1);
                const start = newVal.substring(0, pos);
                const end = newVal.substring(posEnd);


                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const [_, singular, plural] = pluralization.split('|');

                newVal = start
                    + (params[key] > 1
                        ? plural
                        : singular)
                    + end;

            }

        }

        return newVal;

    }, text);


}
