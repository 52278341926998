<script lang="ts">
  import { onMount } from "svelte";
  import { get } from "svelte/store";
  import Slider from "../components/slider.svelte";
  import Switch from "../components/switch.svelte";
  import ToggleButtons, { type ToggleButtonOption } from "../components/toggle-buttons.svelte";
  import { getCurrentLanguage, i18n, i18n$, setLanguage } from "../i18n/i18n";
  import { resetApplication, showAppConfirmDialogAsync } from "../stores/app-store";
  import { solohubDB } from "../stores/database";
  import { CoachPriorityValues, coachPriorityAmount, keepLastSelectedFolder, songDelayStore } from "../stores/settings-store";
  import { appLocalStorage } from "../utils/app-local-storage";
  import version from "../version";
  import { i18nParams } from "./../i18n/i18n";
  import Modal from "./modal.svelte";

  let modal: Modal;

  let songDelayLocal = 20;

  export const openDialogAsync = async () => {
    songDelayLocal = get(songDelayStore);
    await modal.openDialogAsync();
  };

  const coachPriorityAmountOptions: ToggleButtonOption[] = [
    { value: CoachPriorityValues.Never, text: i18n.common.never },
    { value: CoachPriorityValues.One, text: "1" },
    { value: CoachPriorityValues.Two, text: "2" },
    { value: CoachPriorityValues.Three, text: "3" },
    { value: CoachPriorityValues.Always, text: i18n.common.always },
  ];
  const languageOptions: ToggleButtonOption[] = [
    { value: "en", text: "English" },
    { value: "fr", text: "Français" },
  ];

  const clearAllStorage = async () => {
    const res = await showAppConfirmDialogAsync({
      title: i18n.confirmDialogs.confirmClientDisconnection.title,
      contentText: i18n.confirmDialogs.confirmClientDisconnection.message,
      buttons: ["cancel", "yes", "x"],
      contentPadding: "2rem",
    });
    if (!res) {
      return;
    }

    await clearServiceWorkersAsync();
    appLocalStorage.clearAll();
    solohubDB.delete();
    resetApplication();
  };

  const clearServiceWorkersAsync = async () => {
    if ("serviceWorker" in navigator) {
      // Obtenez toutes les registrations de service workers
      const registrations = await navigator.serviceWorker.getRegistrations();

      // Bouclez sur toutes les registrations et les déréférencez
      for await (let r of registrations) {
        await r.unregister();
      }
    }
  };

  let localLanguage = getCurrentLanguage();
  onMount(() => {
    localLanguage = getCurrentLanguage();
  });
  const langChanged = async () => {
    setLanguage(localLanguage, false);

    const res = await showAppConfirmDialogAsync({
      title: i18n.confirmDialogs.changeLanguageRestart.title,
      contentText: i18n.confirmDialogs.changeLanguageRestart.message,
      buttons: ["no", "yes", "x"],
      contentPadding: "2rem",
    });
    if (res) {
      resetApplication();
    }
  };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<Modal
  bind:this={modal}
  config={{
    title: i18n.dialogs.settings.title,
    buttons: ["close", "x"],
    dialogHeight: "90dvh",
    autoSize: false,
    headerIcon: "fa-solid fa-gear",
  }}
>
  <div>{$i18n$.dialogs.settings.version} {version}</div>
  <div data-component="settings">
    <div class="settings-list">
      <div class="setting-box flex">
        <div class="label">{$i18n$.dialogs.settings.songDelay}</div>
        <div class="content">
          <div class="delay-slider-container">
            <Slider bind:value={$songDelayStore} step={1} min={3} max={30} />
          </div>
          <span class="delay-value">{i18nParams($i18n$.dialogs.settings.seconds, { sec: $songDelayStore })}</span>
        </div>
      </div>

      <div class="setting-box flex">
        <div class="label">{$i18n$.dialogs.settings.coachPriority}</div>
        <div class="content">
          <ToggleButtons
            options={coachPriorityAmountOptions}
            bind:selectedValue={$coachPriorityAmount}
            on:selectedValueChanged={(ev) => coachPriorityAmount.set(ev.detail)}
          />
        </div>
      </div>

      <div class="setting-box flex">
        <div class="label">{$i18n$.dialogs.settings.keepLastFolder}</div>
        <div class="content">
          <Switch bind:checked={$keepLastSelectedFolder} />
        </div>
      </div>

      <div class="setting-box flex">
        <div class="label">{$i18n$.dialogs.settings.changeLanguage}</div>
        <div class="content">
          <ToggleButtons options={languageOptions} bind:selectedValue={localLanguage} on:selectedValueChanged={langChanged} />
        </div>
      </div>

      <!-- <div class="setting-box flex">
        <div class="label">Activer la connection des clients mobile (SignalR)</div>
        <div class="content">
          <Switch bind:checked={$enableSignalRStore} />
        </div>
      </div> -->

      <div class="setting-box flex">
        <div class="label">{$i18n$.dialogs.settings.disconnectClient}</div>
        <div class="content">
          <button class="button-danger" on:click={() => clearAllStorage()}>{$i18n$.dialogs.settings.disconnectClientInfo}</button>
        </div>
      </div>

      <!-- <div>
        <DebugInfo />
      </div> -->
    </div>
  </div>
</Modal>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="settings"] {
    width: 90vw;
    height: 100%;
    overflow: auto;

    .debug-data-header {
      display: flex;
      gap: 1rem;
      align-items: center;
      margin-bottom: 0.5rem;

      h2 {
        margin: 0;
      }

      button {
        height: 2.5rem;
      }
    }

    .settings-list {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;

      .setting-box {
        min-height: 4rem;
        border-bottom: 1px solid lightgray;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        overflow: hidden;

        .label {
          font-weight: bold;
        }
        .content {
          display: flex;
          gap: 1rem;
          align-items: center;
          flex: auto;
          justify-content: flex-end;
        }
      }
    }

    .warn {
      font-style: italic;
      color: gray;
    }

    .delay-slider-container {
      min-width: 15rem;
    }

    .delay-value {
      min-width: 7rem;
      text-align: right;
    }
  }
</style>
