<script lang="ts">
  import { removeToast, type IToastInfo } from "../stores/toaster-store";

  export let toastInfo: IToastInfo;

  const closeToast = () => {
    removeToast(toastInfo.id);
  };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="toast" class={toastInfo.type}>
  <div class="message-container">
    {#if toastInfo.title}
      <div class="title">{toastInfo.title}</div>
    {/if}
    {#if toastInfo.text}
      <div class="text">
        {#if toastInfo.textIsHtml}
          {@html toastInfo.text}
        {:else}
          {toastInfo.text}
        {/if}
      </div>
    {/if}
    {#if toastInfo.datalist}
      <div class="list">
        <ul>
          {#each toastInfo.datalist as itm}
            <li>{itm}</li>
          {/each}
        </ul>
      </div>
    {/if}
  </div>
  <div class="close-button clickable" on:click={() => closeToast()}>
    <i class="fa-solid fa-close" />
  </div>
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="toast"] {
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0.5rem 0.5rem 0.8rem #000000aa;
    pointer-events: all;
    width: min(40rem, 90vw);
    display: flex;

    &.danger {
      background-color: $danger-bg;
      color: $danger-fg;
      border: 1px solid $danger-fg;
    }

    &.info {
      background-color: $info-bg;
      color: $info-fg;
      border: 1px solid $info-fg;
    }

    &.warn {
      background-color: $warn-bg;
      color: $warn-fg;
      border: 1px solid $warn-fg;
    }

    &.success {
      background-color: $success-bg;
      color: $success-fg;
      border: 1px solid $success-fg;
    }

    .message-container {
      flex: auto;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      justify-content: center;
      .title {
        font-weight: bold;
      }
    }

    .close-button {
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -1rem -1rem 0 0;
      border-radius: 0 0.5rem 0 0;
    }
  }
</style>
