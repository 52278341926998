<script lang="ts" context="module">
  export type ButtonType = "ok" | "yes" | "no" | "close" | "cancel" | "x";
  export interface IDialogConfig {
    headerIcon?: string;
    title: string;
    headerCentered?: boolean;

    contentText?: string | string[];

    acceptCustomText?: string;
    rejectCustomText?: string;

    buttons?: ButtonType[];
    autoSize?: boolean;
    dialogHeight?: string;
    contentPadding?: string;
  }
</script>

<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { fade, scale } from "svelte/transition";

  import { i18n$ } from "../i18n/i18n";
  import { getNextModalZIndex } from "../stores/app-store";
  import { Deferred } from "../utils/deferred-promise";

  export let config: IDialogConfig;
  export let acceptCustomFunction: () => Promise<boolean> = undefined;

  let defaultConfig: IDialogConfig = {
    title: "",
    contentText: "",
    buttons: ["ok", "cancel", "x"],
    dialogHeight: undefined,
    acceptCustomText: undefined,
    rejectCustomText: undefined,
    autoSize: true,
    headerCentered: false,
    headerIcon: undefined,
    contentPadding: undefined,
  };

  let closePromise: Deferred<boolean | undefined>;
  const dispatch = createEventDispatcher();

  let modalZIndex = getNextModalZIndex();

  onMount(() => {
    config = {
      ...defaultConfig,
      ...config,
    };
  });

  export const openDialogAsync = async (): Promise<boolean | undefined> => {
    modalZIndex = getNextModalZIndex();
    isDialogOpened = true;
    closePromise = new Deferred<boolean | undefined>();
    return closePromise;
  };

  let isDialogOpened = false;

  /**
   * This method is called externally to actually close the dialog
   */
  export const closeDialog = (returnValue: any = <ButtonType>"closeExternal") => {
    closeInternal(returnValue);
  };

  const closeInternal = (accept: boolean = undefined) => {
    closePromise.resolve(accept);
    dispatch("close", accept);
    isDialogOpened = false;
  };

  const acceptClicked = async () => {
    if (acceptCustomFunction) {
      if (await acceptCustomFunction()) {
        closeInternal(true);
      }
    } else {
      closeInternal(true);
    }
  };
</script>

<!-- ######################################################################################### -->
{#if isDialogOpened}
  <div data-component="Modal" style="--modalZIndex:{modalZIndex - 1}">
    <div class="backdrop" transition:fade />
    <div class="dialog-container">
      <div class="dialog" in:scale out:fade style={config.autoSize ? "" : `height:${config.dialogHeight}`}>
        <div class="dialog-content" style={config.autoSize ? "height:auto;" : "height:100%;"}>
          <div class="header">
            <div class="header-content" class:centered={config.headerCentered}>
              <div class="empty" />
              <div class="title">
                {#if config.headerIcon}
                  <i class={config.headerIcon} />
                {/if}
                <span>{config.title}</span>
              </div>
              <div class="close">
                {#if config.buttons.includes("x")}
                  <i class="fa-solid fa-close clickable" on:click={() => closeInternal()} />
                {/if}
              </div>
            </div>
          </div>

          <div class="content" style={config.contentPadding ? `padding:${config.contentPadding}` : undefined}>
            {#if isDialogOpened}
              <slot />
            {/if}
          </div>

          <div class="footer">
            <div class="footer-content">
              {#if config.buttons.includes("cancel")}
                <button on:click={() => closeInternal()}>
                  {$i18n$.common.cancel}
                </button>
              {/if}
              {#if config.buttons.includes("close")}
                <button on:click={() => closeInternal()}>
                  {$i18n$.common.close}
                </button>
              {/if}

              <!-- REJECT -->
              {#if config.buttons.includes("no")}
                <button on:click={() => closeInternal(false)}>
                  {config.rejectCustomText || $i18n$.common.no}
                </button>
              {/if}

              <!-- ACCEPT -->
              {#if config.buttons.includes("ok")}
                <button on:click={acceptClicked}>
                  {config.acceptCustomText || $i18n$.common.ok}
                </button>
              {/if}
              {#if config.buttons.includes("yes")}
                <button on:click={acceptClicked}>
                  {config.acceptCustomText || $i18n$.common.yes}
                </button>
              {/if}

              <!-- {/each} -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<!-- ######################################################################################### -->
<style lang="scss">
  [data-component="Modal"] {
    .backdrop {
      z-index: calc(var(--modalZIndex) - 1);
      background-color: #44444488;
      width: 100dvw;
      height: 100dvh;
      position: absolute;
      top: 0;
      left: 0;
    }

    .dialog-container {
      z-index: var(--modalZIndex);
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      .dialog {
        z-index: 100;
        overflow: hidden;
        border: 0;
        padding: 0;
        box-shadow: 0.8rem 0.8rem 1rem #444444;
        border-radius: 0.5rem;
        max-width: 95vw;

        &::backdrop {
          background: rgba(0, 0, 0, 0.5);
        }

        .dialog-content {
          display: flex;
          flex-direction: column;
          background-color: $background-light;
          border-radius: 0.5rem;

          .header {
            background-color: $primary;
            color: white;

            .header-content {
              display: grid;

              grid-template-columns:
                0
                auto
                minmax(max-content, 1fr);

              &.centered {
                grid-template-columns:
                  minmax(max-content, 1fr)
                  auto
                  minmax(max-content, 1fr);
              }
              align-items: center;
              padding: 1rem;
              font-size: 2rem;
            }

            .title {
              font-size: 1.2rem;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;

              i {
                margin-right: 0.5rem;
              }
            }

            .close {
              display: flex;
              justify-content: flex-end;
              font-size: 2rem;
              margin-left: 1rem;
            }
          }
          .content {
            flex: auto;
            overflow: hidden;
            padding: 1rem;
          }

          .footer {
            .footer-content {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 1rem;
              padding: 0 1rem 1rem 1rem;

              button {
                width: clamp(8rem, 12rem, 12rem);
              }
            }
          }
        }
      }
    }
  }
</style>
