<script lang="ts">
    import { Subscription, filter, skip } from "rxjs";
    import { generate } from "short-uuid";
    import { onDestroy } from "svelte";
    import { fade } from "svelte/transition";
    import { appClickedSubject, getNextModalZIndex } from "../stores/app-store";
    const cId = generate();

    let mainContainer: HTMLDivElement;
    let popupButtonContainer: HTMLDivElement;
    let opened = false;
    let subscription: Subscription;
    let modalZIndex = getNextModalZIndex();

    onDestroy(() => {
        subscription?.unsubscribe();
    });

    const open = () => {
        modalZIndex = getNextModalZIndex();
        opened = true;
        subscription = appClickedSubject
            .pipe(
                skip(1), // skip first or it will close instantly
                filter((e) => {
                    console.log(typeof e);
                    return !mainContainer.contains(<Node>e.target);
                }),
            )
            .subscribe(() => close());
    };

    const close = () => {
        opened = false;
        subscription?.unsubscribe();
    };

    const toggle = () => {
        if (opened) {
            close();
        } else {
            open();
        }
    };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="Popup" bind:this={mainContainer}>
    <div bind:this={popupButtonContainer} on:click={() => toggle()}>
        <slot name="button" />
    </div>
    <div class="popup-container" style="--modalZIndex:{modalZIndex}">
        {#if opened}
            <div class="popup" transition:fade>
                <slot name="popup" />
            </div>
        {/if}
    </div>
</div>

<!--######################################################################################-->
<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="Popup"] {
        .popup-container {
            z-index: var(--modalZIndex);
            position: relative;

            .popup {
                background-color: white;
                margin-top: 0.2rem;
                right: 0;
                white-space: nowrap;
                font-size: 1.2rem;
                position: absolute;
                border-radius: 0.5rem;
                box-shadow: 0.5rem 0.5rem 0.8rem #000000aa;
                overflow: hidden;
            }
        }
    }
</style>
