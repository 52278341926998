import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { BehaviorSubject, Subject, filter, firstValueFrom, interval, lastValueFrom, takeUntil, tap, timer } from 'rxjs';
import { generate } from 'short-uuid';
import { get } from 'svelte/store';
import type { ISignalRConnectionInfo } from '../models/ISignalRConnectionInfo';
import type { ISignalRMessage } from '../models/ISignalRMessage2';
import { logDebug } from './app-store';
import { busPush } from './bus';
import { queueSubject, removeFromQueue } from './queue-store';
import { getSessionInfoDTO, sessionInfoSubject } from './session-store';
import { enableSignalRStore } from './settings-store';
import { showToast } from './toaster-store';
const baseFunctionsUrl = import.meta.env.VITE_AZURE_FUNCTIONS_URL;

export type ClientMessageType =
    "RequestSessionInfo"
    | "addToQueue"
    | "removeFromQueue"
    | "playerAction"
    | "addSnap"

export type SignalRConnectionStatus =
    | "connected"
    | "connecting"
    | "disconnected";

export type HostMessageType =
    | "sessionInfo"
    | "playerUpdate"
    | "queueUpdate"
    | "libraryUpdate";

export type PlayerActionTypes =
    | "pause"
    | "play"
    | "skipCurrent"
    | "skipCallout"
    | "replayCallout"
    | "updatePlayTime";

export type PlayerActionData = {
    action: PlayerActionTypes;
    time?: number;
}

export const signalRConnectionStatusSubject = new BehaviorSubject<SignalRConnectionStatus>("disconnected")
export const signalRConnectedClientsSubject = new BehaviorSubject<ISignalRConnectionInfo[]>([]);

let signalRHub: HubConnection;

export const initSignalRStore = async () => {
    enableSignalRStore.subscribe(async () => {
        if (get(enableSignalRStore)) {
            await connectToSignalrAsync();
        } else {
            await disconnectSignalRAsync();
        }
    })
}

export const connectToSignalrAsync = async (): Promise<boolean> => {

    if (!get(enableSignalRStore)) {
        // logDebug("SIGNALR : SignalR is disabled");
        return false;
    }

    const sessionId = sessionInfoSubject.value.id
    if (!sessionId) {
        return;
    }

    signalRConnectionStatusSubject.next("connecting");
    try {

        // If connection exists, disconnect
        if (signalRHub) {
            await disconnectSignalRAsync(false);
        }

        // Create signalR Hub
        signalRHub = new HubConnectionBuilder()
            .withUrl(baseFunctionsUrl, { withCredentials: false })
            .withAutomaticReconnect()
            .build();

        subscribeHubSignalRMessages(signalRHub)

        // Start Hub
        await signalRHub.start();

        const success = await registerToSession(sessionId);
        if (!success) {
            await disconnectSignalRAsync();
            logDebug("SIGNALR Connection Error, registerToSession failed");
            showToast("Connection", "Erreur de connection au serveur de session", "warn");
            return false;
        }

        checkConnectedClients()

        signalRConnectionStatusSubject.next("connected");

        return true;
    }
    catch (ex) {
        await disconnectSignalRAsync()
        console.error("ERROR CONNECTING TO SIGNALR", ex)
        logDebug("SIGNALR Connection Error");
        showToast("Connection", "Erreur de connection au serveur de session", "warn");
        return false;
    }

}

const registerToSession = async (sessionId: string): Promise<boolean> => {

    const data: ISignalRConnectionInfo = {
        connectionId: signalRHub.connectionId,
        isHost: true,
    }

    const res = await fetch(
        `${baseFunctionsUrl}/RegisterToSession?isHost=true&sessionId=${sessionId}`,
        {
            method: "post",
            cache: "no-store",
            body: JSON.stringify(data)
        }
    );

    return res.ok
}

const pongSubject = new Subject<ISignalRMessage<string>>();

const subscribeHubSignalRMessages = (hub: HubConnection) => {

    hub.on("pong", data => {
        pongSubject.next(JSON.parse(data));
    });

    hub.on("registered", (userConnection: ISignalRConnectionInfo) => {

        const newList = [...signalRConnectedClientsSubject.value].filter(c => c.deviceUserId !== userConnection.deviceUserId);
        newList.push(userConnection);
        signalRConnectedClientsSubject.next(newList.filter(clt => !clt.isHost));

    })

    hub.on("RequestSessionInfo", data => {

        const requestInfo: ISignalRMessage<any> = JSON.parse(data);

        signalrBroadcast("sessionInfo", getSessionInfoDTO(), requestInfo.from.connectionId)


    })

    // hub.on("AddToQueue", data => {

    // const requestInfo: ISignalRMessage<SongSelectionResult> = JSON.parse(data);

    // const song = getSong(requestInfo.data.songId);
    // if (!song) {
    //     return;
    // }

    // addToQueue(song, requestInfo.from, requestInfo.data.isCoachRequest);
    // })

    hub.on("RemoveFromQueue", data => {

        const requestInfo: ISignalRMessage<string> = JSON.parse(data);

        const qs = queueSubject.value.find(s => s.qId === requestInfo.data);
        if (!qs || qs.requestedBy.deviceUserId !== requestInfo.from.deviceUserId) {
            return;
        }

        removeFromQueue(qs.qId);
    })

    hub.on("PlayerAction", (data) => {
        const requestInfo: ISignalRMessage<PlayerActionData> = JSON.parse(data);
        busPush("PlayerAction", requestInfo.data)
    })

    hub.on("AddSnap", (data) => {
        const requestInfo: ISignalRMessage<number> = JSON.parse(data);
        busPush("AddSnap", requestInfo.data)
    })


    hub.onclose(() => {
        logDebug("#### SIGNALR CONNECTION CLOSED")
    })

}

const getFrom = (): ISignalRConnectionInfo => {
    return {
        connectionId: signalRHub.connectionId,
        isHost: true
    }
}

export const signalrBroadcast = async (messageType: HostMessageType, content: any, toConnectionId: string = undefined) => {

    if (!signalRHub?.connectionId) {
        return;
    }

    if (!sessionInfoSubject.value.id) {
        logDebug(`SINALR, CANNOT SEND ${messageType}: No session Id yet`);
        return;
    }
    if (!signalRHub) {
        logDebug(`SINALR : CANNOT SEND ${messageType}: Hub is null`);
        return;
    }
    if (signalRHub.state !== HubConnectionState.Connected) {
        logDebug(`SINALR : CANNOT SEND ${messageType}: Hub state is ${signalRHub.state}`);
        return;
    }

    logDebug(`SIGNALR SEND ${messageType}`);

    const message: ISignalRMessage<any> = {
        from: getFrom(),
        timestamp: Date.now(),
        data: content
    }

    let url = `${baseFunctionsUrl}/SendToClients?sessionId=${sessionInfoSubject.value.id}&messageType=${messageType}`;
    if (toConnectionId) {
        url += `&toConnectionId=${toConnectionId}`;
    }
    const res = await fetch(url,
        {
            method: "post",
            cache: "no-store",
            body: JSON.stringify(message)
        })
    return res.ok;
}


export const disconnectSignalRAsync = async (changeStatus = true) => {

    if (signalRHub) {
        unsubscribeAllMessages(signalRHub)
        await signalRHub.stop();

        // Wait until hub is disconnected
        await firstValueFrom(interval(500).pipe(filter(() => {
            return signalRHub?.state === HubConnectionState.Disconnected;
        })));

        signalRHub = undefined;
    }

    if (changeStatus) {
        signalRConnectionStatusSubject.next("disconnected");
    }

    signalRConnectedClientsSubject.next([]);  // TODO : Must reconnect later with ping/pong

}

const unsubscribeAllMessages = (hub: HubConnection) => {
    if (!hub) {
        return;
    }
    hub.off("pong");
    hub.off("registered");
    hub.off("RequestSessionInfo");
    hub.off("AddToQueue");
    hub.off("RemoveFromQueue");
}

export const checkConnectedClients = async (timeout = 5000): Promise<ISignalRConnectionInfo[]> => {

    if (!signalRHub?.connectionId) {
        return;
    }

    const pingId = generate().slice(0, 5);
    const pingMessage: ISignalRMessage<string> = {
        from: getFrom(),
        timestamp: Date.now(),
        data: pingId
    };

    const url = `${baseFunctionsUrl}/Ping?sessionId=${sessionInfoSubject.value.id}`;
    fetch(url,
        {
            method: "post",
            cache: "no-store",
            body: JSON.stringify(pingMessage)
        })


    const connectedUsers: ISignalRConnectionInfo[] = []

    await lastValueFrom(pongSubject.pipe(
        takeUntil(timer(timeout)),
        filter(msg => msg.data === pingId),
        tap(msg => {

            signalRConnectedClientsSubject.next(
                [...signalRConnectedClientsSubject.value.filter(c => c.deviceUserId !== msg.from.deviceUserId),
                msg.from
                ]
            )

            logDebug(`${msg.from.username} is connected`);
            connectedUsers.push(msg.from);
        })
    ), { defaultValue: undefined });

    return connectedUsers;

}
