<script lang="ts">
  import { flip } from "svelte/animate";
  import { fade, fly } from "svelte/transition";
  import { toasterSubject } from "../stores/toaster-store";
  import Toast from "./toast.svelte";
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="toaster">
  {#each $toasterSubject as toastInfo (toastInfo.id)}
    <div animate:flip={{ duration: 300 }} in:fly={{ y: -20, duration: 500 }} out:fade>
      <Toast {toastInfo} />
    </div>
  {/each}
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="toaster"] {
    position: fixed;
    width: 100%;
    top: 0.8rem;
    left: 0;
    pointer-events: none;
    z-index: $toaster-z-index;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    .toast {
      pointer-events: all;
      min-height: 5rem;
    }
  }
</style>
