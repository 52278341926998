
/***************************************************************************************************
 * Uses fetch to get response from the server, with a timeout and a number of attempts
 * if timeout reach (this is not a timeout for download, but just to get the first response from the server) we retry
 * The abortController in parameter comes from the caller, and is used to abort the localAC (AbortController)
 * 
 * @param {RequestInfo | URL} url URL to fetch
 * @param {RequestInit} options Options to pass to the fetch function
 * @param {number} connectionTimeout Timeout to get the first response from the server, not the download time
 * @param {number} attempts Number of attempts to try to connect to the server
 * @param {AbortController} abortController AbortController to cancel the request
 * 
 *  */
export const fetchRetryWithTimeout = async (
    url: RequestInfo | URL,
    options?: RequestInit,
    connectionTimeout: number = undefined,
    attempts: number = 1,
    abortController: AbortController = undefined,
): Promise<Response> => {

    let attemptsCounter = 1;
    let localAC: AbortController;
    let timer: NodeJS.Timeout;

    // register to the parent abort event to cancel the current localAC
    const onParentAbort = () => { localAC?.abort("parent"); };
    if (abortController) {
        abortController.signal.onabort = onParentAbort;
    }

    while (attemptsCounter <= attempts && !abortController?.signal.aborted) {

        localAC = new AbortController();

        try {

            if (connectionTimeout) {
                timer = setTimeout(() => localAC.abort(`timeout`), connectionTimeout);
            }

            const res = await fetch(url,
                {
                    ...options,
                    signal: localAC.signal
                });


            clearTimeout(timer);
            if (!res.ok) {
                attemptsCounter++;
                continue;
            }

            abortController?.signal.removeEventListener("abort", onParentAbort); // Retirer le gestionnaire d'événement
            return res;

        } catch (error) {
            attemptsCounter++;
        }
    }

    clearTimeout(timer);
    abortController.abort("retry-timeout");
    abortController?.signal.removeEventListener("abort", onParentAbort); // Retirer le gestionnaire d'événement
    throw new Error('Max retries reached');
}