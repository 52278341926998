<script lang="ts">
    import { i18n$ } from "../i18n/i18n";
    import { currentSongCalloutUpdateSubject } from "../stores/library-store";
    import { clientName } from "../stores/settings-store";
    import { libraryLoadingCurrentFolderName, libraryLoadingSongCount } from "../utils/onedrive";
    import LoadingBar from "./loading-bar.svelte";
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="OneDriveLoadingProgress">
    <div class="title">{$i18n$.components.oneDriveLoadingProgress.title}</div>
    <LoadingBar />

    <div class="client-name">{$clientName}</div>

    <div class="label">{$i18n$.components.oneDriveLoadingProgress.folderReading}</div>
    <div>{$libraryLoadingCurrentFolderName || "-"}</div>

    <div class="label">{$i18n$.components.oneDriveLoadingProgress.foundSongs}</div>
    <div class="ellipsis">{$libraryLoadingSongCount}</div>

    {#if $currentSongCalloutUpdateSubject}
        <div class="label">{$i18n$.components.oneDriveLoadingProgress.calloutUpdateFor}</div>
        <div class="ellipsis">{$currentSongCalloutUpdateSubject?.title || "-"}</div>
    {/if}
</div>

<!--######################################################################################-->
<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="OneDriveLoadingProgress"] {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 20rem;

        hr {
            width: 100%;
        }

        i {
            color: $bold;
        }

        .client-name {
            margin-top: 1rem;
            font-size: 1.2rem;
            color: $primary;
        }

        .title {
            color: $primary;
            font-weight: 500;
            margin-bottom: 0.3rem;
        }

        .label {
            margin-top: 1rem;
            font-size: 0.8rem;
            color: $primary;
        }
    }
</style>
