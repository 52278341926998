<script lang="ts">
    import { filter, interval, take } from "rxjs";
    import { Subscription } from "rxjs/internal/Subscription";
    import { onDestroy, onMount } from "svelte";
    import { get, type Unsubscriber } from "svelte/store";
    import { i18n, i18n$ } from "../i18n/i18n";
    import { appInitializedSubject, resetApplication } from "../stores/app-store";
    import { databaseUpgraded } from "../stores/database";
    import { initializeLibraryAsync, lib, reloadDatabaseFromOnedriveAsync } from "../stores/library-store";
    import { dataUrl } from "../stores/settings-store";
    import { showToast } from "../stores/toaster-store";
    import { clearSubscriptionsArray } from "../utils/rx-utils";
    import ClientConnect from "./client-connect.svelte";
    import LoadingBar from "./loading-bar.svelte";
    import SessionStart from "./session-start.svelte";

    let showClientKeyInput = false;
    let subscriptions: (Subscription | Unsubscriber)[] = [];

    onMount(async () => {
        subscriptions = [
            appInitializedSubject.pipe(filter((val) => !!val)).subscribe(async () => {
                await initSplash();
            }),
            // auto reload application if not initialized after 10 seconds
            interval(10000)
                .pipe(take(1))
                .subscribe(() => {
                    if (!appInitializedSubject) {
                        showToast(i18n.toasts.loadingTimeout.title, i18n.toasts.loadingTimeout.message, "danger");
                        setTimeout(() => {
                            resetApplication();
                        }, 3000);
                    }
                }),
        ];
    });

    onDestroy(() => {
        clearSubscriptionsArray(subscriptions);
    });

    const initSplash = async () => {
        // If there is no dataUrl in the local storage, we just show the client key input
        showClientKeyInput = !get(dataUrl);
        if (showClientKeyInput) {
            return;
        }

        // Database has been upgraded, notify user
        if (databaseUpgraded) {
            showToast(i18n.components.splash.databaseUpdated.title, i18n.components.splash.databaseUpdated.message, "warn", undefined, 20000);
        }

        // Load the library, if the database has been upgraded, we force a refresh from OneDrive
        if (databaseUpgraded) {
            await reloadDatabaseFromOnedriveAsync(true);
        }

        await initializeLibraryAsync();
        if (!lib) {
            // This should never happen, but just in case
            showClientKeyInput = true;
            return;
        }
    };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="Splash">
    <!-- ver:<strong>{version}</strong> -->
    <img class="logo" src="/solohub_dark.svg" alt="solohub" />

    {#if $appInitializedSubject}
        {#if showClientKeyInput && !$lib}
            <ClientConnect />
        {/if}

        {#if !!$lib}
            <SessionStart />
        {/if}
    {:else}
        <div class="loading-container">
            <span>{$i18n$.components.splash.loadingMessage}...</span>
            <LoadingBar />
        </div>
    {/if}
</div>

<!--######################################################################################-->
<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="Splash"] {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        width: clamp(20rem, 90%, 50rem);
        .logo {
            height: 5rem;
        }

        .loading-container {
            display: flex;
            flex-direction: column;
            text-align: center;
            gap: 0.5rem;
            min-width: 10rem;
            span {
                color: gray;
            }
        }
    }
</style>
