<script lang="ts">
  import { onMount } from "svelte";

  export let progress = 0.624;
  export let color = undefined;
  export let colorText = "white";
  export let size = "1em";

  $: progressPercentage = Math.ceil(progress * 100);

  let mainDiv: HTMLDivElement;

  onMount(() => {
    if (!color) {
      color = window.getComputedStyle(mainDiv).getPropertyValue("color");
    }
  });
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->

<div bind:this={mainDiv} data-component="CloudDownloadProgress" style="--color: {color}; --colorText: {colorText}; --size: {size}">
  <i class="fa-solid fa-cloud" />
  <div class="progress-text">
    <span class="progress-number">{progressPercentage}</span>
    <span class="progress-percentage">%</span>
  </div>
  <div class="progress-container">
    <div class="progress-line" style={"width:" + progressPercentage + "%"} />
  </div>
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="CloudDownloadProgress"] {
    font-size: var(--size);
    display: grid;
    grid-template-columns: min-content;
    grid-template-rows: min-content;
  }

  i {
    grid-column: 1;
    grid-row: 1;
    color: var(--color);
  }

  .progress-text {
    grid-column: 1;
    grid-row: 1;
    color: var(--colorText);
    justify-self: center;
    align-self: flex-end;
    font-size: 0.5em;
    display: flex;
    align-items: baseline;
    gap: 0.1em;
    margin-bottom: 0.2em;

    .progress-number {
      font-size: 0.8em;
    }
    .progress-percentage {
      font-size: 0.5em;
    }
  }

  .progress-container {
    display: flex;
    .progress-line {
      background-color: var(--color);
      height: 0.15em;
      border-radius: 0.2em;
    }
  }
</style>
