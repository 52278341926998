<script lang="ts">
  import { onMount } from "svelte";

  export let selectedId: string;
  let mainDiv: HTMLDivElement;
  let elements: Element[];

  $: {
    if (elements) {
      elements
        .filter((el) => el.id !== selectedId)
        .forEach((el) => {
          el.classList.remove("switch-container-show");
          el.classList.add("switch-container-hide");
        });

      const selected = elements.find((el) => el.id.split("|").includes(selectedId));
      if (selected) {
        selected.classList.add("switch-container-show");
        selected.classList.remove("switch-container-hide");
      }
    } else {
    }
  }

  let loaded = false;
  onMount(() => {
    elements = [...mainDiv.children];
    elements.forEach((el) => {
      el.classList.remove("switch-container-show");
      el.classList.add("switch-container-hide");
    });

    // Workaround to bypass css transition of 0.3s to avoid initial flickering
    setTimeout(() => {
      loaded = true;
    }, 300);
  });
</script>

<div class={$$props.class} bind:this={mainDiv} data-component="SwitchContainer" class:hide={!loaded}>
  <slot />
</div>

<style lang="scss">
  [data-component="SwitchContainer"] {
    display: grid;
  }

  .el {
    grid-column: 1;
    grid-row: 1;
  }

  :global {
    .switch-container-show {
      opacity: 1;
      transition: opacity 0.3s;
    }
    .switch-container-hide {
      display: block;
      opacity: 0;
      transition: all 0.3s;
      pointer-events: none;
    }

    [data-component="SwitchContainer"] {
      > * {
        grid-column: 1;
        grid-row: 1;
      }
    }
  }
</style>
