<script lang="ts">
  import shortUUID from "short-uuid";
  import { onDestroy, onMount } from "svelte";
  import LibraryTreeFolder from "../components/library-tree-folder.svelte";
  import LibraryTreeSong from "../components/library-tree-song.svelte";
  import OneDriveLoadingProgress from "../components/one-drive-loading-progress.svelte";
  import SongCalloutDialog from "../dialogs/song-callout-dialog.svelte";
  import { i18n, i18n$ } from "../i18n/i18n";
  import type { IFolder, Song } from "../models";
  import { settings } from "../settings";
  import { downloadedSong$, getFirstLevelFolders, getFirstLevelSongs, lib, updateLibraryAsync } from "../stores/library-store";
  import { showToast } from "../stores/toaster-store";
  import { sum } from "../utils/general";
  import { clearSubscriptions, safeSubscribe } from "../utils/rx-utils";
  import Modal from "./modal.svelte";
  const cId = shortUUID.generate();

  let modal: Modal;
  let songCalloutDialog: SongCalloutDialog;

  let totalDownloadedCount = 0;
  let totalDownloadedSize = 0;

  let rootFolders: IFolder[] = [];
  let rootSongs: Song[] = [];

  onMount(() => {
    safeSubscribe(
      cId,
      downloadedSong$.subscribe(() => {
        const downloadedSongs = lib.value.songs.filter((s) => s.downloadStatus === "downloaded");
        totalDownloadedCount = downloadedSongs.length;
        totalDownloadedSize = sum(downloadedSongs.map((s) => s.size));
      }),
      lib.subscribe(() => {
        rootFolders = getFirstLevelFolders().sortBy((s) => s.name, settings.folderSortStartWithAtLast);
        rootSongs = getFirstLevelSongs().sortBy((s) => s.title, settings.fileSortStartWithAtLast);
      })
    );
  });

  onDestroy(() => {
    clearSubscriptions(cId);
  });

  export const openDialogAsync = async () => {
    await modal.openDialogAsync();
  };

  let refreshing = false;
  const refreshLibrary = async () => {
    if (!navigator.onLine) {
      showToast(i18n.toasts.internetConnectionRequired.title, i18n.toasts.internetConnectionRequired.messageSyncToLibrary, "danger");
      return;
    }

    refreshing = true;

    await updateLibraryAsync();

    refreshing = false;
  };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<Modal
  bind:this={modal}
  config={{
    title: i18n.dialogs.library.title,
    buttons: ["close", "x"],
    dialogHeight: "90dvh",
    autoSize: false,
    headerIcon: "fa-solid fa-database",
  }}
>
  <div data-component="MusicLibraryDialog">
    <div class="dialog-header">
      <div class="total-grid">
        <div class="header">
          <div>{$i18n$.dialogs.library.inMemory}</div>
        </div>
        <div class="cell">
          <div class="count">{totalDownloadedCount}</div>
          <div class="label">{$i18n$.dialogs.library.files}</div>
        </div>
        <div class="cell">
          <div class="count">{(totalDownloadedSize / 1000000).toFixed(1)}</div>
          <div class="label">{$i18n$.dialogs.library.mb}</div>
        </div>
      </div>

      <button on:click={() => refreshLibrary()} disabled={refreshing}>
        <span>{$i18n$.dialogs.library.resync}</span>
        {#if refreshing}
          <i class="fas fa-circle-notch fa-spin" />
        {/if}
      </button>
    </div>

    {#if refreshing}
      <div class="loading">
        <OneDriveLoadingProgress />
      </div>
    {:else}
      <div class="list">
        {#each rootFolders as folder}
          <LibraryTreeFolder {folder} />
        {/each}
        {#each rootSongs as song}
          <LibraryTreeSong {song} />
        {/each}
      </div>
    {/if}
  </div>
</Modal>

<SongCalloutDialog bind:this={songCalloutDialog} />

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="MusicLibraryDialog"] {
    width: clamp(30rem, 90vw, 60rem);
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .dialog-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1rem;
    }

    .total-grid {
      display: flex;
      border: 0.2rem solid #dddddd;
      align-items: center;
      .header {
        grid-column-end: span 2;
        display: flex;
        align-items: center;
        gap: 0.3rem;

        background-color: #dddddd;
        padding: 0.5rem 1rem;

        .sub {
          font-size: 0.7rem;
        }
      }

      .cell {
        display: flex;
        gap: 0.3rem;
        align-items: baseline;
        justify-content: center;
        padding: 0.2rem 1rem;
        .label {
          font-size: 0.7rem;
        }
        .count {
          font-weight: bold;
        }
      }
    }

    button {
      align-self: flex-start;
    }

    .loading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: auto;
    }

    .list {
      flex: auto;
      overflow: auto;
    }
  }
</style>
