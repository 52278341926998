<script lang="ts">
    import { Subject, debounceTime, take } from "rxjs";
    import { generate } from "short-uuid";
    import { createEventDispatcher, onDestroy, onMount } from "svelte";
    import { appClickedSubject } from "../stores/app-store";
    import { AudioManager } from "../utils/audio-manager";
    import { secondsToTime } from "../utils/general";
    import { clearSubscriptions, safeSubscribe } from "../utils/rx-utils";

    const cId = generate();
    const dispatch = createEventDispatcher();

    let seeker: HTMLInputElement;
    let audioManager: AudioManager;
    let thumbValue = 0;
    let thumbDragging = false;
    let progressRatio = 0;

    export let audioPlayerInstanceName: string = undefined;
    let status = "empty";
    let currentTime = 0; // Do not export, use timeChanged event instead
    let duration = 0;
    let loading = false;

    onMount(async () => {
        audioManager = new AudioManager(audioPlayerInstanceName);

        safeSubscribe(
            cId,

            appClickedSubject.pipe(take(1)).subscribe(async () => {
                audioManager.wakeup();
            }),

            audioManager.currentTime$.subscribe((time) => {
                updateTime(time);
            }),

            audioManager.status$.subscribe((value) => {
                status = value;
                dispatch("statusChanged", value);
            }),

            audioManager.ended$.subscribe(() => {
                dispatch("ended");
            }),

            thumbDroppedSubject.pipe(debounceTime(100)).subscribe(() => thumbDropped()),
        );
    });

    onDestroy(() => {
        audioManager.destroy();
        clearSubscriptions(cId);
    });

    export let volume = 1;
    $: {
        if (audioManager) {
            audioManager.volume = volume;
        }
    }

    let snapThreshold = 3; // 3 seconds
    let snapTime: number = undefined;
    $: {
        if (snapTime <= 0 || snapTime >= duration) {
            snapTime = undefined;
        }
        dispatch("snapTimeChanged", snapTime);
    }

    // Update audio progress bar
    $: {
        progressRatio = duration ? thumbValue / duration : 0;
        seeker?.style.setProperty("--ratio", progressRatio.toString());
    }

    const thumbDroppedSubject = new Subject<void>();

    export const unloadAsync = async () => {
        await loadAsync(undefined);
    };

    export const loadAsync = async (source: string | ArrayBuffer, autoplay = false) => {
        loading = true;
        currentTime = 0;
        duration = 0;

        if (!source) {
            audioManager.unload();
            return;
        }

        await audioManager.loadAsync([{ source: source }]);

        duration = audioManager.duration;

        loading = false;

        if (autoplay) {
            await play();
        }
    };

    export const play = async (rampUpTime?: number) => {
        await audioManager?.play(rampUpTime);
    };

    export const pause = async (fadeOutTime?: number) => {
        await audioManager?.pause();
    };

    export const togglePlay = async (rampUpTime?: number, fadeOutTime?: number) => {
        await audioManager?.togglePlay(rampUpTime, fadeOutTime);
    };

    export const seek = async (time: number, relative = false) => {
        const seekTime = relative ? currentTime + time : time;
        await audioManager?.seek(seekTime);
        dispatch("TimeUpdatedByUser", seekTime);
    };

    export const setSnap = (time: number = undefined) => {
        if (time) {
            snapTime = Math.max(0, Math.min(time, duration));
        } else {
            snapTime = currentTime;
        }
    };
    export const clearSnap = () => {
        snapTime = undefined;
    };

    export const goToSnap = async () => {
        await seek(snapTime);
    };

    const thumbDrag = () => {
        if (snapTime) {
            if (Math.abs(thumbValue - snapTime) < snapThreshold) {
                thumbValue = snapTime;
            }
        }
    };

    const thumbDropped = async () => {
        thumbDragging = false;
        await seek(thumbValue);
    };

    const updateTime = (value: number) => {
        currentTime = value;

        // Only advance the thumb if it's not being dragged
        if (!thumbDragging) {
            thumbValue = value;
            dispatch("timeChanged", value);
        }
    };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="AudioPlayer">
    <div class="top-container">
        <i
            class="snap-pin fa-solid fa-solid fa-thumbtack"
            style="position:relative; left:{(snapTime * 100) / duration}%"
            class:visible={!!snapTime}
        />

        <div class="thumb-drag-flyout" style="position:relative; left:{(thumbValue * 100) / duration}%" class:visible={thumbDragging}>
            {secondsToTime(thumbValue)}
        </div>
    </div>

    <input
        bind:this={seeker}
        type="range"
        min="0"
        max={duration}
        bind:value={thumbValue}
        step="0.1"
        class:empty-media={status === "empty"}
        on:input={() => thumbDrag()}
        on:change={() => thumbDroppedSubject.next()}
        on:mousedown={() => (thumbDragging = true)}
        on:mouseup={() => thumbDroppedSubject.next()}
        on:pointerdown={() => (thumbDragging = true)}
        on:pointerup={() => thumbDroppedSubject.next()}
        on:touchstart={() => (thumbDragging = true)}
        on:touchend={() => thumbDroppedSubject.next()}
    />

    <div class="bottom-timers" class:hide={status === "empty" || status === "loading"}>
        <div>{secondsToTime(currentTime)}</div>
        <div>{secondsToTime(duration - currentTime)}</div>
    </div>
</div>

<!--######################################################################################-->
<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="AudioPlayer"] {
        $range-color-left: $primary;
        $range-color-right: $primary-light;
        $range-height: 1.25rem;
        $range-track-radius: 0.3rem;

        $range-thumb-color: #ffffff;
        $range-thumb-height: 1.25rem;
        $range-thumb-width: 2.5rem;
        $range-thumb-border-radius: 0.3rem;

        $thumb-moving-text-width: 6.5rem;

        input[type="range"] {
            width: 100%;
            margin: 0; // override default
            height: $range-height;
            border-radius: $range-track-radius;
            appearance: none;
            -webkit-appearance: none;

            --ratio: 0;
            --sx: calc(0.5 * #{$range-thumb-width} + var(--ratio) * (100% - #{$range-thumb-width}));
            background:
                linear-gradient($range-color-left, $range-color-left) 0 / var(--sx) 100% no-repeat,
                $range-color-right;

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                width: $range-thumb-width;
                height: $range-thumb-height;
                border-radius: $range-thumb-border-radius;
                background: $range-thumb-color;

                box-shadow: 0 0 0.2rem black;

                transition: transform 0.2s ease-in-out;
                &:active {
                    transform: scaleY(1.2);
                    transition: transform 0.2s ease-in-out;
                }
            }

            &.empty-media {
                background: lightgray;
                opacity: 0.8;
                background-size: 0.8rem 0.8rem;
                transition: all 0.2s ease-in-out;

                &::-webkit-slider-thumb {
                    width: 0;
                    height: 0;
                }
            }
        }

        .top-container {
            @include overlay-container;
            margin-left: calc($range-thumb-width / 2);
            margin-right: calc($range-thumb-width / 2);
            justify-items: start;
            align-items: flex-end;

            .thumb-drag-flyout {
                width: min-content;
                transform: translateX(-50%);
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
                background-color: transparentize($background-light, 0.3);
                margin-bottom: 0.3rem;
                padding: 0 0.2rem;
                font-weight: 500;
                &.visible {
                    opacity: 1;
                }
            }

            .snap-pin {
                color: $primary;
                width: min-content;
                transform: translateX(-50%);
                font-size: 1.2rem;
                margin-bottom: 0.3rem;
                opacity: 0;
                transition: opacity 0.3s ease-in-out;

                &.visible {
                    opacity: 1;
                }
            }
        }

        .bottom-timers {
            display: flex;
            justify-content: space-between;
            font-size: 0.8rem;
        }
    }
</style>
