<script lang="ts">
    import { Subscription, filter, skip } from "rxjs";
    import { generate } from "short-uuid";
    import { onDestroy } from "svelte";
    import { fade } from "svelte/transition";
    import { appClickedSubject, getNextModalZIndex } from "../stores/app-store";

    const cId = generate();

    export let menuItems: { text: string; icon: string; action: () => void }[] = [];

    let mainContainer: HTMLDivElement;
    let popupButtonContainer: HTMLDivElement;
    let opened = false;
    let subscription: Subscription;
    let modalZIndex = getNextModalZIndex();

    onDestroy(() => {
        subscription?.unsubscribe();
    });

    const open = () => {
        modalZIndex = getNextModalZIndex();
        opened = true;
        subscription = appClickedSubject
            .pipe(
                skip(1), // skip first or it will close instantly
                filter((e) => e.target !== mainContainer),
            )
            .subscribe(() => close());
    };

    const close = () => {
        opened = false;
        subscription?.unsubscribe();
    };

    const toggle = () => {
        if (opened) {
            close();
        } else {
            open();
        }
    };

    const menuItemClicked = (itm: { text: string; action: () => void }) => {
        itm.action();
        close();
    };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="PopupMenuButton" bind:this={mainContainer}>
    <div bind:this={popupButtonContainer} class="popup-menu-button" on:click={() => toggle()}>
        <slot />
    </div>
    <div class="popup-container">
        {#if opened}
            <div class="popup-menu" transition:fade style="--popupZIndex:{modalZIndex}">
                {#each menuItems as itm}
                    <div class="menu-item">
                        <button class="empty-button" on:click={() => menuItemClicked(itm)}>
                            <i class={itm.icon} />
                            <span>{itm.text}</span>
                        </button>
                    </div>
                {/each}
            </div>
        {/if}
    </div>
</div>

<!--######################################################################################-->
<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="PopupMenuButton"] {
        .popup-container {
            position: relative;

            .popup-menu {
                z-index: var(--popupZIndex);
                background-color: white;
                margin-top: 0.2rem;
                right: 0;
                white-space: nowrap;
                font-size: 1.2rem;
                position: absolute;
                border-radius: 0.5rem;
                box-shadow: 0.5rem 0.5rem 0.8rem #000000aa;

                .menu-item {
                    cursor: pointer;

                    white-space: nowrap;
                    font-size: 1.2rem;

                    button {
                        border-radius: 0.5rem;
                        color: $bold;
                        display: flex;
                        gap: 1rem;
                        padding: 1rem;
                        width: 100%;
                    }

                    &:hover {
                        background-color: $lightgray;
                    }
                }
            }
        }
    }
</style>
