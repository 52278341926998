<script lang="ts">
  import Qrcode from "../components/qrcode.svelte";
  import SignalrConnectionIcon from "../components/signalr-connection-icon.svelte";
  import UserTag from "../components/user-tag.svelte";
  import { i18n, i18n$ } from "../i18n/i18n";
  import { sessionInfoSubject } from "../stores/session-store";
  import { connectToSignalrAsync, signalRConnectedClientsSubject, signalRConnectionStatusSubject } from "../stores/signalr-store";
  import Modal from "./modal.svelte";

  let modal: Modal;

  let sessionUrl: string;

  export const openDialogAsync = () => {
    const sessionId = sessionInfoSubject.value.id;
    if (sessionId) {
      sessionUrl = `${import.meta.env.VITE_SOLOHUB_MOBILE_URL}?sId=${sessionId}`;
    }
    return modal.openDialogAsync();
  };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<Modal
  bind:this={modal}
  config={{
    title: i18n.dialogs.sessionInfo.title,
    buttons: ["close", "x"],
    autoSize: true,
  }}
>
  <div data-component="SessionQrcodeDialog">
    {#if $signalRConnectionStatusSubject === "connected"}
      <div class="session-info-content">
        <div class="mobile-app-url">
          <a href={sessionUrl} target="_blank" rel="noreferrer">
            {import.meta.env.VITE_SOLOHUB_MOBILE_URL}
          </a>
        </div>

        <div class="mini-panel">
          <div class="title">{$i18n$.dialogs.sessionInfo.sessionCode}</div>
          <div class="content">
            <div class="session-code">
              {$sessionInfoSubject.id}
            </div>
            <Qrcode url={sessionUrl} />
          </div>
        </div>

        <div class="connected-users-header">
          <div>{$i18n$.dialogs.sessionInfo.connectedUsers}</div>
          <div class="connected-users-count">{$signalRConnectedClientsSubject?.length || 0}</div>
        </div>
        <div class="connected-users">
          {#each $signalRConnectedClientsSubject as client}
            <UserTag username={client.username} />
          {/each}
        </div>
      </div>
    {:else}
      <div class="connection-info">
        <div class="icon">
          <SignalrConnectionIcon status={$signalRConnectionStatusSubject} />
        </div>
        {#if $signalRConnectionStatusSubject === "connecting"}
          <div>{$i18n$.dialogs.sessionInfo.connectingToServer}</div>
        {/if}

        {#if $signalRConnectionStatusSubject === "disconnected"}
          <button on:click={() => connectToSignalrAsync()}>{$i18n$.common.reconnect}</button>
        {/if}
      </div>
    {/if}
  </div>
</Modal>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="SessionQrcodeDialog"] {
    height: 100%;

    .connection-info {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      display: flex;
      flex-direction: column;
      gap: 1rem;

      .icon {
        font-size: 5rem;
      }
    }

    .session-info-content {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .mobile-app-url {
        background-color: $primary;
        color: white;
        width: 100%;
        padding: 0.5rem 2rem;
        border-radius: 0.5rem;
        font-size: 0.8rem;
        text-align: center;

        a {
          text-decoration: none;
          color: white;
        }
      }

      .mini-panel {
        display: flex;
        flex-direction: column;
        min-width: 200px;

        .title {
          background-color: $primary;
          color: white;
          text-align: center;
          font-size: 1rem;
          padding: 0.5rem 1rem;
          border-radius: 0.2rem 0.2rem 0 0;
        }
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          border-width: 0 0.2rem 0.2rem 0.2rem;
          border-style: solid;
          border-color: $primary;
          padding: 0.5rem;
        }
      }

      .session-code {
        color: $bold;
        text-decoration: none;
        font-weight: bold;
        font-size: 26px;
        font-family: "Andale Mono Regular";
      }

      .connected-users-header {
        display: flex;
        align-items: center;
        gap: 1rem;

        .connected-users-count {
          font-weight: bold;
        }
      }
      .connected-users {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
      }
    }
  }
</style>
