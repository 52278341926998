<script lang="ts">
  import NavigateFolderSong from "../components/navigate-folder-song.svelte";
  import { i18n } from "../i18n/i18n";
  import type { IFolder } from "../models";
  import { getRootFolder } from "../stores/library-store";

  import Modal from "./modal.svelte";

  let currentFolder: IFolder = undefined;

  let modal: Modal;

  export const openDialogAsync = async (): Promise<IFolder> => {
    currentFolder = getRootFolder();

    var res = await modal.openDialogAsync();

    return res ? currentFolder : undefined;
  };
</script>

<!-- COMPONENT -->
<Modal
  bind:this={modal}
  config={{
    title: i18n.dialogs.selectFolder.title,
    buttons: ["ok", "cancel", "x"],
    acceptCustomText: i18n.dialogs.selectFolder.selectFolder,
    headerIcon: "fa-regular fa-folder-open",
    dialogHeight: "90dvh",
    autoSize: false,
  }}
>
  <div data-component="SelectFolderDialog">
    <NavigateFolderSong songSelectionEnabled={false} on:folderChanged={(e) => (currentFolder = e.detail)} />
  </div>
</Modal>

<!-- STYLE -->
<style lang="scss">
  [data-component="SelectFolderDialog"] {
    min-width: 30rem;
    max-width: 80vw;
    width: clamp(30rem, 50rem, 80vw);
    height: 100%;
    overflow: auto;
  }
</style>
