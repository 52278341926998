<script lang="ts">
    import { i18n$ } from "../i18n/i18n";
    import Popup from "./popup.svelte";
    import Slider from "./slider.svelte";

    export let volume;
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="volume">
    <Popup>
        <div slot="button" class="volume-icon-container">
            <i class="fa-solid fa-volume-high" />
            <span class="volume-value">{Math.floor(volume * 100)} %</span>
        </div>
        <div slot="popup" class="popup-volume-container">
            <div class="popup-volume-header">
                <div class="volume-title">{$i18n$.components.volume.title}</div>
                <div class="volume-value">{Math.floor(volume * 100)} %</div>
            </div>
            <Slider bind:value={volume} min={0} max={1.5} step={0.01} snaps={[{ value: 1, showTick: true, snapThreshold: 0.05, label: "100 %" }]} />

            {#if volume > 1}
                <div class="warn-boost">
                    <span>{$i18n$.components.volume.warnBoost}</span>
                </div>
            {/if}
        </div>
    </Popup>
</div>

<!--######################################################################################-->
<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="volume"] {
        .volume-icon-container {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            cursor: pointer;
            i {
                font-size: 1.3rem;
            }
            .volume-value {
                white-space: nowrap;
                width: 3em;
            }
        }
        .popup-volume-container {
            display: flex;
            flex-direction: column;
            align-self: center;
            justify-items: center;
            overflow: hidden;
            gap: 1rem;

            color: $bold;
            width: 25rem;
            padding: 1rem;

            .popup-volume-header {
                display: flex;
                justify-content: space-between;
                gap: 1rem;

                .volume-title {
                    font-size: 1.2rem;
                }
                .volume-value {
                    font-weight: bold;
                }
            }

            .warn-boost {
                white-space: break-spaces;
                word-wrap: break-word;
                color: $darkred;
                text-align: center;
                font-size: 1rem;
            }
        }
    }
</style>
