<script lang="ts">
  import { Subscription, map } from "rxjs";
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { i18n } from "../i18n/i18n";
  import type { IFolder, Song } from "../models";
  import {
    addSongToDownloadQueue,
    downloadingSongs$,
    getAllSongs,
    getChildFolders,
    getFolderSongs,
    songsDownloadQueue$,
  } from "../stores/library-store";
  import { showToast } from "../stores/toaster-store";
  import { round, sum } from "../utils/general";
  import LibraryTreeSong from "./library-tree-song.svelte";

  export let folder: IFolder;
  export let expanded = false;

  let subscriptions: Subscription[] = [];

  let childFolders: IFolder[] = [];
  let childSongs: Song[] = [];

  let folderDownloadingSongs$ = downloadingSongs$.pipe(map((songs) => songs.filter((s) => s.parentFolderId === folder.id)));

  let dispatch = createEventDispatcher();

  let downloadedFilesCount = 0;
  let totalFilesCount = 0;
  let totalSizeToDownload = 0;

  onMount(() => {
    loadCurrentFolder();

    subscriptions = [
      folderDownloadingSongs$.subscribe(() => {
        updateFolderInfo();
      }),
    ];
  });

  onDestroy(() => {
    subscriptions.forEach((s) => s.unsubscribe());
  });

  const updateFolderInfo = () => {
    const folderSongs = getFolderSongs(folder.id);
    loadCurrentFolder();
    downloadedFilesCount = getAllSongs().filter((s) => s.downloadStatus === "downloaded" && s.parentFolderId === folder.id).length;
    totalFilesCount = folderSongs.length;
    totalSizeToDownload = sum(folderSongs.filter((s) => s.downloadStatus !== "downloaded").map((s) => s.size));
    totalSizeToDownload = round(totalSizeToDownload / 1000000, 1);
    dispatch("folderUpdated");
  };

  const loadCurrentFolder = () => {
    childFolders = getChildFolders(folder.id).sortBy((s) => s.name);
    childSongs = getFolderSongs(folder.id).sortBy((s) => s.title);
  };

  const downloadAll = async () => {
    if (!navigator.onLine) {
      showToast(i18n.toasts.internetConnectionRequired.title, i18n.toasts.internetConnectionRequired.message, "danger");
      return;
    }

    childSongs.filter((s) => s.downloadStatus !== "downloaded").forEach((s) => addSongToDownloadQueue(s));
  };
  const deleteAll = () => {
    childSongs
      .filter((s) => s.downloadStatus === "downloaded")
      .forEach(async (s) => {
        await s.deleteLocalDataAsync();
        updateFolderInfo();
      });
  };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="LibraryTreeFolder">
  <div class="folder-info">
    <button class="empty-button expand-collapse-button" on:click={() => (expanded = !expanded)}>
      {#if expanded}
        <i class="fa-solid fa-chevron-down" />
      {:else}
        <i class="fa-solid fa-chevron-right" />
      {/if}
      <span class="folder-text">{folder?.name}</span>
    </button>

    <div class="action-container">
      {#if $folderDownloadingSongs$.length > 0}
        <div>
          <i class="fas fa-circle-notch fa-spin" />
          {#if $songsDownloadQueue$.some((s) => s.parentFolderId === folder.id)}
            <span>{totalFilesCount - downloadedFilesCount}</span>
          {:else}
            <span>{$folderDownloadingSongs$.length}</span>
          {/if}
        </div>
      {:else}
        {#if downloadedFilesCount > 0}
          <button class="empty-button delete-button" on:click={deleteAll}>
            <span>({downloadedFilesCount})</span>
            <i class="delete-icon fa-regular fa-trash-can" />
          </button>
        {/if}

        {#if downloadedFilesCount < totalFilesCount}
          <button class="empty-button download-button" on:click={downloadAll}>
            <div class="song-size">
              {totalSizeToDownload} Mb
            </div>
            <i class="download-icon fa-solid fa-cloud-arrow-down" />
          </button>
        {/if}
      {/if}
    </div>
  </div>

  {#if expanded}
    <div class="childs">
      {#each childFolders as subFolder}
        <svelte:self folder={subFolder} />
      {/each}

      {#each childSongs as song}
        <LibraryTreeSong {song} />
      {/each}
    </div>
  {/if}
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  .folder-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin: 0.2rem 0.5rem;
    background: $folder-color;
    border: 1px solid $folder-border-color;
    border-radius: 0.5rem;
    flex: auto;

    .expand-collapse-button {
      padding: 1rem;
      flex: auto;
      color: $folder-border-color;
      i {
        width: 1rem;
      }
    }

    .folder-text {
      font-weight: 500;
      color: $black;
    }

    .action-container {
      margin-right: 1rem;
      display: flex;
      gap: 3rem;
      justify-content: flex-end;
      i {
        font-size: 1.5rem;
      }

      button {
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;
        align-items: baseline;
        .song-size {
          white-space: nowrap;
          font-size: 0.7rem;
        }
      }

      .delete-button {
        color: $darkred;
        align-items: flex-end;
        span {
          font-size: 0.7rem;
          white-space: nowrap;
        }
      }
    }
  }

  .childs {
    margin-left: 2.5rem;
  }
</style>
