
import { Buffer } from "buffer";
import { BehaviorSubject } from "rxjs";
import { get } from "svelte/store";
import type { IFolder, ISong } from "../models";
import { FromBase64 } from "../stores/client-store";
import { dataUrl } from "../stores/settings-store";
import { fetchRetryWithTimeout } from "./fetch";

const fileExtensionRegex = /\.[^/.]+$/;
const calloutRegex = /\((.*?)\)/;
const subtitleRegex = /\[(.*?)\]/;

const supportedMimeTypes = ["audio/mp4", "audio/mpeg"]

export const oneDriveLoadingSubject = new BehaviorSubject<boolean>(false);
export const libraryLoadingCurrentFolderName = new BehaviorSubject<string>("");
export const libraryLoadingFolderCount = new BehaviorSubject<number>(0);
export const libraryLoadingSongCount = new BehaviorSubject<number>(0);



export const getOneDriveDataAsync = async (): Promise<{ folders: IFolder[], songs: ISong[] }> => {

    oneDriveLoadingSubject.next(true);
    libraryLoadingCurrentFolderName.next(undefined);
    libraryLoadingFolderCount.next(0)
    libraryLoadingSongCount.next(0)

    const onedriveUrl = FromBase64(get(dataUrl));
    const result = await loadOneDriveDataRecursiveAsync(onedriveUrl);
    oneDriveLoadingSubject.next(false);

    return result;
}

const getNameAndSuffix = (name: string): { name: string, suffix: string } => {
    const start = name.indexOf("[")
    const end = name.indexOf("]")

    if (start === -1 || end === -1) {
        return { name, suffix: "" }
    }

    return {
        name: name.substring(0, start).trim(),
        suffix: name.substring(start + 1, end).trim(),
    }
}


const loadOneDriveDataRecursiveAsync = async (oneDriveUrl: string): Promise<{ folders: IFolder[], songs: ISong[] }> => {

    if (!oneDriveUrl) {
        throw new Error("No OneDrive URL provided")
    }

    const key = getKeyFromSharedUrl(oneDriveUrl);
    // eslint-disable-next-line max-len
    const url = `${import.meta.env.VITE_ONEDRIVE_API_BASE_URL}/${key}/driveItem?select=id,name,webUrl,lastModifiedDateTime&expand=children(select=id,name,webUrl,folder,file,size,audio,@content.downloadUrl,lastModifiedDateTime)`;

    let data: any;
    try {
        const response = await fetchRetryWithTimeout(url, undefined, 5000, 3)
        data = await response.json();
    } catch (e) {
        throw new Error(`Error loading OneDrive url ${oneDriveUrl}`);
    }

    const folderInfo = getNameAndSuffix(data.name);
    libraryLoadingCurrentFolderName.next(folderInfo.name);

    const folder: IFolder = {
        id: data.id,
        originalName: data.name,
        name: folderInfo.name,
        webUrl: data.webUrl,
        lastModifiedDateTime: data.lastModifiedDateTime,
        suffix: folderInfo.suffix,
    };

    const songs: ISong[] = data.children
        .filter((o: any) => o.file && supportedMimeTypes.includes(o.file.mimeType))
        .map(o => getSong(o, folder.id));

    libraryLoadingSongCount.next(libraryLoadingSongCount.value + songs.length)

    const childFoldersUrls = data.children.filter((o: any) => o.folder).map((o: any) => o.webUrl)

    const folders = [folder]

    for await (const childFolderWebUrl of childFoldersUrls) {
        const subFolderResult = await loadOneDriveDataRecursiveAsync(childFolderWebUrl);

        // first of the returned list is the direct child, set its parent to the current folder
        subFolderResult.folders[0].parentFolderId = folder.id;

        folders.push(...subFolderResult.folders);
        songs.push(...subFolderResult.songs);
    }

    return { folders, songs };
}


const getKeyFromSharedUrl = (url: string): string => {
    let base64Value = Buffer.from(url).toString("base64");
    base64Value = base64Value.substring(0, base64Value.lastIndexOf("="));
    return "u!" + base64Value.replaceAll("/", "_").replaceAll("+", "-");
};


const getSong = (onedriveItem: any, parentFolderId: string): ISong => {

    if (!onedriveItem.file?.mimeType) {
        console.log("no mimetype for", onedriveItem.name)
    }
    if (!onedriveItem.audio?.duration) {
        console.log("no duration for ", onedriveItem.name)
    }

    const song: ISong = {
        id: onedriveItem.id,
        parentFolderId: parentFolderId,
        filename: onedriveItem.name,
        webUrl: onedriveItem.webUrl,
        mimeType: onedriveItem.file?.mimeType,
        size: onedriveItem.size,
        durationFromMeta: onedriveItem.audio?.duration,
        downloadUrl: onedriveItem["@content.downloadUrl"],
        lastModifiedDateTime: onedriveItem.lastModifiedDateTime
    };
    processFilename(song);
    return song;
}


const processFilename = (song: ISong) => {
    // Extraction de l'extension 
    const filenameNoExt = song.filename.replace(fileExtensionRegex, "");

    // Extraction du callout (entre parenthèses), si pas trouvé utiliser le nom du fichier entier
    const calloutMatch = filenameNoExt.match(calloutRegex);
    song.calloutText = calloutMatch ? calloutMatch[1].trim() : filenameNoExt;

    // Extraction du subtitle (entre crochets)
    const subtitleMatch = filenameNoExt.match(subtitleRegex);
    song.subtitle = subtitleMatch ? subtitleMatch[1] : '';

    // Extraction du titre (le reste)
    song.title = filenameNoExt.replace(calloutRegex, '').replace(subtitleRegex, '').trim();
}