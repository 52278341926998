<script lang="ts">
  import "@fontsource-variable/inter";
  import type { Subscription } from "rxjs";
  import { onDestroy, onMount } from "svelte";
  import { fade } from "svelte/transition";
  import AppConfirmDialogWrapper from "./components/app-confirm-dialog-wrapper.svelte";
  import Splash from "./components/splash.svelte";
  import Toaster from "./components/toaster.svelte";
  import { default as AddToQueueDialog, default as SelectSongDialog } from "./dialogs/add-to-queue-dialog.svelte";
  import LibraryDialog from "./dialogs/library-dialog.svelte";
  import SessionHistoryDialog from "./dialogs/session-history-dialog.svelte";
  import SessionInfoDialog from "./dialogs/session-info-dialog.svelte";
  import SettingsDialog from "./dialogs/settings-dialog.svelte";
  import SongCalloutDialog from "./dialogs/song-callout-dialog.svelte";
  import AppBottomBar from "./layout-components/app-bottom-bar.svelte";
  import AppTopBar from "./layout-components/app-top-bar.svelte";
  import SessionPage from "./session/session-page.svelte";
  import { appClickedSubject, initAppStore } from "./stores/app-store";
  import { bus$, type BusMessage } from "./stores/bus";
  import { sessionInfoSubject } from "./stores/session-store";
  import { clientName } from "./stores/settings-store";

  let settingsDialog: SettingsDialog;
  let libraryDialog: LibraryDialog;
  let sessionInfoDialog: SessionInfoDialog;
  let songCalloutConfigDialog: SongCalloutDialog;
  let sessionHistoryDialog: SessionHistoryDialog;
  let addToQueueDialog: SelectSongDialog;

  let subscriptions: Subscription[] = [];

  onMount(async () => {
    await initAppStore();

    subscriptions = [bus$.subscribe(processBusMessage)];
  });

  onDestroy(() => {
    subscriptions.forEach((s) => s.unsubscribe());
  });

  const processBusMessage = (msg: BusMessage) => {
    switch (msg.type) {
      case "DialogShowLibrary":
        libraryDialog.openDialogAsync();
        break;
      case "DialogShowSettings":
        settingsDialog.openDialogAsync();
        break;
      case "DialogShowSessionInfo":
        sessionInfoDialog.openDialogAsync();
        break;
      case "DialogShowSongCalloutConfig":
        songCalloutConfigDialog.openDialogAsync(msg.data);
        break;
      case "DialogShowSessionHistory":
        sessionHistoryDialog.openDialogAsync();
        break;
      case "DialogShowAddToQueue":
        addToQueueDialog.openDialogAsync();
        break;
    }
  };
</script>

<!--######################################################################################-->

<div data-component="App" on:click={(e) => appClickedSubject.next(e)}>
  {#if $clientName}
    <div class="top">
      <AppTopBar />
    </div>
  {/if}

  {#if !$sessionInfoSubject}
    <div class="splash-container">
      <Splash />
    </div>
  {:else}
    <div class="app-content" class:hide={!$sessionInfoSubject} transition:fade>
      <SessionPage />
    </div>

    <div class="bottom">
      <AppBottomBar />
    </div>
  {/if}

  <div>
    <SettingsDialog bind:this={settingsDialog} />
    <SessionInfoDialog bind:this={sessionInfoDialog} />
    <LibraryDialog bind:this={libraryDialog} />
    <SongCalloutDialog bind:this={songCalloutConfigDialog} />
    <SessionHistoryDialog bind:this={sessionHistoryDialog} />
    <AddToQueueDialog bind:this={addToQueueDialog} />

    <!-- Confirm dialog that is used for all Generic Confirm Dialog called from app-store.showAppConfirmDialogAsync() -->
    <AppConfirmDialogWrapper />

    <Toaster />
  </div>
</div>

<!--######################################################################################-->
<style lang="scss">
  [data-component="App"] {
    height: 100dvh;
    display: flex;
    flex-direction: column;
    overflow: visible;

    .app-content {
      height: 100%;
      overflow: auto;
      flex: auto;
      margin: 1rem;
    }

    .splash-container {
      height: 100%;
      flex: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin-top: 15dvh;
    }

    .bottom {
      display: none;
      @media screen and (max-width: $mobile-max-width), screen and (max-height: $mobile-max-height) {
        display: block;
      }
    }
  }
</style>
