import { add } from "date-fns";
import { BehaviorSubject, distinctUntilChanged, map, of, switchMap } from "rxjs";
import { get } from "svelte/store";
import { i18n, i18nParams } from "../i18n/i18n";
import { QueuedSong, type IQueuedSongDTO, type Song } from "../models";
import type { ISignalRConnectionInfo } from "../models/ISignalRConnectionInfo";
import { findLastIndex, insertItemAtIndex, moveItemToIndex, removeFromArray } from "../utils/array";
import { CustomException } from "../utils/exceptions";
import { addSongToDownloadQueue } from "./library-store";
import { CoachPriorityValues, coachPriorityAmount } from "./settings-store";
import { showToast } from "./toaster-store";

export const minSongDelay = 3; // 3 seconds for the countdown 3 seconds mp3

export const queueSubject = new BehaviorSubject<QueuedSong[]>([]);


export const currentSong$ = queueSubject.pipe(
    map(q => q.find(qs => qs.queueStatus === "current")),
    distinctUntilChanged()
);
export const getCurrentSong = () => {
    return queueSubject.value.find(qs => qs.queueStatus === "current");
}

/**
 * Queue of songs that are in "waiting"
 */
export const waitingQueue$ = queueSubject.pipe(
    map(q => q.filter(qs => qs.queueStatus === "waiting")),
    distinctUntilChanged()
);

/**
 * Queue of songs that are not "history" (it includes current song)
 */
export const activeQueue$ = queueSubject.pipe(
    map(q => q.filter(qs => qs.queueStatus !== "history")),
    distinctUntilChanged()
);

// Tellement hot les switchmap!!!  
// Quand la queue change, on va prendre le dernier et switchmap sur sont plannedEndTime Observable
export const queueEndTime$ = activeQueue$.pipe(
    switchMap(q => q.slice(-1)?.[0]?.plannedEndTime$ ?? of(undefined))
)

export const addToQueue = async (
    song: Song,
    requestedBy?: ISignalRConnectionInfo,
    isCoachRequest = false
): Promise<QueuedSong> => {

    if (queueSubject.value && queueSubject.value.filter(qs => qs.queueStatus !== "history").some(qs => qs.song.id === song.id)) {
        throw new CustomException("AlreadyInQueue", "This song is already in queue");
    }

    const queuedSong = new QueuedSong();
    queuedSong.song = song;
    queuedSong.isCoachRequest = isCoachRequest;
    queuedSong.positionOverridden = false;
    queuedSong.queuedTime = new Date();
    queuedSong.queueStatus = "waiting";
    queuedSong.requestedBy = requestedBy;

    if (queuedSong.song.downloadStatus !== "downloaded") {
        addSongToDownloadQueue(song);
    }

    const queue = queueSubject.value || [];
    queuedSong.sessionOccurence = queue.filter(qs => qs.song.id === song.id).length + 1;
    queuedSong.previousOccurenceTime = queue.findLast(qs => qs.song.id === song.id)?.startTime;

    const nextIndex = getNextQueuePosition(queuedSong);
    insertItemAtIndex(queue, queuedSong, nextIndex)

    // Set bypassed by for all songs below
    queue.slice(nextIndex + 1).forEach((qs) => qs.bypassedBy.push(queuedSong));

    queueSubject.next(queueSubject.value)

    updateQueueTime()

    return queuedSong
}


export const errorDownloadingSong = (song: Song) => {

    showToast(i18n.toasts.downloadError.title, i18nParams(i18n.toasts.downloadError.message, { song: song.title }), "danger")

    const errorInWaitingQueue = queueSubject.value.some(qs => qs.song === song && qs.queueStatus === "waiting")
    if (errorInWaitingQueue) {
        queueSubject.next(queueSubject.value.filter(qs => qs.song !== song))
    }
}

export const getNextQueuePosition = (queuedSong: QueuedSong, specificQueue: QueuedSong[] = undefined) => {

    let queue = specificQueue ?? queueSubject.value;

    // queuedSong is already in queue, so we replace it from its current position (like when we change the isCoachRequest value)
    if (queue.includes(queuedSong)) {
        queue = queue.slice(0, queue.indexOf(queuedSong));
    }

    const maxCoachInRow = get(coachPriorityAmount)

    // Not a coach request, or coach never have priority in settings
    if (!queuedSong.isCoachRequest || maxCoachInRow === CoachPriorityValues.Never) {
        return queue.length;
    }

    let waitingQueueTopIndex = queue.findIndex(qs => qs.queueStatus === "waiting");
    if (waitingQueueTopIndex === -1) {
        // if nothing in waiting, queueTopIndex is the end of the queue
        waitingQueueTopIndex = queue.length;
    }

    const regularPriority = 1; // Nombre d'eleves non en leçon qu'un coach ne peux pas rebypasser, peut être un setting à ajouter
    const lastCoachRequestIndex = findLastIndex<QueuedSong>(queue, qs => qs.isCoachRequest || qs.positionOverridden);

    // No coach request yet, just place it on top of the queue
    if (lastCoachRequestIndex <= 0) {
        return waitingQueueTopIndex;
    }

    // Init the new song Index to right after last coach request
    let nextIndex = lastCoachRequestIndex + 1;

    // Validate rule that we cannot have more than [maxCoachInRow] from the last coach request, and update index accordingly
    if (lastCoachRequestIndex >= 0) {

        // Here we get from the lastcoachrequest and go up to the max number of coach request preceding.
        // If they are all coach request, we add the regular priority to the index to skip non coach request songs
        // must add +1 to slice here because end index parameter is not inclusive
        if (queue.slice(lastCoachRequestIndex - maxCoachInRow + 1, lastCoachRequestIndex + 1).every(qs => qs.isCoachRequest)) {
            nextIndex += regularPriority;
        }

    }

    // ensure that we do not  add on top of history or current song
    return Math.max(waitingQueueTopIndex, nextIndex);

}





export const changeSongCoachRequestStatus = (queuedSong: QueuedSong, isCoachRequest: boolean) => {
    if (isCoachRequest === queuedSong.isCoachRequest) {
        return;
    }

    queuedSong.isCoachRequest = isCoachRequest;
    const queue = queueSubject.value;

    if (isCoachRequest) {

        const oldIndex = queue.indexOf(queuedSong);
        const newIndex = getNextQueuePosition(queuedSong);

        moveItemToIndex(queue, queuedSong, newIndex);

        // Set bypassed by for all songs between its last position and its new position
        queue.slice(newIndex + 1, oldIndex + 1).forEach((qs) => qs.bypassedBy.push(queuedSong))

        queueSubject.next(queue);

    } else {

        // find all songs that have been bypassed by this song
        const bypassed = queue.filter(qs => qs.bypassedBy.includes(queuedSong))
        const lastBypassed = bypassed.slice(-1)[0];
        if (lastBypassed) {
            const newIndex = queue.indexOf(lastBypassed) + 1
            moveItemToIndex(queue, queuedSong, newIndex)

            // songs are not bypassed anymore by this song
            bypassed.forEach((bypassedQs) => removeFromArray(bypassedQs.bypassedBy, queuedSong));
        }


        queueSubject.next(queue)

    }
}

// const changeSongPosition = (queuedSong: QueuedSong, activeQueueIndex: number) => {
//     return 123;

// }

export const dequeueNextSong = () => {

    const queue = queueSubject.value

    const oldSong = getCurrentSong();
    if (oldSong) {
        oldSong.queueStatus = "history";
    }

    const newCurrentSong = queue.find(qs => qs.queueStatus === "waiting")
    if (newCurrentSong) {
        newCurrentSong.queueStatus = "current";
        newCurrentSong.startTime = new Date();
    }

    // trigger a queue change, because in fact we only change the status of a song from "waiting" to "current"
    queueSubject.next([...queue]);
    updateQueueTime()
}

export const removeFromQueue = (qId: string) => {

    const removedQs = queueSubject.value.find(qs => qs.qId === qId);
    queueSubject.next(queueSubject.value.filter(qs => qs != removedQs))

    // Songs are not bypassed anymore by this song
    queueSubject.value
        .forEach((qs) => removeFromArray(qs.bypassedBy, removedQs));

    updateQueueTime();
}


export const updateQueueTime = () => {

    const inQueue = queueSubject.value.filter(qs => qs.queueStatus === "waiting");

    const currentRemainingTime = getCurrentSong()?.remainingTime || 0;

    // console.log("-------------------------")
    // console.log("currentRemainingTime", currentRemainingTime)
    inQueue.reduce((accSeconds, qs) => {
        qs.plannedStartTime = (add(Date.now(), { seconds: accSeconds }));
        // console.log(qs.title, format(qs.plannedStartTime, "HH:mm:ss"))
        return accSeconds + qs.totalDuration;
    }, currentRemainingTime)

}

export const getQueueDTO = (): IQueuedSongDTO[] => {
    return queueSubject.value
        .filter(qs => qs.queueStatus === "waiting")
        .map(qs => qs.toDTO());
}