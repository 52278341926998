import beep from "../assets/3beep.mp3";
import warn from "../assets/warn.mp3";
import { downloadAudioAsync, type IAudioData } from "../utils/download";

export interface IAppAudioAssets {
    warn: IAudioData,
    countdown: IAudioData
}

export let audioAssets: IAppAudioAssets;

export const initAudioAssets = async () => {

    audioAssets = {
        warn: await downloadAudioAsync(warn),
        countdown: await downloadAudioAsync(beep)
    }

}


