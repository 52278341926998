<script lang="ts">
  import { Subscription } from "rxjs";
  import { onDestroy } from "svelte";
  import { dndzone } from "svelte-dnd-action";
  import { flip } from "svelte/animate";
  import SongBox from "../components/song-box.svelte";
  import { i18n, i18n$ } from "../i18n/i18n";
  import type { QueuedSong } from "../models/QueuedSong";
  import { queueSubject, waitingQueue$ } from "../stores/queue-store";
  import { showToast } from "../stores/toaster-store";
  import Modal from "./modal.svelte";

  type DndItem = { id: string; moveable: boolean; qs: QueuedSong; position: number };

  let modal: Modal;
  const flipDurationMs = 300;
  let moveableSong: QueuedSong;
  let subscription: Subscription;
  let items: DndItem[];

  export const openDialogAsync = async (songToMove: QueuedSong): Promise<number> => {
    items = undefined;
    moveableSong = songToMove;

    subscription = waitingQueue$.subscribe((queue) => {
      // Moveable song has been removed from the list... so just close the dialog
      if (!queue.includes(moveableSong)) {
        showToast(i18n.toasts.repositionSong.title, i18n.toasts.repositionSong.message_removedFromQueue, "warn");
        modal.closeDialog(undefined);
        return;
      }

      // First time init items
      items = queue.map((qs, index) => ({
        id: index.toString(),
        moveable: qs === moveableSong,
        qs,
        position: index + 1,
      }));

      setTimeout(() => {
        scrollToMoveableSong();
      }, 200);
    });

    const res = await modal.openDialogAsync();
    subscription?.unsubscribe();

    if (!res) {
      return undefined;
    }

    // new index relative to the waiging queue
    const newIndex = items.indexOf(items.find((item) => item.moveable));

    // translate it to the global queue
    const globalNewIndex = queueSubject.value.findIndex((qs) => qs.queueStatus === "waiting") + newIndex;

    return globalNewIndex;
  };

  const scrollToMoveableSong = () => {
    document.getElementById(`qId_${moveableSong.qId}`)?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  onDestroy(() => {
    subscription?.unsubscribe();
  });

  const onConsider = (e: any) => {
    items = e.detail.items;
    items.forEach((item, index) => {
      item.position = index + 1;
    });

    const draggingItemIndex = items.find((item) => item.moveable).position;

    // Update the dragging element (its not in the list container its created separately by svelte-dnd-action)
    const draggingElement = document.querySelector(`#dnd-action-dragged-el .dnd-position`);
    if (draggingElement) {
      draggingElement.innerHTML = draggingItemIndex.toString();
    }
  };

  const onFinalize = (e: any) => {
    items = e.detail.items;
    items.forEach((item, index) => {
      item.position = index + 1;
    });
    setTimeout(() => {
      scrollToMoveableSong();
    }, 200);
  };

  const donothing = (e) => {
    // Je sais pas pourquoi mais ca améliore le scroll sur IOS :/
    // Sinon ca scroll la page plus que déplacer l'item
  };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<Modal
  bind:this={modal}
  config={{
    title: i18n.dialogs.reorderSong.title,
    buttons: ["ok", "cancel", "x"],
    acceptCustomText: i18n.common.confirm,
  }}
>
  <div data-component="ReorderSongDialog">
    <div class="header">{$i18n$.dialogs.reorderSong.header}</div>

    <div class="list-container" use:dndzone={{ items: items, flipDurationMs }} on:consider={onConsider} on:finalize={onFinalize}>
      {#each items as item (item.id)}
        <div
          id={`qId_${item.qs.qId}`}
          class={`dnd-item ${item.moveable ? "item-active" : "item-inactive"}`}
          animate:flip={{ duration: flipDurationMs }}
          on:mousedown={(e) => donothing(e)}
          on:touchmove={(e) => donothing(e)}
        >
          <div class="dnd-position">{item.position}</div>
          <div class="song-box-container">
            <SongBox queuedSong={item.qs} />
          </div>
        </div>
      {/each}
    </div>
  </div>
</Modal>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="ReorderSongDialog"] {
    max-width: 90vw;
    min-width: 30rem;
    max-height: 70dvh;
    overflow: auto;

    .header {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
      color: $bold;
    }

    .list-container {
      display: flex;
      flex-direction: column;
      padding-right: 0.8rem;
      outline: none !important;

      .dnd-item {
        display: flex;
        align-items: center;
        padding: 0.2rem 0;
        -webkit-overflow-scrolling: touch;

        &.item-active {
          cursor: move !important;
          padding: 0.5rem 0;
        }

        &.item-inactive {
          cursor: not-allowed !important;
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
  }

  // Must set it globally else it wont take in consideration the dragging element that is outside the component
  :global(.dnd-item) {
    &.item-active {
      .song-box-container {
        filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
      }
      .dnd-position {
        font-weight: bold;
      }
    }

    .song-box-container {
      width: 100%;
    }

    .dnd-position {
      width: 2rem;
      font-size: 1.5rem !important;
      margin-right: 0.5rem;
    }
  }
</style>
