<script lang="ts">
    import { Subject, Subscription, debounceTime } from "rxjs";
    import { onDestroy, onMount } from "svelte";

    interface SliderSnap {
        value: number;
        snapThreshold?: number;
        showTick?: boolean;
        label?: string;
    }

    export let min = 0;
    export let max = 100;
    export let value = 0;
    export let step = 1;
    export let empty = false;
    export let disabled = false;
    export let showTopValue: "never" | "dragging" | "always" = "always";
    export let snaps: SliderSnap[] = [];

    let slider: HTMLInputElement;

    let progressRatio: number;

    let thumbDragging = false;
    const thumbDroppedSubject = new Subject<void>();

    let subscriptions: Subscription[] = [];
    onMount(() => {
        subscriptions = [thumbDroppedSubject.pipe(debounceTime(100)).subscribe(() => thumbDropped())];
    });

    onDestroy(() => {
        subscriptions.forEach((s) => s.unsubscribe());
    });

    $: {
        progressRatio = value / max;
        slider?.style.setProperty("--ratio", progressRatio.toString());
    }

    thumbDroppedSubject.pipe(debounceTime(100)).subscribe(() => thumbDropped());

    const thumbDrag = () => {
        const snap = findSnapMatch(value);
        if (snap) {
            value = snap.value;
        }
    };

    const thumbDropped = async () => {
        thumbDragging = false;
    };

    const findClosestValue = (arr, inputValue) => {
        return arr.reduce((prev, curr) => {
            return Math.abs(curr - inputValue) < Math.abs(prev - inputValue) ? curr : prev;
        });
    };

    const findSnapMatch = (value) => {
        if (!snaps?.length) {
            return undefined;
        }
        const closestSnapValue = findClosestValue(
            snaps.filter((sn) => sn.value).map((sn) => sn.value),
            value
        );
        const closestSnap = snaps.find((sn) => sn.value === closestSnapValue);
        if (Math.abs(value - closestSnap.value) < closestSnap.snapThreshold) {
            return closestSnap;
        }
    };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="slider">
    {#if showTopValue !== "never"}
        <div class="thumb-drag-flyout-container">
            <div
                class="thumb-drag-flyout"
                style="position:relative; left:{(value * 100) / max}%"
                class:show={showTopValue === "always" || (showTopValue === "dragging" && thumbDragging)}
            >
                <slot name="topValue" />
            </div>
        </div>
    {/if}

    <input
        type="range"
        bind:this={slider}
        bind:value
        {min}
        {max}
        {step}
        on:input={() => thumbDrag()}
        on:change={() => thumbDroppedSubject.next()}
        on:mousedown={() => (thumbDragging = true)}
        on:mouseup={() => thumbDroppedSubject.next()}
        on:pointerdown={() => (thumbDragging = true)}
        on:pointerup={() => thumbDroppedSubject.next()}
        on:touchstart={() => (thumbDragging = true)}
        on:touchend={() => thumbDroppedSubject.next()}
        class:empty
        {disabled}
    />

    <div class="ticks-container">
        {#each snaps.filter((sn) => sn.showTick) as sn}
            <div class="tick" style="position:relative; left:{(sn.value * 100) / max}%" />
        {/each}
    </div>
    <div class="ticks-container">
        {#each snaps.filter((sn) => sn.label) as sn}
            <div class="tick-label" style="position:relative; left:{(sn.value * 100) / max}%">{sn.label}</div>
        {/each}
    </div>
</div>

<!--######################################################################################-->
<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="slider"] {
        $text-color: $black;
        $range-color-left: $primary;
        $range-color-right: $primary-light;

        $range-height: 1.25rem;
        $range-track-radius: 0.3rem;

        $range-thumb-color: #ffffff;
        $range-thumb-height: 1.25rem;
        $range-thumb-width: 2.5rem;
        $range-thumb-border-radius: 0.3rem;

        $thumb-moving-text-width: 6.5rem;

        $tick-height: 0.3rem;
        $tick-width: 1px;
        $snap-label-font-size: 0.8rem;

        .thumb-drag-flyout-container {
            margin-left: calc($range-thumb-width / 2);
            margin-right: calc($range-thumb-width / 2);
            .thumb-drag-flyout {
                width: min-content;
                margin-bottom: 0.5rem;
                transform: translateX(-50%);
                color: $text-color;

                opacity: 0;
                transition: opacity 0.3s ease-in-out;
                &.show {
                    opacity: 1;
                }
            }
        }

        .ticks-container {
            @include overlay-container;

            margin-left: calc($range-thumb-width / 2);
            margin-right: calc($range-thumb-width / 2);
            .tick {
                background-color: $text-color;
                transform: translateX(-50%);
                height: $tick-height;
                width: $tick-width;
                margin-bottom: 0.2rem;
            }

            .tick-label {
                color: $text-color;
                justify-self: flex-start;
                transform: translateX(-50%);
                font-size: $snap-label-font-size;
            }
        }

        input[type="range"] {
            width: 100%;
            margin: 0;
            height: $range-height;
            border-radius: $range-track-radius;
            appearance: none;
            -webkit-appearance: none;

            --ratio: 0;
            --sx: calc(0.5 * #{$range-thumb-width} + var(--ratio) * (100% - #{$range-thumb-width}));
            background: linear-gradient($range-color-left, $range-color-left) 0 / var(--sx) 100% no-repeat, $range-color-right;

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                width: $range-thumb-width;
                height: $range-thumb-height;
                border-radius: $range-thumb-border-radius;
                background: $range-thumb-color;

                box-shadow: 0 0 0.2rem black;

                transition: transform 0.2s ease-in-out;
                &:active {
                    transform: scaleY(1.2);
                    transition: transform 0.2s ease-in-out;
                }
            }

            &.invalid {
                background: lightgray;
                opacity: 0.8;
                background-size: 0.8rem 0.8rem;
                transition: all 0.2s ease-in-out;

                &::-webkit-slider-thumb {
                    width: 0;
                    height: 0;
                }
            }
        }
    }
</style>
