<script lang="ts">
  import type { IDialogConfig } from "./modal.svelte";
  import Modal from "./modal.svelte";

  let defaultDialogConfig: IDialogConfig = {
    title: "",
    buttons: [],
    autoSize: true,
    headerIcon: "fa-regular fa-circle-question",
    headerCentered: true,
  };
  let dialogConfig: IDialogConfig = { ...defaultDialogConfig };
  let modal: Modal;

  export const openDialogAsync = async (config: IDialogConfig) => {
    dialogConfig = { ...defaultDialogConfig, ...config };
    return modal.openDialogAsync();
  };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<Modal bind:this={modal} config={dialogConfig}>
  <div data-component="ConfirmDialog">
    <div class="content-container">
      {dialogConfig.contentText}
    </div>
  </div>
</Modal>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="ConfirmDialog"] {
    width: 100%;
    // width: clamp(20rem, 30rem, 90%);

    .content-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      text-align: center;
    }
  }
</style>
