import { getAudioDuration } from "./audio-context-utils";
import { fetchRetryWithTimeout } from "./fetch";

const baseFunctionsUrl = import.meta.env.VITE_AZURE_FUNCTIONS_URL;
export interface IAudioData {
    data: Blob;
    duration: number;
}


export const downloadCalloutAsync = async (text: string, abortController: AbortController = undefined): Promise<IAudioData> => {
    if (!text?.length) {
        return undefined;
    }
    const url = `${baseFunctionsUrl}/Speak`;

    const res = await fetchRetryWithTimeout(
        url,
        {
            method: 'GET',
            headers: {
                'key': (text.length * 13).toString(),
                'text': encodeURI(text)
            }
        },
        import.meta.env.VITE_AZURE_FUNCTION_SPEECH_CONNECT_TIMEOUT,
        import.meta.env.VITE_AZURE_FUNCTION_SPEECH_RETRIES,
        abortController
    );
    if (!res.ok) {
        throw new Error(`Error calling ${url}: ${res.status} ${res.statusText}`);
    }

    const blob = await res.blob();
    const duration = await getAudioDuration(blob);

    return {
        data: blob,
        duration
    }

}


export const downloadAudioAsync = async (
    url: string,
    abortController: AbortController = undefined,
    progressFunction: (downloadedRatio: number) => void = undefined,
): Promise<IAudioData> => {

    const chunks: Uint8Array[] = [];
    let receivedLength = 0;

    const res = await fetchRetryWithTimeout(url, undefined, import.meta.env.VITE_ONEDRIVE_CONNECT_TIMEOUT, 3, abortController);

    const contentType = res.headers.get("content-type");
    const reader = res.body.getReader();
    const contentSize = parseInt(res.headers.get('content-length'));

    let data: ReadableStreamReadResult<Uint8Array>;
    while (!data?.done) {
        data = await reader.read();

        if (data.value) {
            chunks.push(data.value);
            receivedLength += data.value?.length || 0;

            if (progressFunction) {
                progressFunction(receivedLength / contentSize);
            }
        }
    }

    const blob = new Blob(chunks, { type: contentType })
    const duration = await getAudioDuration(blob);

    return {
        data: blob,
        duration
    }


}

