import { BehaviorSubject, filter, map, mergeMap, startWith } from "rxjs";
import { get } from "svelte/store";
import { Song, type IFolder, type ISong } from "../models";
import { downloadCalloutAsync, type IAudioData } from "../utils/download";
import { getOneDriveDataAsync } from "../utils/onedrive";
import { zip } from "../utils/zip";
import { solohubDB } from "./database";
import { dequeueNextSong, getCurrentSong, queueSubject } from "./queue-store";
import { clientName } from "./settings-store";

export interface ILibrary {
  songs: Song[];
  folders: IFolder[];
}

export const lib = new BehaviorSubject<ILibrary>(undefined);
const maxSimultaneousDownload = 2;
export const songsDownloadQueueSubject = new BehaviorSubject<Song[]>([]);
export const songsDownloadQueue$ = songsDownloadQueueSubject.asObservable();


export const songDownloadStatus$ = lib.pipe(
  filter(lib => !!lib),
  mergeMap(lib => lib.songs),
  mergeMap(song => song.downloadStatus$.pipe(
    map(() => song)
  ))
)

export const downloadedSong$ = songDownloadStatus$.pipe(
  filter(song => song.downloadStatus === "downloaded")
)

export const downloadingSong$ = songDownloadStatus$.pipe(
  filter(song => song.downloadStatus === "downloading")
)


export const downloadedSongs$ = downloadedSong$.pipe(
  map(() => (lib.value.songs.filter(s => s.downloadStatus === "downloaded"))),
  startWith([])
)
export const downloadingSongs$ = downloadedSong$.pipe(
  map(() => (lib.value.songs.filter(s => s.downloadStatus === "downloading"))),
  startWith([])
)

songsDownloadQueueSubject.subscribe(dq => {

  let toStart = maxSimultaneousDownload - dq.filter(s => s.downloadStatus === "downloading").length
  while (toStart > 0) {
    const song = dq.find(s => s.downloadStatus === "notDownloaded")
    if (!song) {
      return
    }
    song?.startDownloadAsync();
    toStart--;
  }
});
songDownloadStatus$.subscribe(() => {
  // a song status changed, ensure songDownloadQueueSubject is up to date
  songsDownloadQueueSubject.next(
    songsDownloadQueueSubject.value
      .filter(s => s.downloadStatus === "notDownloaded" || s.downloadStatus === "downloading")
  );
})

export const addSongToDownloadQueue = (song: Song, inPriority = false) => {
  if (song.downloadStatus === "downloaded") {
    return;
  }

  // reset download status
  if (song.downloadStatus !== "downloading") {
    song.downloadStatus = "notDownloaded";
  }

  // if song already in downloadqueue, and priority is true, move it to the top
  if (songsDownloadQueueSubject.value.includes(song) && inPriority) {
    songsDownloadQueueSubject.next([song, ...songsDownloadQueueSubject.value.filter(s => s !== song)]);
  }


  if (inPriority) {
    songsDownloadQueueSubject.next([song, ...songsDownloadQueueSubject.value]);
  } else {
    songsDownloadQueueSubject.next([...songsDownloadQueueSubject.value, song]);

  }
}


// To keep track of the current song that its calloutupdate is processing
// For UI purpose only in one-drive-loading-progress.svelte
export const currentSongCalloutUpdateSubject = new BehaviorSubject<ISong>(undefined);

/**
 * Load library from database
 * For initial load only, if library is already loaded, must use upateLibraryAsync
 */
export const initializeLibraryAsync = async () => {

  if (lib.value) {
    throw new Error("Library already loaded, use updateLibraryAsync instead")
  }

  const dbSongs = await solohubDB.songs.toArray();
  const songs = dbSongs.map(s => new Song(s));
  updateSongDataInfo(songs);

  lib.next({
    songs: songs,
    folders: await solohubDB.folders.toArray()
  });

  // Force root folder to have client name
  getRootFolder().name = get(clientName);

}

/**
 * Update the actual song library with new database data
 */
export const updateLibraryAsync = async () => {

  await reloadDatabaseFromOnedriveAsync()

  const dbSongs = await solohubDB.songs.toArray();

  // Reuse the existing songs
  const songs = dbSongs.map(dbs => {
    const slib = lib.value.songs.find(slib => slib.id === dbs.id);
    if (!slib) {
      return new Song(dbs)
    } else {
      if (slib.lastModifiedDateTime !== dbs.lastModifiedDateTime) {
        // update song with new data
        Object.assign(slib, dbs);
      }
      return slib
    }
  });


  // If song in queue deleted, remove it
  const toRemoveFromQueue = queueSubject.value.filter(qs => qs.queueStatus === "waiting" && !songs.some(slib => slib.id === qs.song.id));
  if (toRemoveFromQueue.length) {
    queueSubject.next(queueSubject.value.filter(qs => !toRemoveFromQueue.includes(qs)))
  }
  // If currentsong deleted, dequeue next
  if (!songs.some(slib => slib.id === getCurrentSong()?.song.id)) {
    dequeueNextSong();
  }

  await updateSongDataInfo(songs);

  lib.next({
    folders: await solohubDB.folders.toArray(),
    songs: songs
  });
}


/**
 * Re-scan the Onedrive folders and update the database info
 * Note: It will not delete the Songs and Callout data, only the data from the songs 
 * that have been deleted.
 */
export const reloadDatabaseFromOnedriveAsync = async (clearDownloadedAudioData = false) => {

  const data = await getOneDriveDataAsync();
  await solohubDB.songs.clear()
  await solohubDB.folders.clear()
  await solohubDB.folders.bulkAdd(data.folders);
  await solohubDB.songs.bulkAdd(data.songs);

  if (clearDownloadedAudioData) {
    await solohubDB.songsAudioData.clear()
    await solohubDB.calloutsAudioData.clear()
  }

  // Delete AudioData (callout and songs) that are not in the new list
  const songIds = data.songs.map(s => s.id)
  await solohubDB.songsAudioData.where('id').noneOf(songIds).delete();
  await solohubDB.calloutsAudioData.where('id').noneOf(songIds).delete();

}


/**
 * Update the song data info (duration, callout text, etc) from the database
 * tables containing the audio data
 */
const updateSongDataInfo = async (songs: Song[]) => {
  const audioDataIds = await solohubDB.songsAudioData.toCollection().primaryKeys()
  // const calloutDataIds = await solohubDB.s.toCollection().primaryKeys()

  // Start by updating the downloaded status, fast with ids
  songs.filter(s => audioDataIds.includes(s.id))
    .forEach(s => s.downloadStatus = "downloaded");

  // For each downlaoded songs, update the data info
  for await (const s of songs.filter(s => s.downloadStatus === "downloaded")) {

    const songData = await solohubDB.songsAudioData.get(s.id);
    const calloutData = await solohubDB.calloutsAudioData.get(s.id);

    s.songDuration = songData.duration;
    s.calloutDuration = calloutData?.duration || 0

    // If custom callout, just keep the custom one
    if (calloutData.isCustom) {
      s.calloutText = calloutData?.calloutText;
    } else {
      // here the callout (extracted from filename) is different that what we have in the local database
      // So we must update the database with the new callout text and data
      if (calloutData?.calloutText !== s.calloutText) {
        await updateCalloutAsync(s, s.calloutText, false);
      }
    }

  }
}


/**
 * Update the callout from new calloutText
 * @param song Song to update the callout
 * @param calloutText new calloutText
 * @param custom if its a customized callout from the end user
 * @param data optional: if the callout has already been downloaded, we can pass the data to avoid downloading it again
 */
export const updateCalloutAsync = async (song: Song, calloutText: string, custom: boolean, data: IAudioData = undefined) => {
  // We are using a customCalloutText to keep the original calloutText so we can check if the filename has been modified
  // so we can override the custom to have the file version that has been modified

  // Callout is cleared, so remove it from db
  if (!calloutText.length) {
    await solohubDB.calloutsAudioData.delete(song.id);
    song.calloutText = "";
    song.calloutDuration = 0;
    return;
  }

  // Download new calloutAudio
  currentSongCalloutUpdateSubject.next(song);
  const calloutAudio = data ?? await downloadCalloutAsync(calloutText);

  // Update database
  await solohubDB.calloutsAudioData.update(song.id,
    {
      calloutText: calloutText,
      isCustom: custom,
      calloutData: calloutAudio.data,
      calloutDuration: calloutAudio.duration
    });

  // udpdate library song
  song.calloutText = calloutText;
  song.calloutDuration = calloutAudio.duration;

  currentSongCalloutUpdateSubject.next(undefined);
}


export const getAllSongs = () => {
  return lib.value?.songs || [];
}
export const getAllFolders = () => {
  return lib.value?.folders || [];
}
export const getSong = (songId: string) => {
  return lib?.value?.songs.find(s => s.id === songId);
}
export const getFolder = (folderId: string) => {
  return lib.value?.folders.find(f => f.id === folderId);
}
export const getChildFolders = (folderId: string) => {
  return lib.value?.folders.filter(f => f.parentFolderId === folderId) || [];
}
export const getFolderSongs = (folderId: string) => {
  return lib.value.songs?.filter(s => s.parentFolderId === folderId) || [];
}
export const getRootFolder = () => {
  return lib.value.folders.find(f => !f.parentFolderId);
}
export const getFirstLevelFolders = () => {
  return lib.value?.folders.filter(f => f.parentFolderId === getRootFolder()?.id) || [];
}
export const getFirstLevelSongs = () => {
  return lib.value?.songs.filter(s => s.parentFolderId === getRootFolder()?.id) || [];
}



export const getLibraryDTOCompressed = () => {

  const data = {
    folders: getAllFolders().map(f => ({ id: f.id, name: f.name, parentId: f.parentFolderId })),
    songs: getAllSongs().map(f => ({ id: f.id, name: f.title, parentId: f.parentFolderId, duration: f.duration }))
  }
  const json = JSON.stringify(data);
  return zip(json);
}