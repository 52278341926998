import { BehaviorSubject } from "rxjs";
import { solohubDB } from "../stores/database";
import { errorDownloadingSong } from "../stores/queue-store";
import { downloadAudioAsync, downloadCalloutAsync } from "../utils/download";

export type DownloadStatus = "notDownloaded" | "downloading" | "downloaded" | "error" | "cancelled";

export interface ISong {
    id: string;
    parentFolderId: string;
    filename: string;
    title?: string;
    subtitle?: string;
    calloutText?: string;
    calloutTextCustom?: string;
    webUrl: string;
    downloadUrl: string;
    mimeType: string;
    size: number;
    durationFromMeta: number;
    lastModifiedDateTime: string;
}


export class Song implements ISong {

    id: string;
    parentFolderId: string;
    filename: string;
    title?: string;
    subtitle?: string;
    calloutText?: string;
    calloutTextCustom?: string;
    webUrl: string;
    downloadUrl: string;
    mimeType: string;
    size: number;
    durationFromMeta: number;
    lastModifiedDateTime: string;

    // Will be populated by database data
    songDuration: number;
    calloutDuration: number;

    constructor(init: Partial<Song>) {
        Object.assign(this, init);
    }

    public get duration(): number {
        return this.songDuration || (this.durationFromMeta / 1000);
    }

    public get durationOverLimit(): boolean {
        return this.duration > import.meta.env.VITE_AUDIO_MAX_DURATION_MINUTES * 60;
    }


    private downloadStatusSubject = new BehaviorSubject<DownloadStatus>("notDownloaded");
    downloadStatus$ = this.downloadStatusSubject.asObservable();
    public get downloadStatus(): DownloadStatus {
        return this.downloadStatusSubject.value;
    }
    public set downloadStatus(value: DownloadStatus) {
        this.downloadStatusSubject.next(value)
    }

    private downloadProgressSubject = new BehaviorSubject<number>(0);
    downloadProgress$ = this.downloadProgressSubject.asObservable();


    private songDownloadAbortController: AbortController;
    public cancelDownload() {
        this.songDownloadAbortController?.abort(`cancel`);
    }

    public async startDownloadAsync() {
        this.calloutDownloadProgress = 0;
        this.songDownloadProgress = 0;
        this.downloadProgressSubject.next(0);
        this.songDownloadAbortController = new AbortController();

        // already downloading or downloaded
        if (this.downloadStatus === "downloaded" || this.downloadStatus === "downloading") {
            return;
        }

        try {
            this.downloadStatus = "downloading";

            this.calloutDownloadProgress = 0
            this.songDownloadProgress = 0
            const res = await Promise.all([
                downloadCalloutAsync(
                    this.calloutText,
                    this.songDownloadAbortController).finally(() => { this.calloutDownloadProgress = 1; this.updateProgress() }
                    ),
                downloadAudioAsync(
                    this.downloadUrl,
                    this.songDownloadAbortController,
                    (downloadProgress) => { this.songDownloadProgress = downloadProgress; this.updateProgress() }
                )
            ]
            )

            const calloutData = res[0]
            const songData = res[1]

            solohubDB.songsAudioData.add({ id: this.id, blob: songData.data, duration: songData.duration });
            solohubDB.calloutsAudioData.add({ id: this.id, blob: calloutData.data, duration: calloutData.duration, calloutText: this.calloutText, isCustom: false });

            this.songDuration = songData.duration;
            this.calloutDuration = calloutData.duration;

            this.downloadStatus = "downloaded";

        } catch (ex) {

            if (this.songDownloadAbortController?.signal?.reason === "cancel") {
                this.downloadStatus = "cancelled";
            } else {
                this.downloadStatus = "error";
                errorDownloadingSong(this);
            }


        } finally {
            this.songDownloadAbortController = undefined;
            this.calloutDownloadProgress = 0
            this.songDownloadProgress = 0
            this.updateProgress();
        }
    }

    private calloutDownloadProgress = 0
    private songDownloadProgress = 0
    private updateProgress() {
        this.downloadProgressSubject.next((this.calloutDownloadProgress * 0.1) + (this.songDownloadProgress * 0.9))
    }


    public async deleteLocalDataAsync() {
        await solohubDB.songsAudioData.delete(this.id);
        await solohubDB.calloutsAudioData.delete(this.id);
        this.downloadStatus = "notDownloaded";
    }

    public async getSongArrayBuffer(): Promise<ArrayBuffer> {
        const audioData = await solohubDB.songsAudioData.get(this.id);
        return await audioData?.blob?.arrayBuffer();
    }
    public async getCalloutArrayBuffer(): Promise<ArrayBuffer> {
        const audioData = await solohubDB.calloutsAudioData.get(this.id);
        return await audioData?.blob?.arrayBuffer();
    }

}
