

export const shuffleArray = (array: any[]) => {

    const newArray = [...array];

    let currentIndex = newArray.length
    let randomIndex = 0;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [newArray[currentIndex], newArray[randomIndex]] = [
            newArray[randomIndex], newArray[currentIndex]];
    }

    return newArray;
}

export const insertItemAtIndex = (array: any[], item: any, index: number) => {
    array.splice(index < 0 ? 0 : index, 0, item);
}

export const moveItemToIndex = (array: any[], item: any, newIndex: number) => {
    const oldIndex = array.indexOf(item);
    if (oldIndex !== -1) {
        array.splice(oldIndex, 1);
        array.splice(newIndex, 0, item);
    }
}

export const removeFromArray = (array: any[], item: any): void => {
    const index = array.indexOf(item);
    if (index !== -1) {
        array.splice(index, 1);
    }
}

export const findLast = <T>(array: T[], predicate: (t: T) => boolean): T => {
    for (let i = array.length - 1; i >= 0; --i) {
        const x = array[i];
        if (predicate(x)) {
            return x;
        }
    }
}
export const findLastIndex = <T>(array: T[], predicate: (t: T) => boolean) => {
    for (let i = array.length - 1; i >= 0; --i) {
        const x = array[i];
        if (predicate(x)) {
            return array.indexOf(x);
        }
    }
    return -1;
}




declare global {
    interface Array<T> {
        sortBy(selector: (item: T) => string, moveAtEnd?: string[]): T[];
    }
}
Array.prototype.sortBy = function <T>(selector: (item: T) => string, moveAtEnd: string[] = []): T[] {
    let sorted = this.sort((a: T, b: T) => {
        const valueA = selector(a);
        const valueB = selector(b);

        return valueA.localeCompare(valueB, undefined, { sensitivity: "base" });
    });

    moveAtEnd.forEach((startbyString) => {
        const found = sorted.filter((item) => selector(item).startsWith(startbyString));
        if (found) {
            sorted = sorted.filter((item) => !selector(item).startsWith(startbyString));
            sorted.push(...found);
        }
    });

    return sorted;
};