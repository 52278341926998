import { BehaviorSubject, Subject, Subscription, distinctUntilChanged, filter, firstValueFrom, map, merge, startWith, throttleTime } from "rxjs";
import { generate } from "short-uuid";
import { keepAlivePlayingAudioSubject } from "../stores/app-store";
import { sum } from "./general";
import { sleepAsync } from "./sleep";
import { IntervalWorker } from "./web-worker";

export type AudioStatus = "empty" | "loading" | "playing" | "paused";

export interface IAudioSource {
    source: string | ArrayBuffer;
    delay?: number;
    volume?: number;
}


interface ISprite {
    source: string | ArrayBuffer;
    delay: number;
    volume?: number;

    node?: AudioBufferSourceNode;
    nodeGainNode?: GainNode;
    audioBuffer?: AudioBuffer;

    nodeDuration?: number;
    nodeDurationWithDelay?: number;

    spritePlannedStart?: number;
    spritePlannedEnd?: number;
    audioPlannedStart?: number; // Audio starttime after delay
}

export class AudioManager {
    private audioContext: AudioContext;
    private volumeGainNode: GainNode;
    sources: ISprite[] = [];
    private intervalWorker: IntervalWorker;
    private mainIntervalObs = new Subject<void>();

    // Every time we seek, we must create a new sourceNode and start it at the right time matching the audioContext.currentTime
    // See graphic in documentation/audiocontext_timing.drawio
    // Formule : CurrentTime = AudioContext.currentTime - shiftTime + seekStartTime
    // shiftTime : audioContext.currentTime when last seek done
    // seekStartTime : last seek time
    private shiftTime = 0;
    private seekTime = 0;

    private endedSubject = new Subject<void>();
    public ended$ = this.endedSubject.asObservable();
    private wakingUp = false;

    // Pour le hack de IOS
    public ignoreAudioContextInterrupted: boolean = true;

    #id: string = generate();

    private subscriptions: Subscription[] = [];

    constructor(id: string = undefined) {
        this.#id = id;
        this.intervalWorker = new IntervalWorker(`AM_${this.#id}`);
        this.intervalWorker.start(50).subscribe(() => this.mainIntervalObs.next());

        this.audioContext = new AudioContext();
        this.volumeGainNode = this.audioContext.createGain();
        this.volumeGainNode.connect(this.audioContext.destination);
        this.audioContext.suspend();

        this.audioContext.onstatechange = async () => {

            if (this.wakingUp) {
                return;
            }

            // If audiocontext is different than the statusSubject, force audioContext to follow statusSubject.
            // It is important because doing the opposite was really unpredictable sometimes
            // It also fixes the issue with IOS, that when device is locked, audioContext.state goes in "interrupted" state
            if (this.audioContext.state != <string>this.status) {
                if (this.status === "playing") {
                    await this.resumeAudioContext();
                } else {
                    await this.suspendAudioContext();
                }
            }
        }

        this.subscriptions.push(
            this.status$.subscribe(async (status) => {
                // the status is driven by the status$ and send it to the audioContext.
                // This avoid mismatch between the 
                if (["empty", "loading", "paused"].includes(status)) {
                    await this.audioContext.suspend();
                } else {
                    await this.audioContext.resume();
                }
            })
        )

        // This poke the keepAlivePlayingAudioSubject every second to keep the app alive
        // If idle for a long time, the app will be reset
        this.mainIntervalObs.pipe(throttleTime(1000)).subscribe(() => {
            if (this.status === "playing") {
                keepAlivePlayingAudioSubject.next(Date.now());
            }
        })

    }
    destroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.audioContext.onstatechange = null;
        this.intervalWorker.stop();
        this.intervalWorker.terminate();
        this.statusSubject.next("empty");
    }

    private statusSubject = new BehaviorSubject<AudioStatus>("empty");
    public status$ = this.statusSubject.asObservable()
        .pipe(distinctUntilChanged());
    public get status(): AudioStatus {
        return this.statusSubject.value;
    }

    public audioContextTime$ = this.mainIntervalObs.pipe(
        map(() => this.audioContext?.currentTime),
        startWith(0),
        distinctUntilChanged(),
    )
    public get audioContextTime(): number {
        return this.audioContextTime;
    }

    public currentTime$ = this.mainIntervalObs.pipe(
        map(() => this.currentTime),
        startWith(0),
        distinctUntilChanged(),

    )
    public get currentTime(): number {
        return this.status === "loading" ? 0 : this.audioContext.currentTime - this.shiftTime + this.seekTime;
    }

    public get duration(): number | undefined {
        return sum(this.sources.map(src => src.nodeDurationWithDelay || 0));
    }

    _volume = 1;
    public get volume(): number {
        return this._volume;
    }
    public set volume(value: number) {
        this._volume = value;

        const gainVolume = this.calculateGainVolume(value);
        this.volumeGainNode.gain.linearRampToValueAtTime(gainVolume, this.audioContext.currentTime + 0.1)
    }

    async suspendAudioContext() {
        // We do this for IOS because if we "await audioContext.suspend()" while state is already suspended, it will never resolve
        if (this.audioContext.state === "running") {
            await this.audioContext.suspend();
        }
    }
    async resumeAudioContext() {
        if (this.audioContext.state !== "running") {
            await this.audioContext.resume();
        }
    }

    public wakeup = async () => {
        this.wakingUp = true;
        const wasRunning = this.audioContext.state === 'running';

        await this.suspendAudioContext();
        await this.resumeAudioContext();
        if (!wasRunning) {
            await this.suspendAudioContext();
        }
        this.wakingUp = false;
    }


    private async processUrlSourcesAsync(sources: IAudioSource[]) {

        // set default values
        sources.forEach(src => {
            src.delay = src.delay || 0;
            src.volume = src.volume || 1;
        })

        // Get each source that is a string url, unique (do not download twice the same url)
        const sourcesWithUrl = sources.filter(src => typeof src.source === "string");
        const uniqueSourceUrl: string[] = [...new Set(sourcesWithUrl.map(src => <string>src.source))];

        const dataMap = new Map<string, ArrayBuffer>();
        if (uniqueSourceUrl.length) {
            await Promise.all(uniqueSourceUrl.map(async url => {
                try {
                    const response = await fetch(url);
                    if (!response.ok) {
                        dataMap.set(url, undefined);
                        console.error(`Erreur de lecture de ${url}`);
                    }

                    dataMap.set(url, await response.arrayBuffer())

                } catch (error) {
                    console.error(`Erreur de lecture de ${url}:`, error);
                    dataMap.set(url, undefined);
                }
            }));
        }

        // Create a new array of sources with the audioBuffer
        const data = await Promise.all(sources.map(async (src) => {

            const buffer = (typeof src.source === "string")
                ? dataMap.get(src.source)
                : src.source;

            try {
                const audioData = await this.audioContext.decodeAudioData(buffer.slice(0));
                const nodeDuration = Math.max(0, audioData.duration);
                const nodeDurationWithDelay = Math.max(0, nodeDuration + src.delay)

                const processedSource: ISprite = {
                    source: src.source,
                    delay: src.delay,
                    volume: src.volume,
                    audioBuffer: audioData,
                    nodeDuration: nodeDuration,
                    nodeDurationWithDelay: nodeDurationWithDelay,
                }
                return processedSource;
            } catch (ex) {
                console.error("Error decoding audio data", ex);
                return undefined
            }
        }))

        // Calculate planned start and end time for each source
        data.reduce((prev, curr) => {
            curr.spritePlannedStart = prev?.spritePlannedEnd || 0;
            curr.audioPlannedStart = curr.spritePlannedStart + curr.delay;
            curr.spritePlannedEnd = curr.spritePlannedStart + curr.nodeDurationWithDelay;

            return curr;
        }, undefined)

        // remove source in error
        return data.filter(src => !!src);
    }


    async loadAsync(sources: IAudioSource[]): Promise<ISprite[]> {

        this.statusSubject.next("loading");

        await this.unload();

        this.sources = await this.processUrlSourcesAsync(sources);
        await this.seek(0);

        // ensure we reset the volume 
        const gainVolume = this.calculateGainVolume(this.volume);
        this.volumeGainNode.gain.setValueAtTime(gainVolume, this.audioContext.currentTime)

        // manually set pause status, beacause we ignore status change while loading
        await this.suspendAudioContext();

        this.statusSubject.next("paused");

        return this.sources
    }

    async unload() {
        if (!this.sources?.length) {
            return;
        }
        await this.suspendAudioContext();
        this.sources.filter(src => src.node)
            .forEach(src => {
                src.node.disconnect();
                src.node.onended = null
            });
        this.sources = []
        this.statusSubject.next("empty");
    }


    async seek(time: number) {
        if (!this.sources?.length) {
            return;
        }

        const restart = this.status === "playing";
        this.statusSubject.next("paused");

        // disconnect all existing nodes
        this.sources
            .filter(src => src.node)
            .forEach(src => {
                src.node.disconnect();
                src.node.onended = null;
                src.node = null
            });

        const seekTime = Math.min(Math.max(0, time), this.duration || 0);
        this.shiftTime = this.audioContext.currentTime;
        this.seekTime = Math.min(this.duration || 0, Math.max(0, seekTime));

        // this is important here beause I thing there is a defualt value that prevent initial start to have a linearrampup
        this.volumeGainNode.gain.cancelScheduledValues(this.audioContext.currentTime);

        // console.log("--------------------")
        // console.log("SEEK", seekTime, "ctx.currentTime", this.audioContext.currentTime)
        // console.log("")


        // Get sum of time skipped for all nodes that are before the the seekTime
        const skippedSourcesLength = sum(this.sources.filter(src => src.spritePlannedEnd <= seekTime).map(src => src.nodeDurationWithDelay));

        let prevNodeEndTime = 0;
        this.sources
            .filter(src => src.audioBuffer && src.nodeDuration > 0)
            .filter(src => src.spritePlannedEnd > seekTime)
            .forEach((src, index) => {

                src.node = this.audioContext.createBufferSource();
                src.node.buffer = src.audioBuffer;

                src.nodeGainNode = this.audioContext.createGain();
                src.nodeGainNode.gain.value = src.volume || 1;
                src.node.connect(src.nodeGainNode).connect(this.volumeGainNode);

                let when: number = undefined;
                let offset: number = undefined;

                if (index === 0) {
                    // First playing node is tricky to calculate since seek time arrives in the middle of the first node
                    const firstSongSeek = this.seekTime - skippedSourcesLength;

                    let actualDelay = (src.delay || 0) - firstSongSeek
                    const actualDuration = src.nodeDurationWithDelay - firstSongSeek;
                    let cropStart = 0;
                    if (actualDelay < 0) {
                        cropStart -= actualDelay;
                        actualDelay = 0;
                    }

                    when = this.audioContext.currentTime + actualDelay;
                    offset = cropStart;
                    prevNodeEndTime = when + actualDuration - actualDelay;
                } else {
                    // Remaining nodes are easier to calculate
                    when = prevNodeEndTime + (src.delay || 0);
                    prevNodeEndTime = when + src.nodeDuration;
                }

                // console.log(`NODE_${index}`, { when, offset })
                src.node.start(when, offset);


            });

        // Bind songEnded event on last node ended
        this.sources.filter(src => src.node).slice(-1)[0].node.onended = async () => {
            this.statusSubject.next("paused");
            this.endedSubject.next();
        };

        if (restart) {
            //this.statusSubject.next("playing");
            this.play();
        }
    }

    async play(rampUpTime: number = 0.2) {
        if (!this.duration) {
            return;
        }

        // song is at the end, seek to the beginning
        if (this.currentTime >= this.duration) {
            await this.seek(0);
        }

        const gainVolume = this.calculateGainVolume(this.volume);
        if (rampUpTime > 0) {
            this.volumeGainNode.gain.setValueAtTime(0.000001, this.audioContext.currentTime);
            this.volumeGainNode.gain.linearRampToValueAtTime(gainVolume, this.audioContext.currentTime + rampUpTime)
        } else {
            this.volumeGainNode.gain.setValueAtTime(gainVolume, this.audioContext.currentTime);
        }

        this.statusSubject.next("playing");

    }

    async pause(fadeOutTime: number = 0.1) {

        if (fadeOutTime > 0) {
            this.volumeGainNode.gain.setValueAtTime(this.calculateGainVolume(this.volume), this.audioContext.currentTime);
            this.volumeGainNode.gain.linearRampToValueAtTime(0.0001, this.audioContext.currentTime + fadeOutTime)

            // Let time to reach 0 volume fadeoutdelay * 1000 to get ms then add 100ms to be sure
            await sleepAsync(fadeOutTime * 1000 + 100);
        }

        this.statusSubject.next("paused");
    }

    async togglePlay(rampUpTime: number = 0.2, fadeOutTime: number = 0.1) {
        if (this.status === "playing") {
            await this.pause(fadeOutTime);
        } else {
            await this.play(rampUpTime);
        }
    }


    private calculateGainVolume(value: number) {
        // If we only use volume = volume i find that the volume does not progress fast enough. 
        // multiplying it like that sounds better:
        // ex: volume 1     = 1*1       = 1
        // ex: volume 0.5   = 0.5*0.5   = 0.25
        // ex: volume 1.1   = 1.1*1.1   = 1.21
        // ex: volume 1.5   = 1.5*1.5   = 2.25
        return Math.max(value * value, 0.00001);
    }

    public async playAndWaitUntilEndAsync(orUntilPaused: boolean = false) {
        await this.play();

        const obs = orUntilPaused
            ? merge(this.endedSubject, this.status$.pipe(filter(status => status === "paused")))
            : this.endedSubject;

        await firstValueFrom(obs);
    }

}


