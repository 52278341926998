<script lang="ts">
  import { debounceTime, filter, firstValueFrom, skip } from "rxjs";
  import { generate } from "short-uuid";
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import AudioPlayer from "../components/audio-player.svelte";
  import FolderBox from "../components/folder-box.svelte";
  import SelectFolderIcon from "../components/icons/select-folder-icon.svelte";
  import ToggleButton from "../components/toggle-button.svelte";
  import SelectFolderDialog from "../dialogs/select-folder-dialog.svelte";
  import { i18n, i18n$, i18nParams as i18param } from "../i18n/i18n";
  import { type IFolder, type Song } from "../models";
  import { showAppConfirmDialogAsync, showAppMessageAsync } from "../stores/app-store";
  import { addSongToDownloadQueue, getAllSongs, getFolder, getFolderSongs } from "../stores/library-store";
  import { currentSong$, getCurrentSong } from "../stores/queue-store";
  import {
    ambientPlayerStatusSubject,
    clearingSession,
    mainPlayerIsPlaying$,
    mainPlayerStatusSubject,
    sessionInfoSubject,
  } from "../stores/session-store";
  import { showToast } from "../stores/toaster-store";
  import { appLocalStorage } from "../utils/app-local-storage";
  import { shuffleArray } from "../utils/array";
  import type { AudioStatus } from "../utils/audio-manager";
  import { clearSubscriptions, safeSubscribe } from "../utils/rx-utils";

  export let ambientActivated = true;
  const cId = generate();

  const dispatch = createEventDispatcher();

  let shuffled = false;

  let currentFolder: IFolder;
  let audioPlayer: AudioPlayer;
  let selectFolderDialog: SelectFolderDialog;
  let currentIndex = 0;
  let songArray: Song[] = [];

  let audioMediaLoaded: boolean;
  export let volume: number = 1;

  onMount(() => {
    const folderid = appLocalStorage.ambientFolderId;
    shuffled = appLocalStorage.ambientShuffle;

    const folder = getFolder(folderid);
    if (folder) {
      setFolder(folder, true);
    }

    safeSubscribe(
      cId,

      // auto start the ambient player when queue is empty
      currentSong$
        .pipe(
          skip(1),
          filter((s) => !s),
          debounceTime(1000),
        )
        .subscribe(() => {
          // revalidate current song still empty after debounce
          if (getCurrentSong()) {
            return;
          }

          if (clearingSession || !ambientActivated || !audioMediaLoaded) {
            return;
          }
          audioPlayer.play(3);
        }),

      currentSong$.subscribe((song) => {
        if (song) {
          audioPlayer.pause();
        }
      }),

      // Stop ambient playback when main player is playing
      mainPlayerIsPlaying$
        .pipe((isPlaying) => isPlaying)
        .subscribe(() => {
          audioPlayer?.pause();
        }),

      // Pause player when new session
      sessionInfoSubject.subscribe(() => {
        audioPlayer?.pause();
      }),
    );
  });

  onDestroy(() => {
    clearSubscriptions(cId);
  });

  const audioPlayerStatusChanged = (status: AudioStatus) => {
    ambientPlayerStatusSubject.next(status);
    dispatch("statusChanged", status);
    audioMediaLoaded = status !== "empty";
  };

  const setFolder = (folder: IFolder, downloadedOnly: boolean) => {
    currentFolder = folder;

    if (currentFolder) {
      songArray = getFolderSongs(currentFolder.id);
      if (downloadedOnly) {
        songArray = songArray.filter((s) => s.downloadStatus === "downloaded");
      }

      if (shuffled) {
        songArray = shuffleArray(songArray);
      }

      appLocalStorage.ambientFolderId = currentFolder.id;
    }

    loadSongAtIndex(0);
  };

  const changeFolder = async () => {
    const folder = await selectFolderDialog.openDialogAsync();
    if (!folder) {
      return;
    }

    const songs = getFolderSongs(folder.id);
    const availableSongs = songs.filter((s) => s.downloadStatus === "downloaded");

    if (!songs.length) {
      //Aucune musique trouvée dans le répertoire choisi.
      showAppMessageAsync(i18n.confirmDialogs.ambientChangeFolderEmptyFolder.title, i18n.confirmDialogs.ambientChangeFolderEmptyFolder.message);
      return;
    }

    if (songs.length > availableSongs.length) {
      if (availableSongs.length > 0) {
        const res = await showAppConfirmDialogAsync({
          title: i18n.confirmDialogs.ambientConfirmDownloadMissing.title,
          contentText: i18param(i18n.confirmDialogs.ambientConfirmDownloadMissing.message, { avail: availableSongs.length, total: songs.length }),
          acceptCustomText: i18n.common.download,
          buttons: ["x", "cancel", "ok"],
        });
        if (!res) {
          if (navigator.onLine === false) {
            showToast(i18n.toasts.downloadNotAvailable.title, i18n.toasts.downloadNotAvailable.message, "danger");
            return;
          }

          setFolder(folder, false);
          downloadMissingSongs();
        } else {
          setFolder(folder, true);
        }
      } else {
        const res = await showAppConfirmDialogAsync({
          title: i18n.confirmDialogs.ambientConfirmDownloadAll.title,
          contentText: i18n.confirmDialogs.ambientConfirmDownloadAll.message,
          buttons: ["ok", "x", "no"],
          acceptCustomText: i18n.common.download,
        });
        if (!res) {
          return;
        }
        if (navigator.onLine === false) {
          showToast(i18n.toasts.downloadNotAvailable.title, i18n.toasts.downloadNotAvailable.message, "danger");
          return;
        }

        setFolder(folder, false);
        downloadMissingSongs();
      }
    } else {
      setFolder(folder, true);
    }
  };

  const downloadMissingSongs = () => {
    getFolderSongs(currentFolder.id)
      .filter((s) => s.downloadStatus !== "downloaded")
      .forEach((s, index) => addSongToDownloadQueue(s, index === 0));
  };

  let ambientSong: Song;

  const loadSongAtIndex = async (index: number, autoPlay: boolean = false) => {
    currentIndex = index;

    await audioPlayer?.pause();

    ambientSong = songArray?.[currentIndex];
    if (!ambientSong) {
      return;
    }

    // Need to work with a localSong to check below if we load the song aftert download
    const localSong = ambientSong;

    if (ambientSong.downloadStatus !== "downloaded") {
      addSongToDownloadQueue(ambientSong, true);
      await firstValueFrom(ambientSong.downloadStatus$.pipe(filter((s) => s === "downloaded" || s === "error" || s === "cancelled")));
      if (ambientSong.downloadStatus == "error" || ambientSong.downloadStatus == "cancelled") {
        return;
      }
    }

    // Need to check this because if user click next song while downloading and current downloading song is now different we dont want to play it
    if (ambientSong === localSong) {
      const buffer = await ambientSong.getSongArrayBuffer();
      await audioPlayer.loadAsync(buffer);

      if (autoPlay) {
        await audioPlayer.play(3);
      }
    }
  };

  const loadPrevSong = async () => {
    currentIndex--;
    if (currentIndex < 0) {
      currentIndex = songArray.length - 1;
    }

    loadSongAtIndex(currentIndex);
  };

  const loadNextSong = async (autoPlay: boolean = false) => {
    currentIndex++;
    if (currentIndex >= songArray.length) {
      currentIndex = 0;
    }
    loadSongAtIndex(currentIndex, autoPlay);
  };

  const toggleShuffle = () => {
    appLocalStorage.ambientShuffle = shuffled;

    // get songs back the original order from folder songs (but only take the ones already in songArray)
    const orderedSongArray = getAllSongs().filter((s) => s.parentFolderId === currentFolder.id && songArray.includes(s));

    songArray = shuffled ? shuffleArray([...orderedSongArray]) : [...orderedSongArray];

    loadSongAtIndex(0);
  };
</script>

<!--#########################################################################-->
<!-- COMPONENT ambient -->
<div data-component="Ambient">
  {#if !ambientActivated}
    <div class="panel-message gray-message">
      <div>{$i18n$.session.ambient.ambientDeactivated}</div>
      <div>
        {$i18n$.session.ambient.activateControl} <i class="fa-solid fa-toggle-on" />
        {$i18n$.session.ambient.above}
      </div>
    </div>
  {:else if !currentFolder}
    <div class="panel-message gray-message">
      <div>{$i18n$.session.ambient.noMusicSelected}</div>
      <div>
        <button class="choose-folder-button large" on:click={() => changeFolder()}>
          <SelectFolderIcon />
        </button>
      </div>
    </div>
  {/if}

  <div class="content {ambientActivated && currentFolder ? 'show' : 'hide'}">
    <div class="folder-select-line">
      <div class="selected-folder">
        <FolderBox folder={currentFolder} />
      </div>
      <button class="icon-md choose-folder-button" on:click={() => changeFolder()}>
        <SelectFolderIcon />
      </button>

      <ToggleButton bind:value={shuffled} disabled={!currentFolder} on:toggled={() => toggleShuffle()} width="5rem">
        <i class="fa-solid fa-shuffle" />
      </ToggleButton>
    </div>

    {#if !songArray?.length && currentFolder}
      <div class="no-downloaded-in-folder gray-message">{$i18n$.session.ambient.noDownloadInFolder}</div>
    {/if}

    <div class="player-container {ambientActivated && currentFolder && songArray.length > 0 ? 'show' : 'hide'}">
      <div class="current-song-title">{ambientSong?.title}</div>

      <AudioPlayer
        audioPlayerInstanceName="Ambient"
        bind:this={audioPlayer}
        {volume}
        on:ended={() => loadNextSong(true)}
        on:statusChanged={(e) => audioPlayerStatusChanged(e.detail)}
      />
    </div>

    <div class="controls {ambientActivated && ambientSong ? 'show' : 'hide'}">
      <button class="icon-md" on:click={() => loadPrevSong()}>
        <i class="fa-solid fa-backward-step" />
      </button>

      {#if $ambientPlayerStatusSubject === "playing"}
        <button class="icon-lg" on:click={() => audioPlayer.pause(0.2)}>
          <i class="fa-solid fa-pause" />
        </button>
      {/if}
      {#if $ambientPlayerStatusSubject === "paused"}
        <button class="icon-lg" on:click={() => audioPlayer.play(3)} disabled={$mainPlayerStatusSubject === "playing"}>
          <i class="fa-solid fa-play" />
        </button>
      {/if}

      <button class="icon-md" on:click={() => loadNextSong($ambientPlayerStatusSubject === "playing")}>
        <i class="fa-solid fa-forward-step" />
      </button>
    </div>
  </div>
</div>

<SelectFolderDialog bind:this={selectFolderDialog} />

<!--#########################################################################-->

<!-- STYLES -->
<style lang="scss">
  [data-component="Ambient"] {
    height: 100%;
    display: grid;
    grid-template-areas: "grid-main";

    .content {
      grid-area: grid-main;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      overflow: visible;

      .folder-select-line {
        display: flex;
        gap: 1rem;
        align-items: center;

        .selected-folder {
          flex: auto;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          .choose-folder-text {
            color: $bold;
          }
        }

        .folder-name {
          i {
            font-size: 1.5rem;
          }
        }
      }

      .player {
        audio {
          width: 100%;
        }
      }

      .controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .player-container {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;

        .current-song-title {
          @include text-truncate(1);
          // font-size: 1.5rem;
        }
      }
    }

    .panel-message {
      grid-area: grid-main;
      align-self: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      i {
        font-size: 1.5rem;
        margin: 0 0.3rem;
      }
    }

    .no-downloaded-in-folder {
      margin: 1rem;
    }

    .choose-folder-button {
      &.large {
        width: 10rem;
        height: 4rem;
        font-size: 1.5rem;
      }
    }
  }
</style>
