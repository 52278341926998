<script lang="ts">
  import { merge } from "rxjs";
  import { generate } from "short-uuid";
  import { onDestroy, onMount } from "svelte";
  import AudioLockPreventer from "../components/audio-lock-preventer.svelte";
  import { appInitializedSubject, mobileCurrentTabSubject } from "../stores/app-store";
  import { dequeueNextSong, getCurrentSong, queueSubject, updateQueueTime, waitingQueue$ } from "../stores/queue-store";
  import { autoPlaySubject, clearingSession, saveSession } from "../stores/session-store";
  import { clearSubscriptions, safeSubscribe } from "../utils/rx-utils";
  import AmbientPlayerPanel from "./ambient-player-panel.svelte";
  import MainPlayerPanel from "./main-player-panel.svelte";
  import WaitingListPanel from "./queue-panel.svelte";

  const cId = generate();

  onMount(() => {
    safeSubscribe(
      cId,
      merge(autoPlaySubject, queueSubject).subscribe(() => {
        if (appInitializedSubject.value && !clearingSession) {
          saveSession();
        }
      }),

      waitingQueue$.subscribe((q) => {
        if (q.length && !getCurrentSong()) {
          dequeueNextSong();
        }
        updateQueueTime();
      }),
    );
  });

  onDestroy(() => {
    clearSubscriptions(cId);
  });
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="SessionPage">
  <!-- PLAYER -->
  <div class="panel-container area-player" class:selected={$mobileCurrentTabSubject === "main"}>
    <MainPlayerPanel />
  </div>

  <!-- AMBIENT PANEL -->
  <div class="panel-container area-ambient" class:selected={$mobileCurrentTabSubject === "ambient"}>
    <AmbientPlayerPanel />
  </div>

  <!-- QUEUE PANEL -->
  <div class="panel-container area-playlist" class:selected={$mobileCurrentTabSubject === "queue"}>
    <WaitingListPanel />
  </div>
</div>

<AudioLockPreventer />

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="SessionPage"] {
    padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);

    height: 100%;
    display: grid;
    grid-template-areas:
      "player playlist"
      "ambient playlist";

    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 1rem;

    .bar {
      display: none;
    }

    // MOBILE VIEW
    @media screen and (max-width: $mobile-max-width), screen and (max-height: $mobile-max-height) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: "main";

      .panel-container {
        opacity: 0;
        visibility: hidden;
        transition:
          opacity 0.3s ease-in-out,
          visibility 0.3s ease-in-out;
        &.selected {
          opacity: 1;
          visibility: visible;
        }
      }

      .area-player {
        grid-area: main !important;
        align-self: flex-start;
      }
      .area-ambient {
        grid-area: main !important;
        align-self: flex-start;
      }
      .area-playlist {
        grid-area: main !important;
      }

      .bar {
        grid-area: bar;
        display: block;
      }
    }

    .area-player {
      grid-area: player;
    }
    .area-ambient {
      grid-area: ambient;
    }
    .area-playlist {
      grid-area: playlist;
    }
  }
</style>
