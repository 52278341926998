
import { BehaviorSubject } from 'rxjs';
import { generate } from 'short-uuid';

export type ToastType = "info" | "danger" | "warn" | "success";

export interface IToastInfo {
    id: string;
    title: string;
    text: string;
    duration: number;
    type: ToastType;
    datalist: string[];
    textIsHtml: boolean
}

const MAXIMUM_TOASTS = 5;

export const toasterSubject = new BehaviorSubject<IToastInfo[]>([]);
export const showToast = async (title: string, text: string, type: ToastType, datalist: string[] = undefined, duration = 5000, textIsHtml = false) => {

    const newToast: IToastInfo = {
        id: generate(),
        title,
        text,
        duration,
        type,
        datalist,
        textIsHtml
    }
    toasterSubject.next([newToast, ...(toasterSubject.value.slice(0, MAXIMUM_TOASTS - 1))])

    if (newToast.duration > 0) {
        setTimeout(() => {
            removeToast(newToast.id);
        }, newToast.duration);
    }
    return newToast.id;
}

export const removeToast = async (id: string) => {

    toasterSubject.next(toasterSubject.value.filter(t => t.id !== id))

}