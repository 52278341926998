<script lang="ts">
  import { take } from "rxjs";
  import { generate } from "short-uuid";
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { get } from "svelte/store";
  import { i18n$ } from "../i18n/i18n";
  import { appClickedSubject } from "../stores/app-store";
  import { audioAssets } from "../stores/audio-assets-store";
  import { songDelayStore } from "../stores/settings-store";
  import { AudioManager } from "../utils/audio-manager";
  import { clearSubscriptions, safeSubscribe } from "../utils/rx-utils";
  import CalloutIcon from "./icons/callout-icon.svelte";

  const cId = generate();
  export let emitting = false;

  const dispatch = createEventDispatcher();

  let audioManager: AudioManager;

  $: calloutCurrentTime$ = audioManager?.currentTime$;
  let playerDuration = 0;

  onMount(async () => {
    audioManager = new AudioManager("Callout");

    safeSubscribe(
      cId,
      appClickedSubject.pipe(take(1)).subscribe(async () => {
        audioManager.wakeup();
      }),
      audioManager.currentTime$.subscribe((time) => {
        dispatch("timeChanged", time);
      }),
    );
  });

  onDestroy(() => {
    audioManager.destroy();
    clearSubscriptions(cId);
  });

  let warnlength = 1;
  let titleLength = 1;
  let launchLength = 1;

  export const startAndWaitEndAsync = async (calloutData: ArrayBuffer) => {
    emitting = true;

    const warnBuffer = await audioAssets.warn.data.arrayBuffer();
    const launchBuffer = await audioAssets.countdown.data.arrayBuffer();

    const sprites = await audioManager.loadAsync([
      { source: warnBuffer, delay: 0.5 },
      { source: calloutData },
      { source: launchBuffer, delay: get(songDelayStore) - 3 },
    ]);
    playerDuration = audioManager.duration;

    // We must transfer the wait of the "launch" audio to the "title" audio
    warnlength = sprites[0].nodeDurationWithDelay + sprites[1].delay;
    titleLength = sprites[1].nodeDuration + sprites[2].delay;
    launchLength = sprites[2].nodeDuration;

    await audioManager.playAndWaitUntilEndAsync();
    emitting = false;
  };

  export const cancel = async () => {
    if (!emitting) {
      return;
    }
    await audioManager.seek(audioManager.duration - 0.1);
  };

  export const pause = async () => {
    await audioManager.pause();
  };
  export const play = async () => {
    await audioManager.play();
  };
</script>

<!--###########################################################################################################-->
<!-- COMPONENT Callout -->

<div data-component="Callout" style="--warnLength: {warnlength}; --titleLength: {titleLength}; --launchLength:{launchLength}">
  <!-- <button on:click={() => audioManager.togglePlay()}>toggle</button> -->
  <div class="progress-icons">
    <div class="warn">
      <button class="empty-button" on:click={() => audioManager.seek(0)}>
        <i class="fa-solid fa-bell" />
      </button>
    </div>
    <div class="title">
      <button
        class="empty-button"
        on:click={() => {
          audioManager.seek(warnlength);
        }}
      >
        <CalloutIcon />
      </button>
    </div>
    <div class="launch">
      <button class="empty-button" on:click={() => audioManager.seek(warnlength + titleLength - 0.3)}>
        <i class="fa-solid fa-stopwatch" />
        <span class="3sec">{$i18n$.components.callout.threeSec}</span>
      </button>
    </div>
  </div>
  <progress class="progress" max={(playerDuration || 0) * 1000} value={($calloutCurrentTime$ || 0) * 1000} />
</div>

<!--###########################################################################################################-->

<!-- STYLES -->
<style lang="scss">
  [data-component="Callout"] {
    width: 100%;
    .progress {
      grid-area: progress;
      width: 100%;
      accent-color: $bold;
      height: 2rem;
    }
    .close {
      grid-area: close;
      align-self: center;
    }

    .icon {
      grid-area: icon;
      align-self: center;
      color: $bold;
      i {
        font-size: 2.5rem;
      }
    }
  }

  .audio-player {
    display: none;
  }

  .progress-icons {
    display: flex;
    align-items: flex-end;

    i {
      font-size: 1.5rem;
      color: $bold;
    }

    .warn {
      flex: var(--warnLength);
    }

    .title {
      flex: var(--titleLength);
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      button {
        font-size: 1.5rem;
        color: $bold;
      }
    }
    .launch {
      flex: var(--launchLength);
      button {
        display: flex;
        align-items: flex-end;
        span {
          white-space: nowrap;
          font-size: 0.8rem;
          font-weight: bold;
          color: $bold;
        }
      }
    }
  }
</style>
