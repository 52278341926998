import { writable } from "svelte/store";
import { appLocalStorage } from "../utils/app-local-storage";


export enum CoachPriorityValues {
    Never = 0,
    One = 1,
    Two = 2,
    Three = 3,
    Always = 9999999
}


export const clientName = writable<string>();
export const dataUrl = writable<string>();
export const songDelayStore = writable<number>(10);
export const coachPriorityAmount = writable<CoachPriorityValues>(2);

export const enableSignalRStore = writable<boolean>(false);
export const keepLastSelectedFolder = writable<boolean>(true);


export const initSettingsStore = async () => {

    dataUrl.set(appLocalStorage.dataUrl);
    clientName.set(appLocalStorage.clientName);

    songDelayStore.set(appLocalStorage.songDelay)
    coachPriorityAmount.set(appLocalStorage.coachPriorityAmout);

    if (appLocalStorage.selectSongKeepLastFolder !== undefined) {
        keepLastSelectedFolder.set(appLocalStorage.selectSongKeepLastFolder);
    }

    // SIGNALR
    enableSignalRStore.set(appLocalStorage.enableSignalR);
    enableSignalRStore.subscribe(value => {
        appLocalStorage.enableSignalR = value;
    });

    subscribeToChanges()
}

const subscribeToChanges = () => {

    songDelayStore.subscribe(delay => {
        appLocalStorage.songDelay = delay;
    })

    coachPriorityAmount.subscribe(value => {
        appLocalStorage.coachPriorityAmout = value;
    })

    dataUrl.subscribe(url => {
        appLocalStorage.dataUrl = url
    })

    clientName.subscribe(clientName => {
        appLocalStorage.clientName = clientName
    })

    keepLastSelectedFolder.subscribe(value => {
        appLocalStorage.selectSongKeepLastFolder = value;
    })

}






