<script lang="ts">
  import { format } from "date-fns";
  import { interval, startWith } from "rxjs";
  import { generate } from "short-uuid";
  import { onDestroy, onMount } from "svelte";
  import { clearSubscriptions, safeSubscribe } from "../utils/rx-utils";
  let hour: string = "";
  let minutes: string = "";
  const cId = generate();

  onMount(() => {
    safeSubscribe(
      cId,
      interval(1000)
        .pipe(startWith(1)) // do not wait 1 second for first tick
        .subscribe(() => {
          const dt = new Date();
          hour = format(dt, "HH");
          minutes = format(dt, "mm");
        })
    );
  });

  onDestroy(() => {
    clearSubscriptions(cId);
  });
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="Clock">
  <div class="clock">
    <span class="hour">{hour}</span>
    <span class="separator">:</span>
    <span class="minutes">{minutes}</span>
  </div>
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="Clock"] {
    display: flex;
    justify-content: center;

    .clock {
      font-weight: 500;
      font-size: 0; // To collapse spacing between spans
      display: flex;
      align-items: center;
      gap: 0.1rem;

      .hour {
        font-size: 1.5rem;
      }
      .separator {
        font-size: 1rem;
        animation: pulse 1s infinite;
      }
      .minutes {
        font-size: 1.5rem;
      }
    }

    @keyframes pulse {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }
  }
</style>
