<script lang="ts">
  import { format } from "date-fns";
  import { map } from "rxjs";
  import { createEventDispatcher } from "svelte";
  import { Song } from "../models";
  import { secondsToTime } from "../utils/general";
  import { QueuedSong } from "./../models/QueuedSong";
  import SongIcon from "./song-icon.svelte";
  import UserTag from "./user-tag.svelte";

  export let song: Song = undefined;
  export let queuedSong: QueuedSong = undefined;

  export let showClose: boolean = false;
  export let showPlannedStartTime: boolean = false;
  export let clickable: boolean = false;

  export let showBox: boolean = true;

  $: localSong = queuedSong?.song || song;

  $: formattedPlannedStartTime$ = queuedSong?.plannedStartTime$?.pipe(map((time) => (time ? format(time, "HH:mm") : "")));

  const dispatch = createEventDispatcher();
</script>

<div
  data-component="SongBox"
  on:click={(e) => {
    dispatch("click", e);
  }}
  class:clickable
  class:box={showBox}
>
  {#if localSong}
    <div class="song-icon">
      <SongIcon song={localSong} size="1.5rem" />
    </div>

    <div class="song-title">
      {localSong.title}
    </div>

    <div class="song-subtitle">
      <span>{secondsToTime(localSong.duration)}</span>
      {#if localSong.subtitle}
        <span>- {localSong.subtitle}</span>
      {/if}
    </div>

    {#if queuedSong}
      <div class="other">
        {#if queuedSong.requestedBy}
          <UserTag username={queuedSong.requestedBy?.username} />
        {/if}
        {#if queuedSong.isCoachRequest}
          <i class="fa-solid fa-user-group" />
        {/if}
        {#if queuedSong.positionOverridden}
          <i class="fa-solid fa-arrow-up-1-9" />
        {/if}
        {#if showPlannedStartTime}
          <div class="song-planned-time">
            <i class="fa-solid fa-clock" />
            <span>{$formattedPlannedStartTime$}</span>
          </div>
        {/if}
        {#if showClose}
          <i class="fa-solid fa-close close" on:click|stopPropagation={() => dispatch("closeClicked")} />
        {/if}
      </div>
    {/if}
  {/if}
</div>

<style lang="scss">
  [data-component="SongBox"] {
    width: 100%;
    display: grid;
    grid-template-areas:
      "icon title other"
      "icon subtitle other";
    grid-template-columns: auto 1fr;
    gap: 0 1rem;
    align-items: center;
    color: $black;
    padding: 0 1rem;
    height: 3rem;

    &.box {
      background-color: $song-color;
      border: 1px solid $secondary;
      border-radius: 0.5rem;
    }

    .song-icon {
      grid-area: icon;
      font-size: 1.5rem;
    }

    .song-title {
      grid-area: title;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 0.5rem;
    }

    .song-subtitle {
      grid-area: subtitle;
      font-size: 0.7rem;
      margin-top: -0.7rem;
      color: gray;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 0.5rem;
    }

    .other {
      grid-area: other;
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .song-planned-time {
      display: flex;
      align-items: center;
      gap: 0.3em;
      span {
        font-size: 0.8rem;
        line-height: 1em;
      }
    }

    .close {
      grid-area: close;
      font-size: 1.5rem;
      font-weight: bold;
      cursor: pointer;
      margin-left: 1rem;
      color: $primary;
    }
  }
</style>
