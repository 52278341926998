<script lang="ts">
    import { i18n } from "../i18n/i18n";
    import { getClientInfo } from "../stores/client-store";
    import { initializeLibraryAsync, reloadDatabaseFromOnedriveAsync } from "../stores/library-store";
    import { clientName, dataUrl } from "../stores/settings-store";
    import { showToast } from "../stores/toaster-store";
    import { oneDriveLoadingSubject } from "../utils/onedrive";
    import OneDriveLoadingProgress from "./one-drive-loading-progress.svelte";

    let clientKey = "";
    let loading = false;
    const loadClientData = async () => {
        try {
            loading = true;
            if (!navigator.onLine) {
                showToast(i18n.toasts.internetConnectionRequired.title, i18n.toasts.internetConnectionRequired.message, "danger");
                return;
            }

            clientKey = clientKey.toUpperCase();
            const clientInfo = await getClientInfo(clientKey);

            dataUrl.set(clientInfo.data);
            clientName.set(clientInfo.clientName);

            await reloadDatabaseFromOnedriveAsync(true);
            await initializeLibraryAsync();
        } catch (e) {
            console.error(e);
            showToast(i18n.toasts.invalidClientCode.title, i18n.toasts.invalidClientCode.message, "danger");
            return;
        } finally {
            loading = false;
        }
    };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="ClientConnect">
    <div class="client-code-container">
        <div class="client-key-label">Code client</div>
        <input
            type="text"
            class="client-key-input"
            bind:value={clientKey}
            disabled={loading}
            on:keydown={(e) => {
                if (e.key == "Enter") {
                    loadClientData();
                }
            }}
        />
    </div>
    <button on:click={() => loadClientData()} disabled={loading || !clientKey}>Connecter</button>

    {#if loading && !!$oneDriveLoadingSubject}
        <OneDriveLoadingProgress />
    {/if}
</div>

<!--######################################################################################-->
<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="ClientConnect"] {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;

        .client-code-container {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            .client-key-label {
                font-size: 1rem;
                text-align: center;
            }

            .client-key-input {
                font-size: 1rem;
                width: 10rem;
                text-align: center;
                text-transform: uppercase;
            }
        }

        button {
            width: 15rem;
            height: 4rem;
        }
    }
</style>
