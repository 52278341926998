<script lang="ts">
    import LoadingAnimation from "./loading-animation.svelte";

    export let status: "disconnected" | "connecting" | "connected" = undefined;
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="SignalrConnectionIcon">
    <i class="fa-solid fa-satellite-dish main-icon" class:shadowed={!status || status === "disconnected" || status === "connecting"} />
    {#if status === "connected"}
        <i class="fa-solid fa-circle-check connected-icon" />
    {/if}
    {#if status === "disconnected"}
        <i class="fa-solid fa-circle-xmark disconnected-icon" />
    {/if}
    {#if status === "connecting"}
        <div class="loading-animation">
            <LoadingAnimation />
        </div>
    {/if}
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="SignalrConnectionIcon"] {
        width: fit-content;
        position: relative;

        .main-icon {
            &.shadowed {
                opacity: 0.3;
            }
        }

        .connected-icon {
            position: absolute;
            color: darkgreen;
            right: -0.05em;
            bottom: -0.05em;
            font-size: 0.4em;
        }

        .disconnected-icon {
            position: absolute;
            color: darkred;
            right: -0.05em;
            bottom: -0.05em;
            font-size: 0.4em;
        }

        .loading-animation {
            position: absolute;
            right: 0;
            bottom: 0;
            color: $bold;
            font-size: 0.4em;
        }
    }
</style>
