<script lang="ts">
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="AudioAnimation">
  <div class="icon">
    <span />
    <span />
    <span />
  </div>
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="AudioAnimation"] {
    height: fit-content;
    width: fit-content;
  }

  $bar-width: 0.4em;
  $bar-height: 1.3em;
  $bar-spacing: 0.2em;

  .icon {
    display: flex;
    justify-content: space-between;
    gap: $bar-spacing;
    height: $bar-height;
    width: fit-content;

    span {
      width: $bar-width;
      height: 100%;
      background-color: currentColor;
      border-radius: $bar-spacing;
      transform-origin: bottom;
      animation: bounce 2.2s ease infinite alternate;
      content: "";

      &:nth-of-type(2) {
        animation-delay: -2.2s; /* Start at the end of animation */
      }

      &:nth-of-type(3) {
        animation-delay: -3.7s; /* Start mid-way of return of animation */
      }
    }
  }

  @keyframes bounce {
    10% {
      transform: scaleY(0.3); /* start by scaling to 30% */
    }

    30% {
      transform: scaleY(1); /* scale up to 100% */
    }

    60% {
      transform: scaleY(0.5); /* scale down to 50% */
    }

    80% {
      transform: scaleY(0.75); /* scale up to 75% */
    }

    100% {
      transform: scaleY(0.6); /* scale down to 60% */
    }
  }
</style>
