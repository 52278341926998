<script lang="ts">
  import { i18n, i18n$ } from "../i18n/i18n";
  import { Song } from "../models";
  import { updateCalloutAsync } from "../stores/library-store";
  import { showToast } from "../stores/toaster-store";
  import { AudioManager } from "../utils/audio-manager";
  import { downloadCalloutAsync, type IAudioData } from "../utils/download";
  import Modal from "./modal.svelte";

  let modal: Modal;
  let selected: SpeechSynthesisVoice;
  let songLocal: Song;
  let saveDisabled = true;
  let calloutTextLocal: string = "";
  let newCalloutData: IAudioData = undefined;
  let loadingInProgress = false;
  let calloutInProgress = false;
  let audioManager: AudioManager;

  export const openDialogAsync = async (song: Song) => {
    audioManager = new AudioManager("SongCalloutDialog");

    songLocal = song;
    calloutTextLocal = song.calloutText;
    newCalloutData = undefined;
    saveDisabled = true;
    const res = await modal.openDialogAsync();

    audioManager.destroy();

    return res ? selected : undefined;
  };

  const textChanged = () => {
    saveDisabled = songLocal.calloutText === calloutTextLocal;
    newCalloutData = undefined;
  };

  const testCallout = async () => {
    if (calloutInProgress) {
      audioManager.pause();
    } else {
      try {
        loadingInProgress = true;
        let arrayBuffer: ArrayBuffer;
        newCalloutData = undefined;
        if (calloutTextLocal === songLocal.calloutText) {
          // Use the already existing calloutText
          arrayBuffer = await songLocal.getCalloutArrayBuffer();
        } else {
          newCalloutData = await downloadCalloutAsync(calloutTextLocal);
          arrayBuffer = await newCalloutData.data.arrayBuffer();
        }

        await audioManager.loadAsync([{ source: arrayBuffer }]);
        loadingInProgress = false;

        calloutInProgress = true;
        await audioManager.playAndWaitUntilEndAsync(true);
      } catch (e) {
        console.error(e);
        showToast(i18n.toasts.errorReadingCallout.title, i18n.toasts.errorReadingCallout.message, "danger");
        loadingInProgress = false;
      }
    }

    calloutInProgress = false;
  };

  const saveCallout = async (): Promise<boolean> => {
    saveDisabled = true;

    try {
      // has not been generated by testing the callout
      if (!newCalloutData) {
        newCalloutData = await downloadCalloutAsync(calloutTextLocal);
      }
      await updateCalloutAsync(songLocal, calloutTextLocal, true, newCalloutData);
      return true;
    } catch (e) {
      console.error(e);
      showToast(i18n.toasts.errorReadingCallout.title, i18n.toasts.errorReadingCallout.message, "danger");
      return false;
    }
  };
</script>

<!-- COMPONENT SongCalloutDialog -->
<Modal
  bind:this={modal}
  acceptCustomFunction={saveCallout}
  config={{
    title: "Configuration de l'appel",
    buttons: ["ok", "cancel", "x"],
    acceptCustomText: i18n.common.save,
    autoSize: true,
    headerIcon: "fa-solid fa-message",
  }}
>
  <div data-component="SongCalloutDialog">
    <h3>{songLocal.title}</h3>
    <div class="callout-box">
      <input bind:value={calloutTextLocal} on:input={textChanged} disabled={calloutInProgress} />
      <button on:click={testCallout} disabled={loadingInProgress}>
        {#if loadingInProgress}
          <i class="fa-solid fa-circle-notch fa-spin" />
        {:else if calloutInProgress}
          <i class="fa-solid fa-xmark" />
        {:else}
          <i class="fa-solid fa-play" />
        {/if}
      </button>
    </div>

    <div class="warning">
      <strong>{$i18n$.common.warning}</strong><br />
      <!-- Warn user for temporary change only -->
      {$i18n$.dialogs.songCallout.warning}
    </div>
  </div>
</Modal>

<!-- STYLES -->
<style lang="scss">
  [data-component="SongCalloutDialog"] {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    width: min(90vw, 40rem);
    padding: 0 2rem 2rem 2rem;

    .callout-box {
      display: flex;
      gap: 1rem;
      input {
        flex: auto;
      }
      button {
        font-size: 1.5rem;
        width: 5rem;
      }
    }

    .warning {
      background-color: $warn-bg;
      border: 1px solid $warn-fg;
      color: $warn-fg;
      padding: 1rem;
      border-radius: 0.5rem;
    }
  }
</style>
