import { add } from 'date-fns';
import { BehaviorSubject, map } from 'rxjs';
import { generate } from 'short-uuid';
import { audioAssets } from '../stores/audio-assets-store';
import type { IQueuedSongDTO } from './DTO/IQueuedSongDTO';
import type { ISignalRConnectionInfo } from './ISignalRConnectionInfo';
import { Song } from "./Song";

export class QueuedSong {
    qId: string = generate();
    song: Song;

    isCoachRequest = false;
    positionOverridden = false;
    requestedBy?: ISignalRConnectionInfo;

    queuedTime: Date;
    startTime?: Date;
    sessionOccurence: number;
    previousOccurenceTime?: Date;
    bypassedBy: QueuedSong[] = [];
    playStatus: "none" | "playing" | "skipped" | "completed" = "none";

    constructor(init?: Partial<QueuedSong>) {

        this.song = init?.song;
        this.qId = init?.qId ?? generate();
        this.isCoachRequest = init?.isCoachRequest ?? false;
        this.positionOverridden = init?.positionOverridden ?? false;
        this.requestedBy = init?.requestedBy;
        this.queuedTime = init?.queuedTime ?? new Date();
        this.startTime = init?.startTime;
        this.sessionOccurence = init?.sessionOccurence ?? 1;
        this.previousOccurenceTime = init?.previousOccurenceTime;
        this.bypassedBy = init?.bypassedBy ?? [];
        this.playStatus = init?.playStatus ?? "none";
        this.queueStatus = init?.queueStatus;

    }


    private queueStatusSubject = new BehaviorSubject<"current" | "waiting" | "history">(undefined);
    public queueStatus$ = this.queueStatusSubject.asObservable();
    get queueStatus() {
        return this.queueStatusSubject.value
    }
    set queueStatus(value: "current" | "waiting" | "history") {

        this.queueStatusSubject.next(value);
    }

    // TOTAL PLAY TIME : FULL CALLOUT DURATION + SONG DURATION
    private totalPlayTimeSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public totalPlayTime$ = this.totalPlayTimeSubject.asObservable();
    public updateTotalPlayTime = (val: number) => {
        this.totalPlayTimeSubject.next(val);
    }

    // PLANNED START TIME
    private plannedStartTimeSubject: BehaviorSubject<Date> = new BehaviorSubject<Date>(undefined);
    public plannedStartTime$ = this.plannedStartTimeSubject.asObservable();
    public get plannedStartTime() {
        return this.plannedStartTimeSubject.value;
    }
    public set plannedStartTime(val: Date) {

        this.plannedStartTimeSubject.next(val);
        if (val) {
            this.plannedEndTimeSubject.next(add(this.plannedStartTime, { seconds: this.totalDuration }))
        }
    }

    // PLANNED END TIME
    private plannedEndTimeSubject: BehaviorSubject<Date> = new BehaviorSubject<Date>(undefined);
    public plannedEndTime$ = this.plannedEndTimeSubject.asObservable();
    public get plannedEndTime() {
        return this.plannedEndTimeSubject.value;
    }

    // REMAINING TIME
    public remainingTime$ = this.totalPlayTime$.pipe(map((val) => {
        return this.totalDuration - val;
    }));
    public get remainingTime() {
        return this.totalDuration - this.totalPlayTimeSubject.value;
    }

    public get totalDuration() {
        return this.fullCalloutDuration + this.song.duration
    }
    public get fullCalloutDuration() {
        // calloutDuration may be null before the download is complete
        return 0.5 + audioAssets.warn.duration + (this.song.calloutDuration || 0) + audioAssets.countdown.duration;
    }
    public get elapsedTimeSinceStart() {
        return Date.now() - this.startTime.getTime();
    }

    public toDTO = (): IQueuedSongDTO => {
        return {
            songId: this.song.id,
            qId: this.qId,
            isCoachRequest: this.isCoachRequest,
            positionOverridden: this.positionOverridden,
            sessionOccurence: this.sessionOccurence,
            previousOccurenceTime: this.previousOccurenceTime,
            bypassedBy: this.bypassedBy.map(qs => qs.qId),
            queuedTime: this.queuedTime,
            startTime: this.startTime,
            playStatus: this.playStatus,
            queueStatus: this.queueStatus,
            requestedBy: this.requestedBy
        }
    }

}