<script lang="ts">
    import Panel from "../components/panel.svelte";
    import { i18n$ } from "../i18n/i18n";
    import { busPush } from "../stores/bus";
    import WaitingList from "./queue.svelte";
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="QueuePanel">
    <Panel>
        <div slot="header" class="header">
            <div class="title">
                <div class="content">
                    <div class="icon">
                        <i class="fa-solid fa-rectangle-list"></i>
                    </div>
                    <span>{$i18n$.session.panelTitle.queue}</span>
                </div>
            </div>

            <div class="actions">
                <div class="separator"></div>
                <button class="empty-button header-button" on:click={() => busPush("DialogShowSessionHistory")}>
                    <i class="fa-solid fa-clock-rotate-left clickable" />
                </button>
            </div>
        </div>

        <WaitingList />
    </Panel>
</div>

<!--######################################################################################-->
<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="QueuePanel"] {
        height: 100%;

        .header {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .title {
                display: flex;
                align-items: center;
                height: 100%;
                .content {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    margin-left: 1rem;

                    .icon {
                        font-size: 1.5rem;
                    }
                }
            }

            .actions {
                display: flex;
                color: white;

                .separator {
                    width: 0.1rem;
                    background-color: $panel-separator-color;
                    height: 100%;
                }

                .header-button {
                    height: 100%;
                    color: white;
                    padding: 0 1rem;
                    font-size: 1.5rem;
                }

                .switch {
                    margin: 0 1rem;
                    display: flex;
                    align-items: center;
                }
                .volume {
                    padding: 0 1rem;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
</style>
