/* eslint-disable max-len */
export const data = {
    common: {
        ok: "Ok",
        yes: "Yes",
        no: "No",
        cancel: "Cancel",
        confirm: "Confirm",
        reconnect: "Reconnect",
        save: "Save",
        always: "Always",
        never: "Never",
        warning: "Warning",
        download: "Download",
        close: "Close",
        add: "Add",
    },

    general: {
        audioLockPreventerMessage: "Solohub.  Do not pause here or it will prevent queue auto continue automatically.",
    },

    menu: {
        newSession: "New Session",
        library: "Library",
        settings: "Settings",
        restart: "Restart",
    },

    session: {
        panelTitle: {
            main: "NOW PLAYING",
            ambient: "AMBIENT MUSIC",
            queue: "WAITING QUEUE",
        },

        panel: {
            main: {
                noCurrent: "No current music",
                addSong: "Add song",
                downloading: "Downloading..."
            },
            queue: {
                add: "ADD",
                info_queueCount: "In queue",
                info_queueEndTime: "End time",
                emptyQueue: "No music in queue",
            }

        },
        ambient: {
            noDownloadInFolder: "No downloaded music in this folder.",
            ambientDeactivated: "Abmient music deactivated",
            activateControl: "Active with control",
            above: "above",
            noMusicSelected: "No music folder selected",
        }
    },
    components: {
        callout: {
            threeSec: "3 sec",
        },
        oneDriveLoadingProgress: {
            title: "Loading data",
            folderReading: "Reading folder",
            foundSongs: "Songs found",
            calloutUpdateFor: "Update introduction for",
        },
        splash: {
            loadingMessage: "Loading",
            databaseUpdated: {
                title: "Database Update",
                message: "The database had to be reset due to a version change. You need to re-download the music.",
            }
        },
        sessionStart: {
            startNewSession: "Start a new session",
            restoreLastSession: "Restore the last active session",
        },
        volume: {
            title: "Volume",
            warnBoost: "Warning, a volume higher than 100% might cause distortion"
        }
    },
    dialogs: {
        addToQueue: {
            title: "Add to Queue",
        },
        library: {
            title: "Library",
            resync: "Resynchronize the library",
            inMemory: "In memory",
            files: "Files",
            mb: "Mb"
        },
        queuedSongInfo: {
            title: "Modification",
            musicQueuedAt: "Music added at",
            minutesAgo: "{min} minute{min||s} ago",
            bypassedBy: "Bypassed by",
            position: "Position",
            withCoach: "With coach",
            delete: "Delete",
        },
        reorderSong: {
            title: "Repositioning",
            header: "Reposition the music to the desired position",
        },
        selectFolder: {
            title: "Select a folder",
            selectFolder: "Choose this folder",
            cancel: "Cancel",
        },
        sessionHistory: {
            title: "Session History",
            time: "Time",
            song: "Music",
            occurence: "Occurrence",
            priority: "Priority",
            status: "Status",
            emptyHistory: "No history to display",
        },
        sessionInfo: {
            title: "Session",
            sessionCode: "Session code",
            connectedUsers: "Connected users",
            connectingToServer: "Connecting to the server...",
        },
        settings: {
            title: "Settings",
            version: "Version",
            seconds: "{sec} second{sec||s}",
            songDelay: "Delay between the introduction and start of a song",
            coachPriority: "Coach priority (Maximum of subsequent requests)",
            keepLastFolder: "Music selection: Keep the last selected folder.",
            disconnectClient: "Disconnect the client",
            disconnectClientInfo: "Disconnect the client and delete all data.",
            changeLanguage: "Change language",
        },
        songCallout: {
            title: "Introduction Configuration",
            save: "Save",
            warning: "The change in the call will be temporary and will apply only to this device. If you want to make the change permanent, please modify the source file in OneDrive."
        }

    },
    confirmDialogs: {
        newSessionConfirm: {
            title: "New Session",
            message: "If you create a new session you will lose the active session.",
        },
        ambientChangeFolderEmptyFolder: {
            title: "Empty Folder",
            message: "No music found in the selected folder."
        },
        ambientConfirmDownloadMissing: {
            title: "Download Missing Songs",
            message: "Music available: {avail} / {total}. Do you want to download the missing music?",
        },
        ambientConfirmDownloadAll: {
            title: "Download Missing Songs",
            message: "No music available in this folder. Do you want to download them?",
        },
        mainPlayerConfirmRestart: {
            title: "Start the Introduction",
            message: "Start the introduction and restart the music?",
        },
        mainPlayerConfirmSkip: {
            title: "Skip Music",
            message: "Skip to the next music in the list?",
        },
        confirmDownloadSong: {
            title: "Music Not Downloaded",
            message: "This music is not yet downloaded",
        },
        confirmClientDisconnection: {
            title: "Client Disconnection",
            message: "WARNING! Are you sure you want to erase all settings and saved music? The application will automatically restart.",
        },
        changeLanguageRestart: {
            title: "Change Language",
            message: "You must restart the application to change language. Do you want to restart now?",
        },

    },
    toasts: {
        downloadNotAvailable: {
            title: "Download Unavailable",
            message: "You need to be connected to the internet to download.",
        },
        downloadError: {
            title: "Download Error",
            message: "The music \"{song}\" could not be downloaded.",
        },
        sessionRestoreError: {
            title: "Loading Error",
            message: "An error occurred while retrieving the session. A new session has been created.",
        },
        internetConnectionRequired: {
            title: "Data Loading",
            message: "You need to be connected to the internet to download.",
            messageSyncToLibrary: "You need to be connected to the internet to synchronize the library.",
        },
        invalidClientCode: {
            title: "Client Code",
            message: "This client code is invalid.",
        },
        fileTooLongToDownload: {
            title: "Download Impossible",
            message: "Music longer than {minutes} minutes cannot be downloaded.",
        },
        songAlreadyInQueue: {
            title: "Addition Impossible",
            message: "The music \"{song}\" is already in the list.",
        },
        repositionSong: {
            title: "Repositioning a Song",
            message_removedFromQueue: "The music \"{song}\" has been removed from the queue.",
        },
        errorReadingCallout: {
            title: "Error",
            message: "An error occurred while reading the introduction."
        },
        loadingTimeout: {
            title: "Loading timeout",
            message: "A problem occurred while loading. The application will automatically restart."
        },
        appResetTimeout: {
            title: "Inactive timeout reached",
            message: "The application will reset automatically in 10 seconds."
        },

    }

}
