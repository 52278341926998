
export const round = (value: number, decimals: number) => {
    const factor = Math.pow(10, decimals);
    return Math.round(value * factor) / factor;
}

export const slugify = (str = "") =>
    str.toLowerCase().replace(/ /g, "-").replace(/\./g, "");


export const millisecondsToTime = (ms: number) => {
    return secondsToTime(ms / 1000);

}

export const secondsToTime = (sec: number) => {

    const intSeconds = Math.trunc(sec > 0 ? sec : 0);
    const seconds = intSeconds % 60;
    const minutes = (intSeconds - seconds) / 60;

    return `${minutes.toString().padStart(1, '0')}:${seconds.toString().padStart(2, '0')}`;
}


export const sum = (data: number[]) => {
    return data.reduce((a, b) => a + b, 0)
}


export const minmax = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

export const logdev = (message?: any, ...optionalParams: any[]) => {
    if (import.meta.env.DEV) {
        console.log(message, ...optionalParams)
    }
}

export const logdevCond = (condition: boolean, message?: any, ...optionalParams: any[]) => {
    if (!condition) return;
    if (import.meta.env.DEV) {
        console.log(message, ...optionalParams)
    }
}
