<script context="module" lang="ts">
    export interface ToggleButtonOption {
        value: any;
        text: string;
    }
</script>

<script lang="ts">
    import { createEventDispatcher } from "svelte";

    export let options: ToggleButtonOption[] = [];
    export let selectedValue: any = undefined;

    const dispatcher = createEventDispatcher();

    const select = (value: any) => {
        selectedValue = value;

        dispatcher("selectedValueChanged", value);
    };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="ToggleButtons">
    <div class="container">
        {#each options as opt}
            <button on:click={() => select(opt.value)} class:toggled={opt.value === selectedValue}>{opt.text}</button>
        {/each}
    </div>
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
    .container {
        display: flex;
        gap: 0.2rem;
    }
</style>
