
export const getAudioDuration = (blob) => {
    return new Promise<number>((resolve, reject) => {
        try {

            const blobURL = URL.createObjectURL(blob);

            const audioElement = new Audio();
            audioElement.autoplay = false;
            audioElement.src = blobURL;

            audioElement.addEventListener("loadedmetadata", function () {

                // Résoudre la promesse avec la durée de l'audio
                resolve(audioElement.duration);

                // Libérer l'URL objet pour éviter les fuites de mémoire
                URL.revokeObjectURL(blobURL);
            });

            audioElement.addEventListener("error", () => {
                reject(new Error("Impossible de charger le Blob en tant que fichier audio."));
                URL.revokeObjectURL(blobURL);
            });
        } catch (e) {
            console.error(e);
        }
    });
};