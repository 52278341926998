import { filter, Subject } from "rxjs";

export type BusMessageType =
    | "DialogShowSongCalloutConfig"
    | "DialogShowSettings"
    | "DialogShowLibrary"
    | "DialogShowSessionInfo"
    | "DialogShowSessionHistory"
    | "DialogShowAddToQueue"
    | "PlayerAction"
    | "AddSnap";

export interface BusMessage {
    type: BusMessageType,
    data?: any;
}


const busSubject = new Subject<BusMessage>();
export const bus$ = busSubject.asObservable();


export const busPush = (type: BusMessageType, data: any = undefined) => {
    busSubject.next({ type, data })
}

export const busListen = (type: BusMessageType) => {
    return bus$.pipe(filter(msg => msg.type === type));
}