<script lang="ts">
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="AddSongIcon">
    <i class="fa-regular fa-rectangle-list" />

    <div class="plus">
        <i class="fa-solid fa-circle" />
        <i class="fa-solid fa-circle-plus" />
    </div>
</div>

<!--######################################################################################-->
<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="AddSongIcon"] {
        @include overlay-container;

        .plus {
            @include overlay-container;
            transform: translate(0.4em, 0.2em);
            .fa-circle {
                color: white;
                font-size: 0.75em;
            }
            .fa-circle-plus {
                font-size: 0.6em;
            }
        }
    }
</style>
