<script lang="ts">
  import { format } from "date-fns";
  import { flip } from "svelte/animate";
  import { fade, fly } from "svelte/transition";
  import AddSongIcon from "../components/icons/add-song-icon.svelte";
  import Songbox from "../components/song-box.svelte";
  import QueuedSongInfoDialog from "../dialogs/queued-song-info-dialog.svelte";
  import ReorderSongDialog from "../dialogs/reorder-song-dialog.svelte";
  import { i18n$ } from "../i18n/i18n";
  import type { QueuedSong } from "../models";
  import { busPush } from "../stores/bus";
  import { changeSongCoachRequestStatus, queueEndTime$, queueSubject, removeFromQueue, waitingQueue$ } from "../stores/queue-store";
  import { moveItemToIndex } from "../utils/array";

  let queuedSongInfoDialog: QueuedSongInfoDialog;
  let reorderSongDialog: ReorderSongDialog;

  const addSongClicked = async () => {
    busPush("DialogShowAddToQueue");
  };

  const songClicked = async (qs: QueuedSong) => {
    let res = await queuedSongInfoDialog.openDialogAsync(qs);

    if (!res) {
      return;
    }

    switch (res) {
      case "delete":
        removeFromQueue(qs.qId);
        break;
      case "reorder":
        const newIndex = await reorderSongDialog.openDialogAsync(qs);
        // TODO: Cancel works?
        if (!newIndex) {
          return;
        }
        qs.positionOverridden = true;
        let queue = queueSubject.value;
        moveItemToIndex(queue, qs, newIndex);
        queueSubject.next(queue);
        break;
      case "changeToCoachRequest":
        changeSongCoachRequestStatus(qs, true);
        break;
    }
  };
</script>

<!-- COMPONENT -->
<div data-component="Queue">
  <div class="top-part">
    <button class="primary add-song-button" on:click={() => addSongClicked()}>
      <AddSongIcon />
      <span>{$i18n$.session.panel.main.addSong}</span>
    </button>

    <div class="separator" />

    <div class="info-block">
      <div class="label">{$i18n$.session.panel.queue.info_queueCount}</div>
      <div class="value">{$waitingQueue$?.length}</div>
    </div>

    <div class="separator" />

    <div class="info-block">
      <div class="label">{$i18n$.session.panel.queue.info_queueEndTime}</div>
      <div class="value">
        {$queueEndTime$ ? format($queueEndTime$, "HH:mm") : "-"}
      </div>
    </div>
  </div>

  <div class="list-container">
    <div class="list-content">
      {#if !$waitingQueue$?.length}
        <div class="empty-list gray-message">{$i18n$.session.panel.queue.emptyQueue}</div>
      {:else}
        {#each $waitingQueue$ as qSong (qSong.qId)}
          <div animate:flip={{ duration: 300 }} in:fly={{ y: -20, duration: 500 }} out:fade class="file-box clickable">
            <Songbox queuedSong={qSong} on:click={() => songClicked(qSong)} showPlannedStartTime={true} />
          </div>
        {/each}
      {/if}
    </div>
  </div>
</div>

<QueuedSongInfoDialog bind:this={queuedSongInfoDialog} />
<ReorderSongDialog bind:this={reorderSongDialog} />

<!-- STYLE -->
<style lang="scss">
  [data-component="Queue"] {
    height: 100%;
    display: flex;
    flex-direction: column;

    .top-part {
      display: grid;
      grid-template-columns: 1fr auto 1fr auto 1fr;
      height: 4rem;
      margin-bottom: 1rem;

      .add-song-button {
        font-size: 1.5rem;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        span {
          font-size: 0.8rem;
        }
      }

      .separator {
        width: 1px;
        background-color: gray;
        margin: 0 1rem;
      }

      .info-block {
        text-align: center;
        align-self: center;
        .label {
          margin-bottom: 0.5rem;
        }
        .value {
          font-weight: bold;
        }
      }
    }

    .list-container {
      margin-top: 1rem;
      flex: 1 0 0;
      overflow: auto;

      .list-content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .empty-list {
          text-align: center;
          margin-top: 2rem;
        }
      }
    }

    .planned-time {
      color: gray;
      font-size: 0.8rem;
    }
  }
</style>
