import { differenceInSeconds } from 'date-fns';
import { BehaviorSubject, Subscription, interval, map } from 'rxjs';
import { generate } from 'short-uuid';
import { i18n } from '../i18n/i18n';
import { QueuedSong, type IPlayerInfoDTO, type ISessionInfoDTO } from '../models';
import type { ISessionInfoStorageDTO } from "../models/DTO/ISessionInfoStorageDTO";
import { appLocalStorage } from "../utils/app-local-storage";
import { clearSubscriptions, safeSubscribe } from '../utils/rx-utils';
import { IntervalWorker } from '../utils/web-worker';
import { appLastClickTime, keepAlivePlayingAudioSubject, resetApplication } from './app-store';
import { addSongToDownloadQueue, getLibraryDTOCompressed, getSong, lib } from './library-store';
import { getCurrentSong, getQueueDTO, queueSubject } from "./queue-store";
import { connectToSignalrAsync, signalrBroadcast } from "./signalr-store";
import { showToast } from "./toaster-store";

export type PlayerStatus = "empty" | "loading" | "playing" | "paused" | "downloading" | "callout";

export const mainPlayerStatusSubject = new BehaviorSubject<PlayerStatus>("empty");
export const mainPlayerIsPlaying$ = mainPlayerStatusSubject.pipe(map(s => s === "playing" || s === "callout"));

export const ambientPlayerStatusSubject = new BehaviorSubject<PlayerStatus>("empty");
export const ambientPlayerIsPlaying$ = ambientPlayerStatusSubject.pipe(map(s => s === "playing"));

export const playerTimeSubject = new BehaviorSubject<number>(0);
export const autoPlaySubject = new BehaviorSubject<boolean>(true);

export let clearingSession = false;

export type SessionInfo = { id: string, createdAt: Date };
export const sessionInfoSubject = new BehaviorSubject<SessionInfo | undefined>(undefined);

let idleResetIntervalWorker: IntervalWorker;
let idleResetSubscription: Subscription;



const startIdleResetWorker = () => {

    idleResetIntervalWorker = new IntervalWorker("app reset");
    // every seconds check if we need to reset the app
    idleResetSubscription = idleResetIntervalWorker.start(1000).subscribe(() => {

        const clickIdleTime = (Date.now() - appLastClickTime.value);
        const resetInactiveCountdown = (import.meta.env.VITE_APPCLICK_INACTIVE_TIMEOUT_MINUTES * 60 * 1000) - clickIdleTime;

        const playIdleTime = (Date.now() - keepAlivePlayingAudioSubject.value);
        const resetInactivePlayingCountdown = (import.meta.env.VITE_APPPLAYING_INACTIVE_TIMEOUT_MINUTES * 60 * 1000) - playIdleTime;

        if ((resetInactiveCountdown <= 10000 && resetInactiveCountdown > 9000)
            || (resetInactiveCountdown <= 10000 && resetInactiveCountdown > 9000)) {
            showToast(i18n.toasts.appResetTimeout.title, i18n.toasts.appResetTimeout.message, "warn");
        }

        if (resetInactiveCountdown <= 0 || resetInactivePlayingCountdown <= 0) {
            resetApplication();
        }

        console.log(`autoreset Click(${resetInactiveCountdown / 1000} seconds) Play(${resetInactivePlayingCountdown / 1000} seconds))`)
    })

}



export const clearSession = () => {

    if (idleResetIntervalWorker) {
        idleResetSubscription.unsubscribe();
        idleResetIntervalWorker.stop();
        idleResetIntervalWorker.terminate();
    }

    clearingSession = true
    mainPlayerStatusSubject.next("empty")
    playerTimeSubject.next(0)

    if (sessionInfoSubject.value) {
        clearSubscriptions(`SESSION_${sessionInfoSubject.value.id}`)
    }

    sessionInfoSubject.next(undefined);
    queueSubject.next([])

    clearingSession = false
}



export const createNewSession = (sessionId: string = undefined) => {

    if (sessionInfoSubject.value) {
        clearSession()
    }

    if (!sessionId) {
        sessionId = generate().slice(0, 5);
    }

    sessionInfoSubject.next({ id: sessionId, createdAt: new Date() })

    startIdleResetWorker();

    saveSession();
    connectToSignalrAsync();
    registerSessionEvents();
}


export const getSessionAgeInSeconds = () => {
    if (!sessionInfoSubject.value)
        return 0;

    return differenceInSeconds(new Date(), sessionInfoSubject.value.createdAt);
}


export const saveSession = () => {

    try {
        const session: ISessionInfoStorageDTO = {
            sessionId: sessionInfoSubject.value.id,
            sessionSavedDate: new Date(),
            queue: queueSubject.value.map(qs => qs.toDTO()),
            autoplay: autoPlaySubject.value
        }

        appLocalStorage.sessionInfo = session;

    } catch (ex) {
        console.error("Erreur de sauvegarde de la session");
    }
}

export const restoreSession = async () => {

    try {
        const session = appLocalStorage.sessionInfo;
        if (!session) {
            return false;
        }

        createNewSession(session.sessionId);

        autoPlaySubject.next(session.autoplay ?? true);

        const newQueue = session.queue.map(itm => {
            itm.startTime = itm.startTime ? new Date(itm.startTime) : undefined;
            itm.queuedTime = new Date(itm.queuedTime)


            const song = getSong(itm.songId);
            if (song.downloadStatus !== "downloaded") {
                addSongToDownloadQueue(song);
            }

            // Create QueuedSong with Song Info and DTO properties
            const qs = new QueuedSong(
                {
                    song: song,
                    ...itm,
                    bypassedBy: []  // will be defined after loop
                });



            // Reset the current song to put it back in waiting list, it will be dequeued automatically in queueStore
            if (qs.queueStatus === "current") {
                qs.queueStatus = "waiting";
                qs.playStatus = "none";
                qs.startTime = undefined;
            }

            return qs;
        })

        // Put back bypassed by references
        session.queue.filter(qsDto => qsDto.bypassedBy?.length)
            .forEach(qsDto => {
                const queuedSong = newQueue.find(qs => qs.qId === qsDto.qId);
                queuedSong.bypassedBy = qsDto.bypassedBy.map(qsqId => newQueue.find(qs => qs.qId === qsqId))
            });

        queueSubject.next(newQueue);

    } catch (ex) {

        showToast(
            i18n.toasts.sessionRestoreError.title,
            i18n.toasts.sessionRestoreError.message,
            "danger"
        );
        console.error(ex);
        createNewSession();
    }
}


export const getSessionInfoDTO = () => {

    const dto: ISessionInfoDTO = {
        sessionId: sessionInfoSubject.value.id,
        libraryCompressed: getLibraryDTOCompressed(),
        queue: getQueueDTO(),
        player: getPlayerDTO()
    }

    return dto;
}


export const getPlayerDTO = (): IPlayerInfoDTO => {
    const curSong = getCurrentSong();
    if (!curSong) {
        return undefined;
    }
    return {
        song: curSong.toDTO(),
        status: mainPlayerStatusSubject.value,
        time: playerTimeSubject.value,
    }
}

const registerSessionEvents = () => {


    // ClearSubscriptions is done in the ClearSession()
    safeSubscribe(
        `SESSION_${sessionInfoSubject.value.id}`,
        mainPlayerStatusSubject.subscribe(() => {
            signalrBroadcast("playerUpdate", getPlayerDTO())
        }),

        // TO DO : CHECK HERE
        queueSubject.subscribe(() => {
            signalrBroadcast("queueUpdate", getQueueDTO())
        }),
        lib.subscribe(() => {
            signalrBroadcast("libraryUpdate", getLibraryDTOCompressed())
        }),
        interval(5000).subscribe(() => {
            signalrBroadcast("playerUpdate", getPlayerDTO())
        })
    )



}
