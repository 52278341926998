/* eslint-disable max-len */
export const data = {
    common: {
        ok: "Ok",
        yes: "Oui",
        no: "Non",
        cancel: "Annuler",
        confirm: "Confirmer",
        save: "Sauvegarder",
        reconnect: "Reconnecter",
        always: "Toujours",
        never: "Jamais",
        warning: "Attention",
        download: "Télécharger",
        close: "Fermer",
        add: "Ajouter",
    },

    general: {
        audioLockPreventerMessage: "Solohub.  Ne pas mettre en pause ici, cela empêchera la file d'attente de continuer automatiquement.",
    },

    menu: {
        newSession: "Nouvelle session",
        library: "Bibliothèque",
        settings: "Paramètres",
        restart: "Redémarrer",
    },

    session: {
        panelTitle: {
            main: "EN LECTURE",
            ambient: "AMBIANCE",
            queue: "EN ATTENTE",
        },

        panel: {
            main: {
                noCurrent: "Aucune musique en lecture",
                addSong: "Ajouter",
                downloading: "Téléchargement en cours..."
            },
            queue: {
                add: "AJOUTER",
                info_queueCount: "En attente",
                info_queueEndTime: "Heure de fin",
                emptyQueue: "Aucune musique en attente",
            }

        },
        ambient: {
            noDownloadInFolder: "Aucune musique téléchargée dans ce répertoire.",
            ambientDeactivated: "Musique ambiante désactivée",
            activateControl: "Activer avec le contrôle",
            above: "plus haut",
            noMusicSelected: "Aucun répertoire de musique sélectionné",
        }
    },
    components: {
        callout: {
            threeSec: "3 sec",
        },
        oneDriveLoadingProgress: {
            title: "Chargement des données",
            folderReading: "Lecture du répertoire",
            foundSongs: "Musiques trouvées",
            calloutUpdateFor: "Mise à jour de l'introduction pour",
        },
        splash: {
            loadingMessage: "Chargement",
            databaseUpdated: {
                title: "Mise à jour de la base de données",
                message: "La base de données a dû être réinitialisée dû à un changement de version. Vous devez re-télécharger les musiques.",
            }
        },
        sessionStart: {
            startNewSession: "Démarrer une nouvelle session",
            restoreLastSession: "Récupérer la dernière session active",
        },
        volume: {
            title: "Volume",
            warnBoost: "Attention, un volume plus grand que 100% pourrait causer une distortion"
        }
    },
    dialogs: {
        addToQueue: {
            title: "Ajout à la liste d'attente",
        },
        library: {
            title: "Bibliothèque",
            resync: "Resynchroniser la bibliothèque",
            inMemory: "En mémoire",
            files: "Fichiers",
            mb: "Mb"
        },
        queuedSongInfo: {
            title: "Modification",
            musicQueuedAt: "Musique ajoutée à",
            minutesAgo: "il y a {min} minute{min||s}",
            bypassedBy: "Dépassé par",
            position: "Position",
            withCoach: "En leçon",
            delete: "Supprimer",
        },
        reorderSong: {
            title: "Repositionnement",
            header: "Repositionner la musique à la position désirée",
        },
        selectFolder: {
            title: "Sélection d'un répertoire",
            selectFolder: "Choisir ce repertoire",
            cancel: "Annuler",
        },
        sessionHistory: {
            title: "Historique des sessions",
            time: "Heure",
            song: "Musique",
            occurence: "Occurence",
            priority: "Priorité",
            status: "Statut",
            emptyHistory: "Aucun historique à afficher",
        },
        sessionInfo: {
            title: "Session",
            sessionCode: "Code de session",
            connectedUsers: "Utilisateurs connectés",
            connectingToServer: "Connexion au serveur...",
        },
        settings: {
            title: "Paramètres",
            version: "Version",
            seconds: "{sec} seconde{sec||s}",
            songDelay: "Délais entre l'introduction et démarrage d'une musique",
            coachPriority: "Priorité aux entraîneurs (Maximum de demandes subséquentes)",
            keepLastFolder: "Sélection de musique : Conserver le dernier dossier sélectionné.",
            disconnectClient: "Déconnecter le client",
            disconnectClientInfo: "Déconnecter le client et effacer toutes les données.",
            changeLanguage: "Changer la langue",
        },
        songCallout: {
            title: "Configuration de l'introduction",
            save: "Sauvegarder",
            warning: "La modification de l'appel sera temporaire et s'appliquera uniquement à cet appareil. Si vous souhaitez effectuer la modification de manière permanente, veuillez modifier le fichier source dans OneDrive."
        }

    },
    confirmDialogs: {
        newSessionConfirm: {
            title: "Nouvelle session",
            message: "Si vous créez une nouvelle session vous perdrez la session active.",
        },
        ambientChangeFolderEmptyFolder: {
            title: "Répertoire vide",
            message: "Aucune musique trouvée dans le répertoire choisi."
        },
        ambientConfirmDownloadMissing: {
            title: "Téléchargement manquant",
            message: "Musiques disponibles : {avail} / {total}.  Désirez-vous télécharger les musiques manquantes",
        },
        ambientConfirmDownloadAll: {
            title: "Téléchargement manquant",
            message: "Aucune musique disponible dans ce répertoire.  Désirez-vous les télécharger?",
        },
        mainPlayerConfirmRestart: {
            title: "Démarrer l'introduction",
            message: "Démarrer l'introduction et recommencer la musique ?",
        },
        mainPlayerConfirmSkip: {
            title: "Sauter musique",
            message: "Sauter à la prochaine musique de la liste ?",
        },
        confirmDownloadSong: {
            title: "Musique non téléchargée",
            message: "Cette musique n'est pas encore téléchargée",
        },
        confirmClientDisconnection: {
            title: "Déconnexion du client",
            message: "ATTENTION! Voulez-vous vraiment effacer tous les paramètres et les musiques sauvegardées?  L'appliation redémarrera automatiquement.",
        },
        changeLanguageRestart: {
            title: "Changement de Language",
            message: "Vous devez redémarrer pour changer la langue. Redémarrer maintenant?",
        },

    },
    toasts: {
        downloadNotAvailable: {
            title: "Téléchargement indisponible",
            message: "Vous devez être connecté à internet pour télécharger.",
        },
        downloadError: {
            title: "Erreur de téléchargement",
            message: "La musique \"{song}\" n'a pu être téléchargée.",
        },
        sessionRestoreError: {
            title: "Erreur de chargement",
            message: "Une erreur est survenue lors de la récupération de la session.  Une nouvelle session a été créée.",
        },
        internetConnectionRequired: {
            title: "Chargement des données",
            message: "Vous devez être connecté à internet pour télécharger.",
            messageSyncToLibrary: "Vous devez être connecté à internet pour synchroniser la bibliothèque.",
        },
        invalidClientCode: {
            title: "Code client",
            message: "Ce code client est invalide.",
        },
        fileTooLongToDownload: {
            title: "Téléchargement impossible",
            message: "Les musiques de plus de {minutes} minutes ne peuvent pas être téléchargées.",
        },
        songAlreadyInQueue: {
            title: "Ajout impossible",
            message: "La musique \"{song}\" est déjà en liste.",
        },
        repositionSong: {
            title: "Repositionnement d'une musique",
            message_removedFromQueue: "La musique \"{song}\" a été retirée de la liste d'attente.",
        },
        errorReadingCallout: {
            title: "Erreur",
            message: "Une erreur est survenue lors de la lecture de l'introduction."
        },
        loadingTimeout: {
            title: "Problème de Chargement",
            message: "Un problème est survenu lors du chargement.  L'application redémarrera automatiquement."
        },
        appResetTimeout: {
            title: "Temps d'inactivité atteint",
            message: "L'application redémarrera automatiquement dans 10 secondes"
        },

    }

}
