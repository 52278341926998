<script lang="ts">
  import { onMount } from "svelte";
  import CloudDownloadProgress from "../components/cloud-download-progress.svelte";
  import type { Song } from "../models";

  export let song: Song;
  export let size = undefined;

  let mainDiv: HTMLDivElement;

  $: songDownloadStatus$ = song.downloadStatus$;
  $: songDownloadProgress$ = song?.downloadProgress$;

  onMount(() => {
    if (!size) {
      size = window.getComputedStyle(mainDiv).getPropertyValue("font-size");
    }
  });
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div bind:this={mainDiv} data-component="SongIcon" style="--size: {size}">
  {#if song}
    {#if $songDownloadStatus$ === "downloaded"}
      <i class="fa-solid fa-music" />
    {:else if $songDownloadStatus$ === "downloading"}
      <CloudDownloadProgress progress={$songDownloadProgress$} {size} />
    {:else}
      <i class="fa-solid fa-cloud" />
    {/if}
  {/if}
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  i {
    font-size: var(--size);
  }
</style>
