<script lang="ts">
  import { i18n, i18nParams } from "../i18n/i18n";
  import type { Song } from "../models";
  import { busPush } from "../stores/bus";
  import { addSongToDownloadQueue, songsDownloadQueue$ } from "../stores/library-store";
  import { showToast } from "../stores/toaster-store";
  import { round, secondsToTime } from "../utils/general";
  import CalloutIcon from "./icons/callout-icon.svelte";
  import SongIcon from "./song-icon.svelte";

  export let song: Song;

  $: songDownloadStatus$ = song.downloadStatus$;

  const download = async () => {
    if (!navigator.onLine) {
      showToast(i18n.toasts.internetConnectionRequired.title, i18n.toasts.internetConnectionRequired.message, "danger");
      return;
    }
    addSongToDownloadQueue(song);
  };

  const songOverLimitMessage = () => {
    showToast(
      i18n.toasts.fileTooLongToDownload.title,
      i18nParams(i18n.toasts.fileTooLongToDownload.message, { minutes: import.meta.env.VITE_AUDIO_MAX_DURATION_MINUTES }),
      "danger"
    );
  };

  const deleteLocal = async () => {
    await song.deleteLocalDataAsync();
  };

  const calloutConfigClicked = async () => {
    busPush("DialogShowSongCalloutConfig", song);
  };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="LibraryTreeSong">
  <div class="icon">
    <SongIcon {song} size="1.5rem" />
  </div>
  <div class="song-texts">
    <div class="song-title ellipsis">{song.title}</div>
    <div class="song-subtitle ellipsis">{song.subtitle}</div>
  </div>

  <div class="action-container">
    <div class="callout-button">
      {#if $songDownloadStatus$ === "downloaded"}
        <button class="empty-button" on:click={calloutConfigClicked} title={song.calloutText}>
          <CalloutIcon />
        </button>
      {/if}
    </div>

    <div class="song-duration" class:hide={song.duration <= 0}>
      <span>
        {secondsToTime(song.duration)}
      </span>
      <i class="fa-solid fa-clock" />
    </div>

    {#if $songDownloadStatus$ === "downloading"}
      <button class="empty-button download-button downloading-cancel-button" on:click={() => song.cancelDownload()}>
        <div class="cancel-icon">
          <i class="fa-solid fa-ban" />
        </div>
      </button>
    {:else if $songDownloadStatus$ === "downloaded"}
      <button class="empty-button download-button delete-button" on:click={deleteLocal}>
        <div class="song-size">{round(song.size / 1000000, 1)} Mb</div>
        <i class="delete-icon fa-regular fa-trash-can" />
      </button>
    {:else if song.durationOverLimit}
      <button class="empty-button download-button over-limit-button" on:click={songOverLimitMessage}>
        <div class="song-size">{round(song.size / 1000000, 1)} Mb</div>
        <i class="fa-solid fa-exclamation-triangle song-too-large-icon" />
      </button>
    {:else}
      <button class="empty-button download-button" on:click={download} disabled={$songsDownloadQueue$.includes(song)}>
        <div class="song-size">{round(song.size / 1000000, 1)} Mb</div>
        <i class="download-icon fa-solid fa-cloud-arrow-down" />
      </button>
    {/if}
  </div>
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="LibraryTreeSong"] {
    display: flex;
    align-items: center;
    height: 2.7rem;
    gap: 1rem;
    font-size: 1rem;
    margin-bottom: 0.2rem;
    padding: 0.3rem 1rem;
    border-bottom: 1px solid #dddddd;
    &:last-child {
      border-bottom: none;
    }

    .song-texts {
      flex: auto;
      overflow: hidden;

      .song-subtitle {
        font-size: 0.8rem;
        color: gray;
      }
    }

    .action-container {
      display: flex;
      justify-content: flex-end;

      .callout-button {
        width: 3rem;
        display: flex;
        justify-content: flex-end;
        button {
          font-size: 1.5rem;
          height: auto;
        }
      }

      .song-duration {
        width: 5rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.4rem;
        color: gray;
        span {
          font-size: 0.7rem;
        }
        i {
          font-size: 1rem;
        }
      }

      .download-button {
        display: flex;
        width: 6rem;
        justify-content: flex-end;
        gap: 0.5rem;

        .song-size {
          white-space: nowrap;
          font-size: 0.7rem;
          text-align: right;
        }

        &.over-limit-button {
          color: gray;
        }

        &.downloading-cancel-button {
          width: 6rem;
          font-size: 1.2rem;
          color: $darkred;
        }
        &.delete-button {
          color: $darkred;
        }
      }
    }
  }
</style>
