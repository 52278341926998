<script lang="ts">
  import PopupMenuButton from "../components/popup-menu-button.svelte";
  import SignalrConnectionIcon from "../components/signalr-connection-icon.svelte";
  import { i18n } from "../i18n/i18n";

  import Clock from "../components/clock.svelte";
  import { resetApplication, showAppConfirmDialogAsync } from "../stores/app-store";
  import { busPush } from "../stores/bus";
  import { lib } from "../stores/library-store";
  import { createNewSession } from "../stores/session-store";
  import { clientName, enableSignalRStore } from "../stores/settings-store";
  import { signalRConnectionStatusSubject } from "../stores/signalr-store";

  const menuItems = [
    {
      text: i18n.menu.newSession,
      action: () => newSession(),
      icon: "fa-solid fa-file",
    },
    {
      text: i18n.menu.library,
      action: () => busPush("DialogShowLibrary"),
      icon: "fa-solid fa-database",
    },
    {
      text: i18n.menu.settings,
      action: () => busPush("DialogShowSettings"),
      icon: "fa-solid fa-gear",
    },
    {
      text: i18n.menu.restart,
      action: () => resetApplication(),
      icon: "fa-solid fa-redo",
    },
    // {
    //   text: "À propos",
    //   action: undefined, //() => busPush("DialogShowAbout"),
    //   icon: "fa-solid fa-info-circle",
    // },
  ];

  const newSession = async () => {
    const res = await showAppConfirmDialogAsync({
      title: i18n.confirmDialogs.newSessionConfirm.title,
      contentText: i18n.confirmDialogs.newSessionConfirm.message,
      headerIcon: "fa-solid fa-triangle-exclamation",
      buttons: ["cancel", "ok"],
      contentPadding: "2rem",
    });
    if (!res) {
      return;
    }
    createNewSession();
  };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="AppTopBar">
  <div class="top-bar-content">
    <div class="top-left">
      <div>
        <img class="logo" src="/solohub_dark.svg" alt="solohub" />
      </div>
    </div>

    <div class="top-center">
      <div class="client-name">
        {#if $clientName}
          {$clientName}
        {/if}
      </div>
    </div>

    <div class="top-right">
      <div class="settings-buttons">
        <Clock />

        {#if $enableSignalRStore}
          <div class="clickable" on:click={() => busPush("DialogShowSessionInfo")}>
            <SignalrConnectionIcon status={$signalRConnectionStatusSubject} />
          </div>
        {/if}

        {#if $clientName && $lib}
          <PopupMenuButton {menuItems}>
            <button class="empty-button menu-button">
              <i class="fa-solid fa-bars" />
            </button>
          </PopupMenuButton>
        {/if}
      </div>
    </div>
  </div>
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="AppTopBar"] {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) 0 env(safe-area-inset-left);
    background-color: #ffffff;
    border-bottom: 1px solid lightgray;

    .top-bar-content {
      @include left-center-right-container;

      padding: 0.5rem 1rem;

      .top-left {
        display: flex;
        gap: 1rem;
        align-items: center;

        .logo {
          height: 3rem;
          display: block;
        }

        .vertical-separator {
          height: 1.5rem;
          width: 1px;
          background-color: $primary;
        }
      }

      .top-center {
        display: flex;
        align-items: center;
        .client-name {
          font-size: 1.5rem;
          font-weight: bold;
          color: $primary;
        }
      }

      .top-right {
        display: flex;
        align-items: center;

        .settings-buttons {
          display: flex;
          gap: 2rem;
          align-items: baseline;
          justify-content: flex-end;
          font-size: 1.5rem;
          color: $bold;
        }

        .clock-container {
          text-align: center;
          :global() {
            color: $darkgray;
          }
        }
      }

      .menu-button {
        i {
          font-size: 1.5rem;
          color: $primary;
        }
      }

      @media only screen and (max-width: 600px) {
        .top-left {
          .logo {
            height: 2rem;
          }
        }
        .top-center {
          .client-name {
            font-size: 1rem;
          }
        }
      }
    }
  }
</style>
