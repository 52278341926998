<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { fade, slide } from "svelte/transition";
  import type { IFolder } from "../models";
  import { settings } from "../settings";
  import { getChildFolders, getFolder, getFolderSongs, getRootFolder } from "../stores/library-store";
  import { clientName } from "./../stores/settings-store";
  import FolderBox from "./folder-box.svelte";
  import Songbox from "./song-box.svelte";

  const dispatch = createEventDispatcher();

  export let initialFolder: IFolder = undefined;
  export let disabledIds = [];
  export let songSelectionEnabled = true;
  export let excludeFolderSuffix: string = undefined;
  export let onlyFolderSuffix: string = undefined;

  let currentFolder: IFolder = undefined;
  $: parentFolder = getFolder(currentFolder?.parentFolderId);

  $: childFolders = getChildFolders(currentFolder?.id)
    .filter((f) => {
      if (onlyFolderSuffix) {
        return f.suffix === onlyFolderSuffix;
      }
      if (excludeFolderSuffix) {
        return f.suffix !== excludeFolderSuffix;
      }
      return true;
    })
    .sortBy((s) => s.name, settings.folderSortStartWithAtLast);

  $: childSongs = getFolderSongs(currentFolder?.id)
    .filter((s) => !s.durationOverLimit)
    .sortBy((s) => s.title, settings.fileSortStartWithAtLast);

  const changeFolder = (folderId: string) => {
    currentFolder = getFolder(folderId);

    dispatch("folderChanged", currentFolder);
  };

  onMount(() => {
    currentFolder = getFolder(initialFolder?.id) || getRootFolder();
  });

  const navigateRoot = () => {
    changeFolder(getRootFolder()?.id);
  };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="NavigateFolderSong">
  <div class="header">
    {#if currentFolder?.parentFolderId}
      <div class="top-nav" transition:slide>
        <button on:click={() => changeFolder(currentFolder.parentFolderId)} transition:fade>
          <i class="fa-solid fa-arrow-turn-up fa-flip-horizontal" />
          <span class="back-folder-name">{parentFolder.name}</span>
        </button>

        <button class="back-button" on:click={navigateRoot} transition:fade>
          <i class="fa-solid fa-home" />
          <span class="back-folder-name">{$clientName}</span>
        </button>
      </div>
    {/if}

    <div class="current-folder">
      {!currentFolder?.parentFolderId ? $clientName : currentFolder?.name}
    </div>
  </div>

  <div class="list">
    <div class="list-items">
      {#each childFolders as folder (folder.id)}
        <div class="list-item" class:disabled={disabledIds.indexOf(folder.id) >= 0}>
          <FolderBox {folder} clickable={true} on:click={() => changeFolder(folder.id)} />
        </div>
      {/each}

      {#each childSongs as song (song.id)}
        {#if songSelectionEnabled}
          <div class="song-item" class:disabled={disabledIds.indexOf(song.id) >= 0}>
            <Songbox {song} showBox={false} />
            <button on:click={() => dispatch("songSelectedPrioritized", song)} disabled={disabledIds.indexOf(song.id) >= 0}>
              <i class="fa-solid fa-user-group" />
            </button>
            <button on:click={() => dispatch("songSelectedRegular", song)} disabled={disabledIds.indexOf(song.id) >= 0}>
              <i class="fa-solid fa-user" />
            </button>
          </div>
        {:else}
          <div class="simple-song">{song.title}</div>
        {/if}
      {/each}
    </div>
  </div>
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="NavigateFolderSong"] {
    display: flex;
    flex-direction: column;
    height: 100%;

    .header {
      border-bottom: 1px solid lightgray;
      margin-bottom: 1rem;
      .top-nav {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;
        justify-content: space-between;

        button {
          display: flex;
          gap: 1rem;
        }
      }

      .current-folder {
        color: $bold;
        padding: 0.5rem;
        font-size: 1.2rem;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .list {
      flex: auto;
      overflow: auto;

      .list-items {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;

        .song-item {
          display: flex;
          gap: 1rem;
          margin-right: 1rem;
          padding-bottom: 0.3rem;
          border-bottom: 1px solid lightgray;

          &.disabled {
            opacity: 0.4;
          }

          button {
            width: 5rem;
          }
        }
      }

      .simple-song {
        margin-left: 2rem;
      }
    }
  }
</style>
