
import { Subject, type Observable } from "rxjs";

export class IntervalWorker {

    #worker: Worker;
    #intervalSubject = new Subject<void>();
    #workerName: string;

    constructor(name: string = undefined) {
        this.#workerName = name;
    }

    start(interval: number): Observable<void> {
        this.#worker = new Worker('/workers/intervalWorker.js');
        this.#worker.postMessage({ message: 'start', interval });

        this.#worker.onmessage = () => {
            this.#intervalSubject.next()
            // console.log(`SW: ${this.#workerName} - ${new Date().toLocaleTimeString()}`);
        };
        return this.#intervalSubject.asObservable();
    }

    stop() {
        this.#worker.postMessage({ message: 'stop' });
    }

    terminate() {
        this.#worker.terminate();
    }
}