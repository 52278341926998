import pako from 'pako';

export const zip = (data: string): Uint8Array => {

    const zipped = pako.gzip(data);

    return zipped
}

export const unzip = (data: Uint8Array): string => {

    return pako.ungzip(data, { to: "string" });
}