<script lang="ts">
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="SelectFolderIcon">
    <i class="fa-regular fa-folder" />
    <div class="ellipsis-container">
        <i class="fa-solid fa-ellipsis" />
    </div>
</div>

<!--######################################################################################-->
<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="SelectFolderIcon"] {
        @include overlay-container;

        .ellipsis-container {
            font-size: 0.8rem;
            transform: translateY(0.2em);
        }
    }
</style>
