<script lang="ts">
  export let contentPadding: string = undefined;
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="Panel">
  <div class="main">
    <div class="top">
      <slot name="header" />
    </div>

    <div class="content" style={contentPadding ? `padding:${contentPadding}` : undefined}>
      <slot />
    </div>
  </div>
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="Panel"] {
    height: 100%;

    .main {
      display: flex;
      flex-direction: column;
      height: 100%;

      .top {
        @include left-center-right-container;
        gap: 1rem;

        background-color: $panel-header-color;
        height: 3rem;
        color: white;
        border-radius: 0.5rem 0.5rem 0 0;
      }

      .content {
        background-color: $background-light;
        flex: auto;
        border-radius: 0 0 0.5rem 0.5rem;
        overflow: hidden;
        border-width: 0 0.1rem 0.1rem 0.1rem;
        border-style: solid;
        border-color: $panel-header-color;
        padding: 1rem;
      }
    }
  }
</style>
