<script lang="ts">
  import { filter, firstValueFrom, interval } from "rxjs";
  import { generate } from "short-uuid";
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import Callout from "../components/callout.svelte";
  import SwitchContainer from "../components/switch-container.svelte";
  import { showAppConfirmDialogAsync } from "../stores/app-store";
  import { busListen, busPush } from "../stores/bus";
  import { currentSong$, dequeueNextSong, updateQueueTime } from "../stores/queue-store";
  import { autoPlaySubject, getSessionAgeInSeconds, mainPlayerStatusSubject, playerTimeSubject, sessionInfoSubject } from "../stores/session-store";

  import AudioPlayer from "../components/audio-player.svelte";
  import AddSongIcon from "../components/icons/add-song-icon.svelte";
  import SongBox from "../components/song-box.svelte";
  import { i18n, i18n$ } from "../i18n/i18n";
  import type { QueuedSong } from "../models";
  import { addSongToDownloadQueue } from "../stores/library-store";
  import type { PlayerActionData } from "../stores/signalr-store";
  import type { AudioStatus } from "../utils/audio-manager";
  import { clearSubscriptions, safeSubscribe } from "../utils/rx-utils";
  export let volume: number = 1;

  const cId = generate();

  const dispatch = createEventDispatcher();

  let callout: Callout;
  let songLocal: QueuedSong;
  let audioPlayer: AudioPlayer;
  let loadingLocalSong = false;
  let snapTime = undefined;
  $: songDownloadProgressObservable = songLocal?.song.downloadProgress$;

  onMount(() => {
    safeSubscribe(
      cId,

      currentSong$.subscribe(async (song) => {
        if (!song && !songLocal) {
          return;
        }

        await unloadSong();
        await loadSong(song);
        // checking session age is to avoid autoplay when retoring a session
        if (songLocal && autoPlaySubject.value && getSessionAgeInSeconds() > 2) {
          await playCallout();
          await playSong();
        }
      }),

      sessionInfoSubject.subscribe(() => audioPlayer?.pause()),

      mainPlayerStatusSubject.subscribe((status) => {
        dispatch("statusChanged", status);
      }),

      busListen("PlayerAction").subscribe((busMsg) => {
        const actionData: PlayerActionData = busMsg.data;

        switch (actionData.action) {
          case "play":
            audioPlayer.play();
            break;
          case "pause":
            audioPlayer.pause();
            break;
          case "skipCurrent":
            nextSongClicked(false);
            break;
          case "replayCallout":
            replayCallout();
            break;
          case "updatePlayTime":
            audioPlayer.seek(actionData.time);
            break;
          case "skipCallout":
            callout.cancel();
            break;
        }
      }),

      interval(2500)
        .pipe(filter(() => !!songLocal))
        .subscribe(() => updateQueueTime()),
    );
  });

  onDestroy(() => {
    clearSubscriptions(cId);
  });

  const audioPlayerStatusChanged = (status: AudioStatus) => {
    mainPlayerStatusSubject.next(status);
  };

  const unloadSong = async () => {
    await callout.cancel();
    await audioPlayer?.pause();
    await audioPlayer?.unloadAsync();
    songLocal = undefined;
  };

  const loadSong = async (qs: QueuedSong) => {
    songLocal = qs;
    if (!songLocal) {
      return;
    }

    if (songLocal.song.downloadStatus !== "downloaded") {
      addSongToDownloadQueue(songLocal.song);
      mainPlayerStatusSubject.next("downloading");
      await firstValueFrom(songLocal.song.downloadStatus$.pipe(filter((s) => s === "downloaded" || s === "error" || s === "cancelled")));
    }

    if (songLocal.song.downloadStatus === "downloaded") {
      const buffer = await qs.song.getSongArrayBuffer();
      await audioPlayer.loadAsync(buffer);
    } else {
      dequeueNextSong();
    }
  };

  const playCallout = async () => {
    mainPlayerStatusSubject.next("callout");
    songLocal.playStatus = "playing";

    const buffer = await songLocal.song.getCalloutArrayBuffer();

    songLocal.updateTotalPlayTime(0);
    await callout.startAndWaitEndAsync(buffer);
  };

  const playSong = async () => {
    mainPlayerStatusSubject.next("playing");
    songLocal.playStatus = "playing";

    try {
      await audioPlayer.play();
    } catch (ex) {
      console.error("error", ex);
      mainPlayerStatusSubject.next(undefined);
    }
  };

  const songPlayed = () => {
    songLocal.playStatus = "completed";
    if (autoPlaySubject.value) {
      dequeueNextSong();
    }
  };

  const replayCallout = async () => {
    if (!songLocal) {
      return;
    }

    if (songLocal.playStatus === "playing") {
      const res = await showAppConfirmDialogAsync({
        title: i18n.confirmDialogs.mainPlayerConfirmRestart.title,
        contentText: i18n.confirmDialogs.mainPlayerConfirmRestart.message,
        buttons: ["cancel", "ok"],
      });
      if (!res) {
        return;
      }
    }

    await callout.cancel(); //cancel if already on callout
    await audioPlayer.pause();
    await audioPlayer.seek(0);

    await playCallout();
    await playSong();
  };
  const skipCallout = async () => {
    await callout.cancel();
  };

  const nextSongClicked = async (askConfirmation = true) => {
    // If within 1 second of the end of the song, dont bother asking confirmation
    const nearEnd = Math.abs(playerTimeSubject.value - songLocal.song.duration) <= 1;

    if (askConfirmation && !nearEnd) {
      const res = await showAppConfirmDialogAsync({
        title: i18n.confirmDialogs.mainPlayerConfirmSkip.title,
        contentText: i18n.confirmDialogs.mainPlayerConfirmSkip.message,
        buttons: ["cancel", "ok"],
      });
      if (!res) {
        return;
      }

      songLocal.playStatus = "skipped";
    }

    dequeueNextSong();
  };

  const playerSeeked = (time: number) => {
    audioPlayerTimeChanged(time);
    dispatch("timeUpdatedByUser");
  };

  const calloutTimeChanged = (time: number) => {
    if (songLocal) {
      songLocal.updateTotalPlayTime(time);
    }
  };

  const audioPlayerTimeChanged = (time: number) => {
    playerTimeSubject.next(time);
    if (songLocal) {
      songLocal.updateTotalPlayTime(time + songLocal.fullCalloutDuration);
    }
  };

  const setSnapNow = () => {
    // Remove snap if: snapAlready at current time OR snap within 1 second of start OR end of song
    if (snapTime === playerTimeSubject.value || playerTimeSubject.value <= 1 || Math.abs(playerTimeSubject.value - songLocal.song.duration) <= 1) {
      audioPlayer.clearSnap();
    } else {
      audioPlayer.setSnap();
    }
  };
</script>

<!-- ######################################################################################### -->
<!-- COMPONENT -->
<div data-component="Player">
  <div class={"empty gray-message " + ($currentSong$ ? "hide" : "show")}>
    <div>{$i18n$.session.panel.main.noCurrent}</div>
    <button class="add-song-button" on:click={() => busPush("DialogShowAddToQueue")}>
      <AddSongIcon />
      <span>{$i18n$.session.panel.main.addSong}</span>
    </button>
  </div>

  <div class={"content " + ($currentSong$ && !loadingLocalSong ? "show" : "hide")}>
    <div class="player-container">
      <div class="songbox">
        <SongBox queuedSong={$currentSong$} />
      </div>
      <SwitchContainer selectedId={$mainPlayerStatusSubject}>
        <div id="downloading" class="downloading-container">
          {$i18n$.session.panel.main.downloading}... {Math.ceil($songDownloadProgressObservable * 100)} %
        </div>

        <div id="callout" class="callout-container">
          <Callout bind:this={callout} on:timeChanged={(e) => calloutTimeChanged(e.detail)} />
        </div>

        <div id="playing|paused" class="tracker-container">
          <div class="tracker-player-container">
            <AudioPlayer
              audioPlayerInstanceName="Player"
              bind:this={audioPlayer}
              {volume}
              on:ended={() => songPlayed()}
              on:statusChanged={(e) => audioPlayerStatusChanged(e.detail)}
              on:timeChanged={(e) => audioPlayerTimeChanged(e.detail)}
              on:TimeUpdatedByUser={(e) => playerSeeked(e.detail)}
              on:snapTimeChanged={(e) => (snapTime = e.detail)}
            />
          </div>
          <button class="set-snap-button empty-button" on:click={() => setSnapNow()}>
            <i class="fa-solid fa-thumbtack" />
          </button>
        </div>
      </SwitchContainer>
    </div>

    <div class="controls">
      <div class="left-buttons">
        <button
          class="icon-md"
          on:click={() => replayCallout()}
          disabled={["callout", "empty", "loading", "downloading"].includes($mainPlayerStatusSubject)}
        >
          <i class="fa-solid fa-bell" />
        </button>

        {#if $mainPlayerStatusSubject === "callout"}
          <button class="icon-md" on:click={() => skipCallout()}>
            <span class="callout-replay">
              <i class="fa-solid fa-bell" />
              <i class="fa-solid fa-xmark callout-action" />
            </span>
          </button>
        {/if}
        {#if snapTime}
          <button class="icon-md goto-snap-button" on:click={() => audioPlayer.goToSnap()}>
            <i class="fa-solid fa-caret-left arrow" />
            <i class="fa-solid fa-thumbtack" />
            <i class="fa-solid fa-caret-right arrow" />
          </button>
        {/if}
      </div>

      <div class="center-buttons">
        {#if $mainPlayerStatusSubject === "playing"}
          <button class="icon-lg" on:click={() => audioPlayer.pause()}>
            <i class="fa-solid fa-pause" />
          </button>
        {/if}
        {#if $mainPlayerStatusSubject === "paused"}
          <button class="icon-lg" on:click={() => audioPlayer.play()}>
            <i class="fa-solid fa-play" />
          </button>
        {/if}
      </div>

      <div class="right-buttons">
        <button class="icon-md next-button" on:click={() => nextSongClicked()}>
          <i class="fa-solid fa-forward-step" />
        </button>
      </div>
    </div>
  </div>
</div>

<!-- ######################################################################################### -->

<!-- STYLE -->
<style lang="scss">
  [data-component="Player"] {
    height: 100%;
    display: grid;
    grid-template-areas: "grid-main";

    .empty {
      grid-area: grid-main;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      align-self: center;
      width: 100%;
    }

    .add-song-button {
      width: 12rem;
      height: 4rem;
      font-size: 1.5rem;
      display: flex;
      flex-direction: column;
      span {
        font-size: 0.8rem;
      }
    }

    .content {
      grid-area: grid-main;
      width: 100%;
      overflow: visible; // Allow shadow to overflow
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .songbox {
        grid-area: songbox;
        overflow: hidden;
      }

      .controls {
        grid-area: controls;
        display: flex;
        gap: 0.5rem;
        .left-buttons {
          flex: 1 1 0;
        }
        .right-buttons {
          flex: 1 1 0;
          display: flex;
          justify-content: flex-end;
        }
      }
      .player-container {
        width: 100%;
        grid-area: player;
        align-self: center;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .tracker-container {
          display: flex;

          .tracker-player-container {
            flex: auto;
          }

          .set-snap-button {
            padding-left: 1rem;
            font-size: 1.5rem;
          }
        }
      }

      .left-buttons {
        display: flex;
        gap: 1rem;
      }
    }

    .callout-replay {
      .callout-action {
        transform: scale(0.7);
      }
    }
  }
</style>
