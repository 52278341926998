<script lang="ts">
  import NavigateFolderSong from "../components/navigate-folder-song.svelte";
  import { i18n } from "../i18n/i18n";
  import type { IFolder, Song } from "../models";
  import { showAppConfirmDialogAsync } from "../stores/app-store";
  import { getRootFolder } from "../stores/library-store";
  import { activeQueue$, addToQueue, queueSubject } from "../stores/queue-store";
  import { showToast } from "../stores/toaster-store";
  import { appLocalStorage } from "../utils/app-local-storage";
  import Modal from "./modal.svelte";
  let modal: Modal;

  let prevFolder: IFolder;
  let currentFolder: IFolder;

  export const openDialogAsync = async (forceRoot: boolean = false) => {
    if (forceRoot || !appLocalStorage.selectSongKeepLastFolder) {
      prevFolder = undefined;
    }

    currentFolder = prevFolder || getRootFolder();

    modal.openDialogAsync();
  };

  const songSelected = async (song: Song, isCoachRequest = false) => {
    const foundInQueue = queueSubject.value.filter((qs) => qs.queueStatus !== "history").some((qs) => qs.song.id === song.id);
    if (foundInQueue) {
      await showToast(i18n.toasts.songAlreadyInQueue.title, i18n.toasts.songAlreadyInQueue.message, "warn");
      return;
    }

    if (song.downloadStatus !== "downloaded" && song.downloadStatus !== "downloading") {
      if (!navigator.onLine) {
        await showToast(i18n.toasts.internetConnectionRequired.title, i18n.toasts.internetConnectionRequired.message, "danger");
        return;
      }

      // Song not downloaded, ask user if he wants to download it
      const res = await showAppConfirmDialogAsync({
        title: i18n.confirmDialogs.confirmDownloadSong.title,
        contentText: i18n.confirmDialogs.confirmDownloadSong.message,
        acceptCustomText: i18n.common.download,
        buttons: ["cancel", "ok", "x"],
        headerIcon: "fa-solid fa-cloud-arrow-down",
      });
      if (!res) {
        return;
      }
    }

    addToQueue(song, undefined, isCoachRequest);

    modal.closeDialog();
  };
</script>

<!-- COMPONENT -->
<Modal
  bind:this={modal}
  config={{
    title: i18n.dialogs.addToQueue.title,
    buttons: ["cancel", "x"],
    dialogHeight: "90dvh",
    autoSize: false,
    headerIcon: "fa-solid fa-music",
  }}
>
  <div data-component="AddToQueueDialog">
    <div class="content">
      <NavigateFolderSong
        initialFolder={prevFolder}
        on:folderChanged={(e) => (prevFolder = e.detail)}
        on:songSelectedRegular={(ev) => songSelected(ev.detail, false)}
        on:songSelectedPrioritized={(ev) => songSelected(ev.detail, true)}
        disabledIds={$activeQueue$.map((qs) => qs.song.id)}
        excludeFolderSuffix="A"
      />
    </div>
  </div>
</Modal>

<!-- STYLE -->
<style lang="scss">
  [data-component="AddToQueueDialog"] {
    display: flex;
    flex-direction: column;
    width: min(90vw, 40rem);
    height: 100%;
    .content {
      flex: auto;
      overflow: hidden;
    }
    .top {
      padding: 1rem 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background-color: white;
      border: 1px solid lightgray;
      border-radius: 0.5rem;
      padding: 1rem;
      margin-bottom: 1rem;

      i {
        font-size: 1.2rem;
      }

      .override-position {
        display: flex;
        align-items: center;
        gap: 1rem;
        input {
          text-align: center;
          width: 5rem;
        }
      }
    }
  }
</style>
