<script lang="ts">
    import { generate } from "short-uuid";
    import { onDestroy, onMount } from "svelte";
    import empty from "../assets/empty.mp3";
    import { i18n$ } from "../i18n/i18n";
    import { appClickedSubject } from "../stores/app-store";
    import { clearSubscriptions, safeSubscribe } from "../utils/rx-utils";
    const cId = generate();

    let htmlAudioElement: HTMLAudioElement;

    onMount(() => {
        safeSubscribe(
            cId,
            appClickedSubject.subscribe(() => {
                // play dummy audio to allow audio to play on iOS when device is locked
                if (htmlAudioElement.paused) {
                    htmlAudioElement.play();
                }
            })
        );
    });

    onDestroy(() => {
        clearSubscriptions(cId);
    });
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="AudioLockPreventer">
    <audio controls src={empty} bind:this={htmlAudioElement} loop={true} title={$i18n$.general.audioLockPreventerMessage} />
</div>

<!--######################################################################################-->
<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="AudioLockPreventer"] {
        display: none;
    }
</style>
