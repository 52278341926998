<script lang="ts">
  import { differenceInMinutes, format } from "date-fns";
  import SongBox from "../components/song-box.svelte";
  import { type QueuedSong } from "../models";
  import { waitingQueue$ } from "../stores/queue-store";
  import { i18n, i18n$, i18nParams } from "./../i18n/i18n";
  import Modal from "./modal.svelte";

  type QueuedSongInfoDialogAction = "delete" | "reorder" | "changeToCoachRequest";

  let modal: Modal;
  let queuedSong: QueuedSong;
  let selectedAction: QueuedSongInfoDialogAction;

  $: songQueueStatus$ = queuedSong?.queueStatus$;

  export const openDialogAsync = async (qs: QueuedSong) => {
    selectedAction = undefined;

    queuedSong = qs;

    await modal.openDialogAsync();

    return selectedAction;
  };

  export const closeDialog = (action: QueuedSongInfoDialogAction) => {
    selectedAction = action;
    modal.closeDialog();
  };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<Modal
  bind:this={modal}
  config={{
    title: i18n.dialogs.queuedSongInfo.title,
    headerIcon: "fa-regular fa-rectangle-list",
    buttons: ["cancel", "x"],
    rejectCustomText: i18n.common.cancel,
  }}
>
  <div data-component="QueudSongInfoDialog">
    {#if queuedSong}
      <div class="song-box-container">
        <SongBox {queuedSong} showPlannedStartTime={true} />
      </div>
    {/if}

    <div class="info-box">
      <div>
        <i class="fa-solid fa-clock" />
        <strong>{$i18n$.dialogs.queuedSongInfo.musicQueuedAt}</strong>
        {format(queuedSong.queuedTime, "H:mm")}
        ({i18nParams($i18n$.dialogs.queuedSongInfo.minutesAgo, { min: differenceInMinutes(new Date(), queuedSong.queuedTime) })})
      </div>
      {#if queuedSong.bypassedBy?.length}
        <div>
          <div>
            <i class="fa-solid fa-user-group" />
            <strong>{$i18n$.dialogs.queuedSongInfo.bypassedBy}</strong>
          </div>
          <ul class="bypassed-list">
            {#each queuedSong.bypassedBy as bp}
              <li class="bypassed-item">
                {bp?.song.title}
              </li>
            {/each}
          </ul>
        </div>
      {/if}
    </div>

    <div class="action-buttons">
      <div class="action-button-container">
        <button on:click={() => closeDialog("reorder")} disabled={$songQueueStatus$ !== "waiting" || $waitingQueue$?.length <= 1}>
          <i class="fa-solid fa-down-long" />
          <i class="fa-solid fa-up-long" />
        </button>
        <span>{$i18n$.dialogs.queuedSongInfo.position}</span>
      </div>
      <div class="action-button-container">
        <button on:click={() => closeDialog("changeToCoachRequest")} disabled={$songQueueStatus$ !== "waiting" || queuedSong.isCoachRequest}>
          <i class="fa-solid fa-user" />
          <i class="fa-solid fa-arrow-right" />
          <i class="fa-solid fa-user-group" />
        </button>
        <span>{$i18n$.dialogs.queuedSongInfo.withCoach}</span>
      </div>
      <div class="action-button-container">
        <button on:click={() => closeDialog("delete")} disabled={$songQueueStatus$ !== "waiting"}>
          <i class="fa-solid fa-xmark" />
        </button>
        <span>{$i18n$.dialogs.queuedSongInfo.delete}</span>
      </div>
    </div>
  </div>
</Modal>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="QueudSongInfoDialog"] {
    min-width: 30rem;
    max-width: 80vw;
    height: 100%;
    overflow: auto;

    .song-box-container {
      margin-top: 1rem;
    }

    .info-box {
      border: 1px solid $bold;
      color: $bold;
      border-radius: 0.5rem;
      padding: 1rem;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      i {
        margin-right: 0.5rem;
      }

      .bypassed-title {
        font-weight: bold;
        margin-bottom: 1rem;
      }

      .bypassed-list {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        margin-left: 1rem;
      }
    }

    .action-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      margin-top: 2rem;
      margin-bottom: 2rem;

      button {
        width: 7rem;
        height: 4rem;
        font-size: 1.5rem;
      }

      .action-button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          margin-top: 0.5rem;

          color: $primary;
        }
      }
    }
  }
</style>
