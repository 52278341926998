import { BehaviorSubject, Subject } from "rxjs";
import type { IDialogConfig } from "../dialogs/modal.svelte";
import { Deferred } from "../utils/deferred-promise";
import { initAudioAssets } from "./audio-assets-store";
import { initializeDatabaseAsync } from "./database";
import { initSettingsStore } from "./settings-store";
import { initSignalRStore } from "./signalr-store";

export const appInitializedSubject = new BehaviorSubject<boolean>(false);
export const appClickedSubject = new Subject<MouseEvent>;

export const keepAlivePlayingAudioSubject = new BehaviorSubject<number>(Date.now());


export const appLastClickTime = new BehaviorSubject<number>(Date.now());
appClickedSubject.subscribe(() => {
    appLastClickTime.next(Date.now());
})

export const initAppStore = async () => {

    // app start, only once
    if (!appInitializedSubject.value) {
        warmupAzureFunctions();
        await initAudioAssets();
        await initializeDatabaseAsync();
        await initSettingsStore();
        await initSignalRStore();
        appInitializedSubject.next(true)
    }



}


// This is to maintain zindex of modals and popups
let currentModalZIndexSubject = 1000;
export const getNextModalZIndex = () => {
    return currentModalZIndexSubject++;
}

export const mobileCurrentTabSubject = new BehaviorSubject<"main" | "ambient" | "queue">("main");


// When application is opend/closed on mobile
export const appVisibilitySubject = new BehaviorSubject<boolean>(true);
document.onvisibilitychange = () => {
    if (document.visibilityState === "visible") {
        appVisibilitySubject.next(true);
    } else {
        appVisibilitySubject.next(false);
    }
};

const warmupAzureFunctions = async () => {
    try {
        fetch(import.meta.env.VITE_AZURE_FUNCTIONS_URL + '/Warmup').then(res => {
            if (res.status !== 200) {
                console.error('Failed to warmup Azure Functions');
            }
        })
    } catch {
        console.error('Failed to warmup Azure Functions');
    }

}

// This section is to manage the app-confirm-dialog-wrapper to have a global confirmdialog component
// That we can await from everywhere
export const appConfirmDialogSubject = new BehaviorSubject<IDialogConfig>(undefined);
export let appConfirmDialogStoreClosePromise: Deferred<boolean>;
export const showAppConfirmDialogAsync = async (config: IDialogConfig): Promise<boolean> => {
    const defaultConfig: IDialogConfig = {
        title: "Confirmation",
        contentText: "",
        buttons: ["ok", "cancel", "x"],
        contentPadding: 'clamp(1rem, 3rem, 10%)',
    }

    appConfirmDialogSubject.next({ ...defaultConfig, ...config });
    appConfirmDialogStoreClosePromise = new Deferred<boolean>();
    return appConfirmDialogStoreClosePromise;
}
export const showAppMessageAsync = async (title: string, text: string): Promise<boolean> => {
    appConfirmDialogSubject.next({
        title: title,
        contentText: text,
        buttons: ["ok", "x"],
        contentPadding: '3rem',
    });
    appConfirmDialogStoreClosePromise = new Deferred<boolean>();
    return appConfirmDialogStoreClosePromise;
}

export const logDebugDataSubject = new BehaviorSubject<{ time: string, msg: string }[]>([]);
export const logDebug = (msg: string) => {
    const time = (new Date()).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    logDebugDataSubject.next([{ time, msg }, ...logDebugDataSubject.value.slice(0, 99)]);
    console.log(msg);
}
export const logDebugCond = (msg: string, condition: boolean) => {
    if (!condition) {
        return;
    }
    logDebug(msg)
}

export const resetApplication = () => {
    window.location.replace(window.location.origin);
};


