<script lang="ts">
    import { format } from "date-fns";
    import enCA from "date-fns/locale/en-CA";
    import frCA from "date-fns/locale/fr-CA";
    import { onMount } from "svelte";
    import { i18n$, lang$ } from "../i18n/i18n";
    import type { ISessionInfoStorageDTO } from "../models";
    import { createNewSession, restoreSession } from "../stores/session-store";
    import { appLocalStorage } from "../utils/app-local-storage";

    let lastSessionInfo: ISessionInfoStorageDTO;

    onMount(async () => {
        // If there was a session in progress, we show the restore session button
        if (appLocalStorage.sessionInfo?.queue.some((q) => q.queueStatus !== "history")) {
            lastSessionInfo = appLocalStorage.sessionInfo;
        }
    });

    const newSessionClicked = () => {
        createNewSession();
    };
    const restoreSessionClicked = () => {
        restoreSession();
    };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="SessionStart">
    <div class="session-buttons">
        <button on:click={() => newSessionClicked()}>
            <i class="fa-regular fa-file" />
            <span>{$i18n$.components.sessionStart.startNewSession}</span>
        </button>
        {#if lastSessionInfo}
            <button on:click={() => restoreSessionClicked()}>
                <i class="fa-solid fa-arrow-rotate-left" />
                <span>{$i18n$.components.sessionStart.restoreLastSession}</span>
                <hr />
                <div class="last-session-info">
                    {#if $lang$ === "fr"}
                        <span>{format(lastSessionInfo.sessionSavedDate, "EEEE d MMM", { locale: frCA })}</span>
                        <span>{format(lastSessionInfo.sessionSavedDate, "H:mm", { locale: frCA })}</span>
                    {:else}
                        <span>{format(lastSessionInfo.sessionSavedDate, "EEEE MMM d", { locale: enCA })}</span>
                        <span>{format(lastSessionInfo.sessionSavedDate, "H:mm", { locale: enCA })}</span>
                    {/if}
                </div>
            </button>
        {/if}
    </div>
</div>

<!--######################################################################################-->
<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="SessionStart"] {
        .session-buttons {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            button {
                height: auto;
                width: 20rem;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                padding: 2rem;

                i {
                    font-size: 2rem;
                }
            }
        }

        .last-session-info {
            color: $bold;
        }

        hr {
            width: 100%;
        }
    }
</style>
