<script lang="ts">
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="LoadingBar">
    <div class="inner-bar" />
</div>

<!--######################################################################################-->
<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="LoadingBar"] {
        width: 100%;
        height: 0.2rem;
        position: relative;
        overflow: hidden;

        .inner-bar {
            width: 0;
            height: 100%;
            background-color: $primary; /* Couleur de la barre de chargement */
            position: absolute;
            animation: loadingAnimation 2s infinite;
        }

        @keyframes loadingAnimation {
            0% {
                left: 0;
                width: 0;
            }
            50% {
                width: 100%;
            }
            100% {
                left: 100%;
                width: 0;
            }
        }
    }
</style>
