<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import type { IFolder } from "../models";

  export let folder: IFolder;
  export let clickable: boolean = false;

  const dispatch = createEventDispatcher();
</script>

<!-- COMPONENT folder-box -->
<div data-component="folder-box" on:click={(e) => dispatch("click", e)} class:clickable>
  <i class="fa-solid fa-folder" />
  <span>{folder?.name}</span>
</div>

<!-- STYLES -->
<style lang="scss">
  [data-component="folder-box"] {
    display: flex;
    align-items: center;
    gap: 1rem;

    color: $black;
    border: 1px solid $folder-border-color;
    background: $folder-color;

    border-radius: 0.5rem;
    padding: 0 1rem;
    height: 3rem;
    font-weight: 500;

    i {
      font-size: 1.5rem;
      color: $folder-border-color;
    }

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
</style>
