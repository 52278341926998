<script lang="ts">
  export let checked = false;
  export let height = "1.5rem";
  export let invertedColors = true;
</script>

<!-- COMPONENT checkbox -->
<div
  data-component="Switch"
  style="--controlHeight:{height}"
  on:click={() => (checked = !checked)}
  class="clickable"
  class:inverted-colors={invertedColors}
  class:checked
>
  <div class="back" />
  <div class="round" />
</div>

<!-- STYLES -->
<style lang="scss">
  $h: var(--controlHeight);
  $w: calc(#{$h} * 1.8);
  $round_size: calc(#{$h} * 0.7);
  $round_padding: calc((#{$h} * 0.3) / 2);

  [data-component="Switch"] {
    position: relative;
    height: $h;
    width: $w;
    display: block;

    .back {
      position: absolute;
      background-color: gray;
      height: $h;
      width: $w;
      border-radius: $h;
      transition: 0.5s;
    }

    .round {
      position: absolute;

      height: $round_size;
      width: $round_size;
      border-radius: $round_size;

      background-color: white;
      top: $round_padding;
      left: $round_padding;
      transition: 0.5s;
    }

    &.checked {
      .back {
        background-color: $primary;
      }
      .round {
        transform: translateX(calc(#{$w} - (#{$round_padding} * 2) - #{$round_size}));
      }
    }

    &.inverted-colors {
      .back {
        background-color: $primary-dark;
      }
      .round {
        background-color: $primary;
      }
      &.checked {
        .back {
          background-color: $primary-dark;
        }
        .round {
          background-color: white;
        }
      }
    }
  }
</style>
