<script lang="ts">
    export let invertColors = false;
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="AddSongIcon" class:inverted={invertColors}>
    <i class="fa-solid fa-folder" />
    <i class="fa-solid fa-music" />
</div>

<!--######################################################################################-->
<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="AddSongIcon"] {
        @include overlay-container;
        align-items: center;
        justify-items: center;
        .fa-music {
            color: white;
            font-size: 0.45em;
            margin-top: 0.4em;
        }

        &.inverted {
            .fa-music {
                color: $primary;
            }
        }
    }
</style>
