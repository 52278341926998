import { Subscription, tap, type Observable } from "rxjs";
import type { Unsubscriber } from "svelte/store";

export const log = <T>(source$: Observable<T>): Observable<T> => {
    return source$.pipe(tap((v) => console.log(`log: ${v}`)));
}





export const subscriptionsRx: Record<string, (Subscription)[]> = {};
export const subscriptionsSvelte: Record<string, (Unsubscriber)[]> = {};

export const safeSubscribe = (cId: string, ...params: (Subscription | Unsubscriber)[]) => {

    params.forEach(sub => {
        if (sub instanceof Subscription) {
            if (!subscriptionsRx[cId]) {
                subscriptionsRx[cId] = []
            }
            subscriptionsRx[cId].push(sub);
        } else {
            if (!subscriptionsSvelte[cId]) {
                subscriptionsSvelte[cId] = []
            }
            subscriptionsSvelte[cId].push(sub);
        }
    })
}


export const clearSubscriptions = (key: string) => {

    if (subscriptionsRx[key]?.length) {
        subscriptionsRx[key].forEach(sub => sub.unsubscribe());
    }
    if (subscriptionsSvelte[key]?.length) {
        subscriptionsSvelte[key].forEach(unsubscriber => unsubscriber());
    }

    delete subscriptionsRx[key];
    delete subscriptionsSvelte[key];
}

export const clearSubscriptionsArray = (subscriptions: (Subscription | Unsubscriber)[]) => {

    subscriptions.forEach(sub => {
        if (sub instanceof Subscription) {
            sub.unsubscribe();
        } else {
            sub();
        }
    })

}
