<script lang="ts">
  import { onMount } from "svelte";
  import ConfirmDialog from "../dialogs/confirm-dialog.svelte";
  import { appConfirmDialogStoreClosePromise, appConfirmDialogSubject } from "../stores/app-store";

  let dialog: ConfirmDialog;

  onMount(() => {
    appConfirmDialogSubject.subscribe(async (config) => {
      if (config) {
        const res = await dialog.openDialogAsync(config);
        appConfirmDialogStoreClosePromise.resolve(res);
      }
    });
  });
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<ConfirmDialog bind:this={dialog} />
