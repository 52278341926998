<script lang="ts">
    import AudioAnimation from "../components/audio-animation.svelte";
    import Panel from "../components/panel.svelte";
    import Volume from "../components/volume.svelte";
    import { i18n$ } from "../i18n/i18n";
    import { autoPlaySubject, getPlayerDTO, mainPlayerIsPlaying$ } from "../stores/session-store";
    import { signalrBroadcast } from "../stores/signalr-store";
    import MainPlayer from "./main-player.svelte";

    export let playerVolume: number = 1;

    const playerTimeUpdatedByUser = () => {
        signalrBroadcast("playerUpdate", getPlayerDTO());
    };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="MainPlayerPanel">
    <Panel>
        <div slot="header" class="header">
            <div class="title">
                <div class="content">
                    <div class="icon">
                        <i class="fa-solid fa-music" />
                    </div>
                    <span>{$i18n$.session.panelTitle.main}</span>
                    <div class="show" class:hide={!$mainPlayerIsPlaying$}>
                        <AudioAnimation />
                    </div>
                </div>
            </div>

            <div class="actions">
                <div class="separator"></div>
                <button class="empty-button header-button" on:click={() => autoPlaySubject.next(!autoPlaySubject.value)}>
                    <i class="fa-solid fa-bolt panel-toggle" class:active={$autoPlaySubject} />
                </button>

                <div class="separator"></div>

                <div class="volume">
                    <Volume bind:volume={playerVolume} />
                </div>
            </div>
        </div>

        <MainPlayer volume={playerVolume} on:timeUpdatedByUser={() => playerTimeUpdatedByUser()} />
    </Panel>
</div>

<!--######################################################################################-->
<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="MainPlayerPanel"] {
        height: 100%;

        .header {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .title {
                display: flex;
                align-items: center;
                height: 100%;
                .content {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    margin-left: 1rem;

                    .icon {
                        font-size: 1.5rem;
                    }
                }
            }

            .actions {
                display: flex;
                color: white;

                .separator {
                    width: 0.1rem;
                    background-color: $panel-separator-color;
                    height: 100%;
                }

                .header-button {
                    height: 100%;
                    color: white;
                    padding: 0 1rem;
                    font-size: 1.5rem;
                }
                .volume {
                    padding: 0 1rem;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
</style>
