<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let value: boolean = false;
  export let disabled: boolean = false;
  export let width: string = "auto";

  const dispatcher = createEventDispatcher();

  const click = () => {
    value = !value;
    dispatcher("toggled", value);
  };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="ToggleButton">
  <button {disabled} on:click={() => click()} class:toggled={value} style:width>
    <slot />
  </button>
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  .container {
    display: flex;
    gap: 0.2rem;
  }
</style>
