import Dexie from 'dexie';
import type { IFolder, ISong } from '../models';

export interface SongAudioData {
    id: string;
    blob: Blob;
    duration: number;
}
export interface CalloutAudioData {
    id: string;
    blob: Blob;
    duration: number;
    calloutText: string;
    isCustom: boolean;
}

export let solohubDB: SolohubDatabase;

export let databaseUpgraded = false;
export const initializeDatabaseAsync = async () => {

    const DATABASE_NAME = "solohub_db";
    const DATABASE_VERSION = 7;
    const DATABASE_VERSION_DEXIE = DATABASE_VERSION * 10; // dexie mulitplies the indexdb version by 10 automatically, a bit annoying

    const currentVersion = await getCurrentVersion(DATABASE_NAME);

    if (currentVersion !== DATABASE_VERSION_DEXIE) {
        databaseUpgraded = true;
        await Dexie.delete(DATABASE_NAME);
    }

    solohubDB = new SolohubDatabase(DATABASE_NAME);
    await solohubDB.version(DATABASE_VERSION).stores({
        songs: 'id',
        folders: 'id',
        songsAudioData: 'id',
        calloutsAudioData: 'id'
    })

};

const getCurrentVersion = (dbName: string): Promise<number> => {
    return new Promise<number>((resolve) => {
        const openRequest = indexedDB.open(dbName);
        openRequest.onsuccess = () => {
            const db = openRequest.result;
            resolve(db.version);
            db.close();
        };
        openRequest.onerror = () => {
            resolve(-1)
        };
    });
}

export class SolohubDatabase extends Dexie {
    songs!: Dexie.Table<ISong>;
    folders!: Dexie.Table<IFolder>;
    songsAudioData!: Dexie.Table<SongAudioData>;
    calloutsAudioData!: Dexie.Table<CalloutAudioData>;

    constructor(databaseName: string) {
        super(databaseName);
    }

    async existsAsync() {
        const res = (await Dexie.exists(this.name))
        return res;
    }

    async getSongBlobAsync(id: string): Promise<Blob> {
        return (await this.songsAudioData.get({ id: id }))?.blob;
    }

    async getCalloutBlobAsync(id: string): Promise<Blob> {
        return (await this.calloutsAudioData.get({ id: id }))?.blob;
    }

}

