<script lang="ts">
    import AudioAnimation from "../components/audio-animation.svelte";
    import AmbientIcon from "../components/icons/ambient-icon.svelte";
    import { i18n$ } from "../i18n/i18n";
    import { mobileCurrentTabSubject } from "../stores/app-store";
    import { waitingQueue$ } from "../stores/queue-store";
    import { ambientPlayerIsPlaying$, mainPlayerIsPlaying$ } from "../stores/session-store";
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="AppBottomBar">
    <div class="content">
        <button
            class="empty-button bar-button"
            class:selected={$mobileCurrentTabSubject === "main"}
            on:click={() => mobileCurrentTabSubject.next("main")}
        >
            <div class="icon">
                <i class="fa-solid fa-music" />
            </div>
            <div class="label">
                <div><!-- dummy --></div>
                <span>{$i18n$.session.panelTitle.main}</span>
                <div class="label-suffix show" class:hide={!$mainPlayerIsPlaying$}>
                    <AudioAnimation />
                </div>
            </div>
        </button>

        <button
            class="empty-button bar-button"
            class:selected={$mobileCurrentTabSubject === "ambient"}
            on:click={() => mobileCurrentTabSubject.next("ambient")}
        >
            <div class="icon">
                <AmbientIcon />
            </div>
            <div class="label">
                <div><!-- dummy --></div>
                <span>{$i18n$.session.panelTitle.ambient}</span>
                <div class="label-suffix show" class:hide={!$ambientPlayerIsPlaying$}>
                    <AudioAnimation />
                </div>
            </div>
        </button>

        <button
            class="empty-button bar-button"
            class:selected={$mobileCurrentTabSubject === "queue"}
            on:click={() => mobileCurrentTabSubject.next("queue")}
        >
            <div class="icon">
                <i class="fa-solid fa-rectangle-list"></i>
            </div>
            <div class="label">
                <div><!-- dummy --></div>
                <div>
                    <span>{$i18n$.session.panelTitle.queue}</span>
                    <span>
                        ({$waitingQueue$.length})
                    </span>
                </div>
                <div class="label-suffix">
                    <!-- just to align with other button -->
                    <div class="hide">
                        <AudioAnimation />
                    </div>
                </div>
            </div>
        </button>
    </div>
</div>

<!--######################################################################################-->
<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="AppBottomBar"] {
        background-color: white;
        padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

        .content {
            display: flex;

            .bar-button {
                flex: 1 1 0;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                color: gray;
                padding: 0.5rem 0;

                .icon {
                    font-size: 2rem;
                    height: 2rem;
                }
                .label {
                    @include left-center-right-container;

                    width: 100%;
                    align-items: baseline;
                    gap: 0.5rem;
                    font-size: 0.8rem;
                    white-space: nowrap;

                    .label-suffix {
                        justify-content: flex-start;
                    }
                }

                border-top: 3px solid transparent;
                &.selected {
                    color: $primary;
                    border-top: 3px solid $primary;
                }
            }
        }
    }
</style>
